/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import { v4, NIL } from "uuid";

/**
 * Until we can have a proper typesafe UUID, this will have to do.
 */
export type UUID = string;

/**
 * The "empty" UUID
 */
export const EMPTY_UUID_STRING = NIL;
export const EMPTY_UUID = parseUUID(NIL);

/**
 * Create a random UUID.
 */
export function createUUID(): UUID {
  return v4();
}

/**
 * Create a UUID from a string.
 */
export function parseUUID(uuid: string): UUID {
  return uuid;
}

/**
 * An object that retains its most recently assigned value while being passed around by reference.
 */
export interface Bucket<T> {
  value: T;
}

export type NumberBucket = Bucket<number>;

// for TreeView; the typeof operator doesn't seem to work here
export type TreeNodeId = number | string;
