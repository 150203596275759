/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { gql, useMutation } from "@apollo/client";
import { AppInfo, MigrationExecutionState, SchemaStatus } from "Api";
import migrateDataToLatestVersion from "./Api/Gql/MigrateDataToLatestVersion";
import { Button } from "baseui/button";
import { HeadingXXLarge, LabelLarge, LabelSmall } from "baseui/typography";
import * as React from "react";
import { CenteredContent } from "./DesignSystem/Containers";
import { handleApolloError } from "./Shared/Errors";
import { APP_TITLE_TITLECASE } from "./Utils/SiteProps";
import { LOGO_URL } from "./Utils/SiteLogo.mjs";

export const MigrationUpdate = ({
  appInfo,
  onMigrationIgnore,
  onMigrationComplete,
}: {
  appInfo: AppInfo;
  onMigrationIgnore: () => void;
  onMigrationComplete: () => void;
}): JSX.Element => {
  const [migrationIsRunning, setMigrationIsRunning] = React.useState(false);
  const [migrateDataToLatestVersionMutation] = useMutation(
    gql(migrateDataToLatestVersion),
    {
      onCompleted: () => {
        setMigrationIsRunning(false);
        onMigrationComplete();
      },
      onError: (error) => {
        setMigrationIsRunning(false);
        handleApolloError(
          error,
          "An error occured while attempting to migrate. Please try again. If the problem persists. Please contact SimVentions."
        );
      },
    }
  );

  return (
    <CenteredContent>
      <img src={LOGO_URL.toString()} height="200rem"></img>
      <HeadingXXLarge>{`Welcome to ${APP_TITLE_TITLECASE}!`}</HeadingXXLarge>
      <LabelLarge>
        {`We've made improvements to ${APP_TITLE_TITLECASE}, and the current version of your data needs to be migrated to work with these updates.`}
      </LabelLarge>
      <MigrationStatus
        migrationState={appInfo?.schemaStatus.lastExecutedMigration}
        expectedMigrationId={appInfo?.schemaStatus.expectedMigrationId}
        migrationIsRunning={migrationIsRunning}
        onExecuteMigrationClick={() => {
          setMigrationIsRunning(true);
          migrateDataToLatestVersionMutation();
        }}
      />
      <Button size="mini" kind="tertiary" onClick={() => onMigrationIgnore()}>
        Ignore Migration (not recommended)
      </Button>
    </CenteredContent>
  );
};

const MigrationStatus = ({
  migrationState,
  expectedMigrationId,
  migrationIsRunning,
  onExecuteMigrationClick,
}: {
  migrationState?: MigrationExecutionState;
  expectedMigrationId?: string;
  migrationIsRunning?: boolean;
  onExecuteMigrationClick?: () => void;
}): JSX.Element => {
  const standardErrorMessage =
    "Error loading migration information. Please contact SimVentions.";
  if (!migrationState || !expectedMigrationId) {
    return <LabelSmall>{standardErrorMessage}</LabelSmall>;
  }
  switch (migrationState.__typename) {
    case "ExecutedMigration":
      if (migrationState.changeId !== expectedMigrationId) {
        return (
          <>
            <LabelSmall>{`Current Version: ${migrationState.changeId}`}</LabelSmall>
            <LabelSmall>{`Expected Version: ${expectedMigrationId}`}</LabelSmall>
            <Button
              isLoading={migrationIsRunning}
              onClick={() => onExecuteMigrationClick()}
            >
              Migrate Data
            </Button>
          </>
        );
      }
    case "NoMigrationsExecuted":
      return (
        <>
          <LabelSmall>{`No migrations have been executed. Please migrate data to version "${expectedMigrationId}"`}</LabelSmall>
          <Button
            isLoading={migrationIsRunning}
            onClick={() => onExecuteMigrationClick()}
          >
            Migrate Data
          </Button>
        </>
      );
    case "ErrorGettingMigrations":
      return (
        <>
          <LabelSmall>{standardErrorMessage}</LabelSmall>
          <LabelSmall>{migrationState.errorMessage}</LabelSmall>
        </>
      );
    default:
      const _exhaustiveCheck: never = migrationState;
      return _exhaustiveCheck;
  }
};

export const showMigrationPage = (schemaStatus: SchemaStatus): boolean => {
  switch (schemaStatus.lastExecutedMigration.__typename) {
    case "ExecutedMigration":
      return (
        schemaStatus.lastExecutedMigration.changeId !=
        schemaStatus.expectedMigrationId
      );
    case "NoMigrationsExecuted":
      return (
        schemaStatus.lastExecutedMigration.message !=
        schemaStatus.expectedMigrationId
      );
    default:
      return true;
  }
};
