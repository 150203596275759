/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import React, {
  useCallback,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { CSSProperties } from "react";
import { Position, Handle, NodeChange } from "reactflow";
import "reactflow/dist/style.css";
import { PathType } from "../Api/DataTransformation";
import { getHandleOffsetLeft, getHandleOffsetTop } from "../Utils/Common";
import { DeleteConfirmationModal } from "../Shared/DeleteConfirmationModal";
import { COMMON_TEXT_STYLING } from "../Utils/SiteProps";
import { ProjectContext } from "../UserApp";
import {
  findNodeHandleForEntry,
  getEntryComponentKey,
  getHandleComponentKey,
  getHandlePosition,
  isNodeTitleValid,
  nodeHasEdge,
  WorkbenchNodeData,
  WorkbenchNodeEntry,
  WorkbenchNodeHandle,
} from "./WorkbenchData";
import { BiEdit, BiX } from "react-icons/bi";
import { displayableLabel } from "./NodeDescriptorUtil";
import { Button } from "baseui/button";
import { Input } from "baseui/input";
import { FormControl } from "baseui/form-control";
import { DirectionalStack, HorizontalSpacer } from "../DesignSystem/Containers";

export const WORKBENCH_NODE_STYLE: CSSProperties = {
  background: "#eee",
  border: "1px solid #454545",
  borderRadius: 5,
  paddingLeft: "5px",
};

export const WORKBENCH_NODE_PADDING = 5;
export const WORKBENCH_NODE_FIELD_STYLE: CSSProperties = {
  padding: WORKBENCH_NODE_PADDING + "px",
};

export const WORKBENCH_NODE_HANDLE_STYLE: CSSProperties = {
  background: "#555",
};

export function createWorkbenchNodeHandle(
  refCallback: (element: HTMLDivElement) => void,
  handle: WorkbenchNodeHandle,
  pathType: PathType.INPUT | PathType.OUTPUT
): JSX.Element {
  const type = pathType == PathType.INPUT ? "target" : "source";
  const position = getHandlePosition(handle, pathType);
  const handleKey = getHandleComponentKey(position, handle.id);

  return (
    <Handle
      ref={refCallback}
      type={type}
      position={position}
      id={handleKey}
      key={handleKey}
      style={WORKBENCH_NODE_HANDLE_STYLE}
    />
  );
}

export interface HandleRefTuple {
  inputHandle: HTMLDivElement | null;
  inputPosition: Position | null;
  outputHandle: HTMLDivElement | null;
  outputPosition: Position | null;
}

export interface WidgetHandleRefTuple extends HandleRefTuple {
  widget: HTMLElement | null;
}

export function createHandleComponentsForHandle(
  handle: WorkbenchNodeHandle,
  pathType: PathType,
  refs: HandleRefTuple
): [JSX.Element, JSX.Element] {
  return [
    (pathType == PathType.INPUT || pathType == PathType.BOTH) &&
      createWorkbenchNodeHandle(
        (item) => {
          refs.inputHandle = item;
          refs.inputPosition = getHandlePosition(handle, PathType.INPUT);
        },
        handle,
        PathType.INPUT
      ),
    (pathType == PathType.OUTPUT || pathType == PathType.BOTH) &&
      createWorkbenchNodeHandle(
        (item) => {
          refs.outputHandle = item;
          refs.outputPosition = getHandlePosition(handle, PathType.OUTPUT);
        },
        handle,
        PathType.OUTPUT
      ),
  ];
}

export function entryComponentWithHandles(
  entry: WorkbenchNodeEntry,
  handle: WorkbenchNodeHandle,
  entryComponent: React.ReactNode,
  refs: WidgetHandleRefTuple
): JSX.Element {
  const entryKey = getEntryComponentKey(entry.id);

  // lay out the component with optional left and right handles
  return (
    <div id={entryKey} key={entryKey} style={WORKBENCH_NODE_FIELD_STYLE}>
      <div ref={(item) => (refs.widget = item)}>{entryComponent}</div>
      {createHandleComponentsForHandle(handle, handle.path.pathType, refs)}
    </div>
  );
}

export function alignHandleTuple(refTuple: WidgetHandleRefTuple): void {
  alignHandle(refTuple.widget, refTuple.inputHandle, refTuple.inputPosition);
  alignHandle(refTuple.widget, refTuple.outputHandle, refTuple.outputPosition);
}

export function alignHandle(
  widget: HTMLElement,
  handle: HTMLDivElement,
  position: Position,
  additionalOffset: number = 0
): void {
  if (widget) {
    if (position == Position.Left || position == Position.Right) {
      // position the handle at the midpoint of this widget
      const handleTop =
        Math.floor(
          additionalOffset +
            getHandleOffsetTop(widget) +
            widget.offsetHeight / 2
        ) + "px";

      // set the vertical coordinates of the handle
      if (handle) {
        handle.style.top = handleTop;
      }
    } else if (position == Position.Top || position == Position.Bottom) {
      // position the handle at the midpoint of this widget
      const handleLeft =
        Math.floor(
          additionalOffset +
            getHandleOffsetLeft(widget) +
            widget.offsetWidth / 2
        ) + "px";

      // set the horizontal coordinates of the handle
      if (handle) {
        handle.style.left = handleLeft;
      }
    }
  }
}

export interface WorkbenchNodeEntryProps {
  entry: WorkbenchNodeEntry;
}

export interface WorkbenchNodeProps<
  P extends WorkbenchNodeEntryProps = WorkbenchNodeEntryProps
> {
  data: WorkbenchNodeData;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  subtitle?: React.ReactNode;
  mainComponent?: React.ReactNode;
  entryComponent?: (props: P) => JSX.Element;
  getEntryProps?: (entry: WorkbenchNodeEntry) => P;
  getEntryComponent?: (
    entry: WorkbenchNodeEntry,
    handle: WorkbenchNodeHandle,
    handleRefs: React.MutableRefObject<Map<number, WidgetHandleRefTuple>>,
    nodeProps: WorkbenchNodeProps<P>
  ) => JSX.Element;
}

export function WorkbenchNodeComponent<
  P extends WorkbenchNodeEntryProps = WorkbenchNodeEntryProps
>(props: WorkbenchNodeProps<P>): JSX.Element {
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const data = props.data;
  const handleRefs = useRef(new Map<number, WidgetHandleRefTuple>());
  const { projectState, projectDispatch } = useContext(ProjectContext);

  // this action gets dispatched to the project
  const doRemoveNode = useCallback(
    (nodeId: string) => {
      // create a node removal change for this node
      const changes: NodeChange[] = [
        {
          id: nodeId,
          type: "remove",
        },
      ];
      projectDispatch(["changeNodes", { changes }]);
    },
    // projectDispatch is guaranteed to be stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const startTitleEditing = useCallback(
    (nodeId: string) => {
      projectDispatch(["startTitleEditing", { nodeId }]);
    },
    // projectDispatch is guaranteed to be stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const updateTitleEdit = useCallback(
    (nodeId: string, wipTitle: string) => {
      projectDispatch(["updateTitleEdit", { nodeId, wipTitle }]);
    },
    // projectDispatch is guaranteed to be stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const confirmTitleEdit = useCallback(
    (nodeId: string) => {
      projectDispatch(["confirmTitleEdit", { nodeId }]);
    },
    // projectDispatch is guaranteed to be stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const discardTitleEdit = useCallback(
    (nodeId: string) => {
      projectDispatch(["discardTitleEdit", { nodeId }]);
    },
    // projectDispatch is guaranteed to be stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // determine the components to display
  // if we have a main component, display it; otherwise display an entry component for each entry
  const myGetEntryComponent =
    props.getEntryComponent ?? defaultGetEntryComponent;
  const myMainComponent = props.mainComponent ?? (
    <>
      {data.entries.map((entry) =>
        myGetEntryComponent(
          entry,
          findNodeHandleForEntry(data, entry.id),
          handleRefs,
          props
        )
      )}
    </>
  );

  function handleDelete(): void {
    if (nodeHasEdge(rfEdges, data.dataID)) {
      setIsDeleteConfirmationOpen(true);
    } else {
      doRemoveNode(data.dataID);
    }
  }

  // this is needed to make the handles line up with their widgets
  useLayoutEffect(() =>
    handleRefs.current.forEach(alignHandleTuple, [handleRefs])
  );

  const { rfEdges } = projectState;
  const showValidationError =
    (data.titleInProgress ?? "").length > 0 &&
    !isNodeTitleValid(data.titleInProgress ?? "");

  // create one node with as many entries as it needs
  return (
    <div
      style={{ ...WORKBENCH_NODE_STYLE, outline: "none" }}
      onKeyDown={(e) => {
        if (e.key === "Backspace" || e.key === "Delete") {
          handleDelete();
        }
      }}
      tabIndex={0}
    >
      <DeleteConfirmationModal
        isOpen={isDeleteConfirmationOpen}
        header={"Delete Node"}
        bodyText={`Are you sure you want to delete this node? It currently has at least one edge attached to it.`}
        onDelete={() => {
          doRemoveNode(data.dataID);
          setIsDeleteConfirmationOpen(false);
        }}
        onCancel={() => setIsDeleteConfirmationOpen(false)}
      />

      {props.header}

      <DirectionalStack direction="row">
        {data.title ? (
          <div style={{ ...COMMON_TEXT_STYLING, fontSize: "1.3rem" }}>
            {!data.titleEditInProgress ? (
              <>
                {data.title}
                <Button
                  size="mini"
                  kind="tertiary"
                  onClick={() => startTitleEditing(data.dataID)}
                >
                  <BiEdit></BiEdit>
                </Button>
              </>
            ) : (
              <div className="nodrag">
                <FormControl
                  error={
                    showValidationError && "Title must be a valid file name"
                  }
                >
                  <Input
                    autoFocus
                    value={data.titleInProgress ?? ""}
                    type="text"
                    size="mini"
                    onChange={(e) => {
                      updateTitleEdit(data.dataID, e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        // whitespace-only fields are not allowed
                        if (data.titleInProgress.trim().length == 0) {
                          discardTitleEdit(data.dataID);
                        }
                        // invalid titles must not be accepted
                        else if (showValidationError) {
                          // do nothing
                        } else {
                          confirmTitleEdit(data.dataID);
                        }
                      } else if (e.key === "Escape") {
                        discardTitleEdit(data.dataID);
                      }
                      e.stopPropagation();
                    }}
                    onBlur={() => discardTitleEdit(data.dataID)}
                  ></Input>
                </FormControl>
              </div>
            )}
          </div>
        ) : (
          <HorizontalSpacer width="10px"></HorizontalSpacer>
        )}
        <button
          onMouseOver={() => setIsMouseOver(true)}
          onMouseOut={() => setIsMouseOver(false)}
          onClick={handleDelete}
          style={{
            borderLeft: isMouseOver ? "solid .05px black" : "none",
            borderBottom: isMouseOver ? "solid .05px black" : "none",
            borderRight: "none",
            borderTop: "none",
            backgroundColor: "#eee",
            marginLeft: "auto",
            float: "right",
            cursor: "pointer",
          }}
        >
          <BiX size={19} />
        </button>
      </DirectionalStack>

      {props.subtitle}

      {myMainComponent}

      {props.footer}
    </div>
  );
}

export function defaultGetEntryComponent<
  P extends WorkbenchNodeEntryProps = WorkbenchNodeEntryProps
>(
  entry: WorkbenchNodeEntry,
  handle: WorkbenchNodeHandle,
  handleRefs: React.MutableRefObject<Map<number, WidgetHandleRefTuple>>,
  nodeProps: WorkbenchNodeProps<P>
): JSX.Element {
  const refTuple: WidgetHandleRefTuple = {
    widget: null,
    inputHandle: null,
    inputPosition: null,
    outputHandle: null,
    outputPosition: null,
  };
  handleRefs.current.set(handle.id as number, refTuple);

  // although we can't enforce this from a type safety standpoint, we'll assume that
  // if getEntryProps isn't provided, the basic WorkbenchNodeEntryProps is acceptable
  const entryProps: P | WorkbenchNodeEntryProps = nodeProps.getEntryProps
    ? nodeProps.getEntryProps(entry)
    : { entry };

  const entryComponent = nodeProps.entryComponent ? (
    nodeProps.entryComponent(entryProps as P)
  ) : (
    <span style={COMMON_TEXT_STYLING}>
      {displayableLabel("", entry.label, entry.labelRange)}
    </span>
  );

  return entryComponentWithHandles(entry, handle, entryComponent, refTuple);
}
