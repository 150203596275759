/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { ApolloError, FetchResult } from "@apollo/client";
import { notify } from "./Notify";

export function notifyGeneralError(error: Error, message: string): void {
  notify.negative(`${error.message}: ${message}`);
}

export function handleApolloError(
  error: ApolloError,
  actionDescription: string
): void {
  if (!error) {
    return;
  }

  let fullMessage = actionDescription;

  if (error.networkError) {
    fullMessage += " " + error.networkError.message;
  }
  if (error.graphQLErrors) {
    fullMessage += " " + readableGqlError(error);
  }

  notify.negative(fullMessage);
}

export function hasServerErrors(
  response: FetchResult<any, Record<string, any>, Record<string, any>>
): boolean {
  return response.errors && response.errors.length > 0;
}

export function readableServerErrors(
  response: FetchResult<any, Record<string, any>, Record<string, any>>
): string {
  const errorText = response.errors.map(
    (error) => `${error.message} (${readableLocations(error.locations)})`
  );
  return errorText.join(",");
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function readableGqlError(errors: any): string {
  const errorText = errors.graphQLErrors.map((error) => {
    const message = error.message;
    if (!message) {
      return JSON.stringify(error);
    }

    // GQL query did not match schema
    if (message.includes("Validation error")) {
      return `${message} (${readableLocations(error.locations)}) `;
    }

    // Exception thrown in the body of the GQL method.
    if (message.includes("Exception while")) {
      return `${message} (${readableLocations(error.locations)})`;
    }

    // We don't have good example data for what to do with this;
    // Just stringify the whole thing to get more out of it.
    // In a validation error, networkError is null.
    return JSON.stringify(error);
  });
  return errorText.join(",");
}

function readableLocations(locations: any): string {
  return (
    "Gql " +
    locations
      .map((location) => `Line ${location.line} Col ${location.column}`)
      .join(",")
  );
}
