/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import SearchInput from "../Shared/SearchInput";
import ModelSearchResultTable, {
  SelectableResultItem,
} from "./ModelSearchResultTable";
import { gql, useQuery } from "@apollo/client";
import { handleApolloError } from "../Shared/Errors";
import findModelsWithText from "../Api/Gql/FindModelsWithText";
import { ModelSearchCommandBar } from "./ModelSearchCommandBar";
import { Labels, Pagination } from "baseui/pagination";
import { ModelQueryResponse, QuerySettingsInput, SortOrder } from "Api";
import { boundWithinRange } from "../Utils/Common";
import {
  CenterControls,
  VerticalSpacer,
  DirectionalStack,
} from "../DesignSystem/Containers";
import { LoadingPlaceholder } from "../Shared/LoadingPlaceholder";
import { containsAny } from "../Utils/Array";
import { UUID } from "../Utils/Types";

const FIND_MODELS_WITH_TEXT_QUERY_NAME = "FindModelsWithText";

const PAGE_LABELS: Labels = {
  prevButton: " ",
  nextButton: " ",
  preposition: "of",
};

const PAGE_SIZE = 50;

export function ResourceSearchPage(): JSX.Element {
  const [currentPage, setCurrentPage] = React.useState(1);

  const [searchText, setSearchText] = React.useState("");

  const [sortField, setSortField] = React.useState("title");

  const [sortOrder, setSortOrder] = React.useState<SortOrder>("ASCENDING");

  const querySettings = React.useMemo<QuerySettingsInput>(() => {
    return {
      paging: {
        size: PAGE_SIZE,
        index: currentPage,
      },
      sort: {
        fieldName: sortField,
        order: sortOrder,
      },
    };
  }, [currentPage, sortField, sortOrder]);

  const { loading, data } = useQuery(
    gql(findModelsWithText),
    // TODO: See if we can provide a generic handler object / function for simple queries
    // like this that takes in the user error message as a parameter
    {
      fetchPolicy: "network-only",
      variables: {
        textSubstring: searchText,
        querySettings,
      },
      onError: (error) => handleApolloError(error, "Error fetching models!"),
    }
  );

  const textQueryResponse: ModelQueryResponse = data?.findModelsWithText;
  const modelTableData = React.useMemo(() => {
    return textQueryResponse?.results ?? [];
  }, [textQueryResponse?.results]);
  const pageCount = textQueryResponse?.pageCount ?? 1;

  const [isNewQuery, setIsNewQuery] = React.useState(false);

  const [selectedModelIds, setSelectedModelIds] = React.useState<UUID[]>([]);

  const selectableModelItems = React.useMemo<SelectableResultItem[]>(() => {
    return modelTableData.map((modelInfo) => {
      const isSelected = containsAny(
        selectedModelIds,
        (selectedId) => selectedId === modelInfo.id
      );
      return {
        item: modelInfo,
        isSelected,
      };
    });
  }, [modelTableData, selectedModelIds]);

  const toggleSelection = React.useCallback(
    (selectableModelItem: SelectableResultItem) => {
      if (selectableModelItem.isSelected) {
        // It's selected, remove it from the selection
        const newSelectedIds = selectedModelIds.filter(
          (selectedId) => selectedId !== selectableModelItem.item.id
        );
        setSelectedModelIds(newSelectedIds);
      } else {
        // It's not selected, add it to the selection
        const newSelectedIds = selectedModelIds.concat(
          selectableModelItem.item.id
        );
        setSelectedModelIds(newSelectedIds);
      }
    },
    [selectedModelIds]
  );

  const handleClick = React.useCallback(
    (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
      selectableModelItem: SelectableResultItem
    ) => {
      if (event.ctrlKey) {
        toggleSelection(selectableModelItem);
      } else {
        // Change the selection to the selected item.
        setSelectedModelIds([selectableModelItem.item.id]);
      }
    },
    [toggleSelection]
  );

  const handlePageChange = React.useCallback(
    ({ nextPage }) => {
      const pageToRequest = boundWithinRange(nextPage, 1, pageCount);
      setCurrentPage(pageToRequest);
      setIsNewQuery(false);
    },
    [pageCount]
  );

  const toggleSortOrder = React.useCallback(() => {
    switch (sortOrder) {
      case "ASCENDING":
        setSortOrder("DESCENDING");
        break;
      case "DESCENDING":
        setSortOrder("ASCENDING");
        break;
      default:
        const _exhaustiveCheck: never = sortOrder;
        return _exhaustiveCheck;
    }
  }, [sortOrder]);

  const handleSort = React.useCallback(
    (sortCol: string) => {
      if (sortCol === sortField) {
        toggleSortOrder();
      } else {
        setSortField(sortCol);
        setSortOrder("ASCENDING");
      }
    },
    [toggleSortOrder, sortField]
  );

  const handleSearch = React.useCallback((searchValue: string) => {
    setSearchText(searchValue);
    setCurrentPage(1);
    setIsNewQuery(true);
  }, []);

  return (
    <>
      <ModelSearchCommandBar
        refetchQueryName={FIND_MODELS_WITH_TEXT_QUERY_NAME}
      />
      <SearchInput placeholder="Search everything" onSearch={handleSearch} />

      {loading && isNewQuery ? (
        <LoadingPlaceholder message={"Searching..."} />
      ) : (
        <DirectionalStack>
          <VerticalSpacer height="2rem" />
          <CenterControls>
            <Pagination
              currentPage={currentPage}
              numPages={pageCount}
              onPageChange={handlePageChange}
              labels={PAGE_LABELS}
            />
          </CenterControls>
          {loading ? (
            <LoadingPlaceholder message={"Getting data..."} />
          ) : (
            <ModelSearchResultTable
              selectableResultItems={selectableModelItems}
              refetchQueryName={FIND_MODELS_WITH_TEXT_QUERY_NAME}
              onSort={handleSort}
              onClick={handleClick}
            />
          )}
          <CenterControls>
            <Pagination
              currentPage={currentPage}
              numPages={pageCount}
              onPageChange={handlePageChange}
              labels={PAGE_LABELS}
            />
          </CenterControls>
        </DirectionalStack>
      )}
    </>
  );
}
