/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { ModelMetadata, ModelMetadataSchema } from "Api";
import _ from "lodash";
import {
  FieldSpec,
  multiLineTextFieldSpec,
  pickListFieldSpec,
  SimpleTextFieldSpec,
  simpleTextFieldSpec,
  tagListFieldSpec,
} from "../Shared/FieldSpec";
import { compareCaseInsensitive, equalIgnoreCase } from "../Utils/Sort";

export const TITLE_FIELD = simpleTextFieldSpec("Title");
export const SUBJECT_FIELD = simpleTextFieldSpec("Subject");
export const VERSION_FIELD = simpleTextFieldSpec("Version");
export const DESCRIPTION_FIELD = multiLineTextFieldSpec("Description");
export const KEYWORDS_FIELD = tagListFieldSpec("Keywords");
export const NOTES_FIELD = multiLineTextFieldSpec("Notes");

// It seems like there should be a way to use keyof to determine this
// list, but the type information is erased at runtime.
const FIXED_SCHEMA_FIELDS: FieldSpec[] = [
  TITLE_FIELD,
  SUBJECT_FIELD,
  VERSION_FIELD,
  DESCRIPTION_FIELD,
  KEYWORDS_FIELD,
  NOTES_FIELD,
];

function isFixedSchema(propertyName: string): boolean {
  if (propertyName == "customProps") {
    return false;
  }
  return FIXED_SCHEMA_FIELDS.find(
    (field) => field.propertyName === propertyName
  )
    ? true
    : false;
}

export function isExpandedSchema(
  propertyName: string,
  userDefinedModelSchema?: ModelMetadataSchema
): boolean {
  // This is a special case; customProps is where all user-defined
  // schema information is stored.
  if (equalIgnoreCase(propertyName, "customProps")) {
    return true;
  }
  const fieldForProperty = allMetadataFields(userDefinedModelSchema).filter(
    (value) => equalIgnoreCase(value.propertyName, propertyName)
  );
  return fieldForProperty && fieldForProperty.length > 0;
}

export function allMetadataFields(
  userDefinedModelSchema?: ModelMetadataSchema
): FieldSpec[] {
  const fields: FieldSpec[] = [...FIXED_SCHEMA_FIELDS];
  if (userDefinedModelSchema) {
    const pickListFields = userDefinedModelSchema.fields.selectMultiple?.map(
      (pickListSchema) => {
        return pickListFieldSpec(pickListSchema);
      }
    );
    if (pickListFields) {
      fields.push(...pickListFields);
    }
  }
  return fields;
}

export function concatUnspecifiedFields(
  fields: FieldSpec[],
  customProps?: Record<string, any>
): FieldSpec[] {
  if (!customProps) {
    return fields;
  }

  const schemaPropertyNames = fields.map((field) => field.propertyName);
  const unspecifiedPropNames = Object.keys(customProps)
    .filter(
      (customPropertyName) => !schemaPropertyNames.includes(customPropertyName)
    )
    .sort(compareCaseInsensitive);

  const unspecifiedFields = unspecifiedPropNames.map((unspecifiedPropName) => {
    const fieldForProperty: SimpleTextFieldSpec = {
      type: "SimpleTextField",
      title: unspecifiedPropName,
      propertyName: unspecifiedPropName,
    };
    return fieldForProperty;
  });

  return fields.concat(unspecifiedFields);
}

export function flattenPresentMetadata(
  modelMetadata: ModelMetadata
): Record<string, any> {
  if (!modelMetadata) {
    return {};
  }
  const values = {
    ...modelMetadata.customProps,
    ...modelMetadata,
  };
  delete values.customProps;

  Object.keys(values).forEach((propertyName) => {
    if (values[propertyName] == null) {
      delete values[propertyName];
    }
  });
  return values;
}

export function applyMetadataProperty(
  modelMetadata: ModelMetadata,
  propertyName: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  value: any
): ModelMetadata {
  const updatedMetadata: ModelMetadata = {
    ...modelMetadata,
  };
  if (isFixedSchema(propertyName)) {
    updatedMetadata[propertyName] = value;
  } else {
    if (!updatedMetadata.customProps) {
      updatedMetadata.customProps = {};
    }
    // TODO: This does not work if tsconfig is set to es2020 instead of commonjs.
    // It appears that customProps has preventExtensions() called on it.
    updatedMetadata.customProps = {
      ...updatedMetadata?.customProps,
      [propertyName]: value,
    };
  }

  return updatedMetadata;
}
