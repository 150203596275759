/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import React, { useCallback, useContext, useReducer, useState } from "react";
import { useEffect } from "react";
import {
  CSVNodeEntryStatus,
  CSVNodeReducer,
  CSVNodeState,
} from "./CSVNodeState";
import { OverflowActions, OverflowMenu } from "../Shared/OverflowMenu";
import {
  WorkbenchNodeComponent,
  WorkbenchNodeEntryProps,
  WorkbenchNodeProps,
} from "./WorkbenchNode";
import { DeleteConfirmationModal } from "../Shared/DeleteConfirmationModal";
import { COMMON_TEXT_STYLING } from "../Utils/SiteProps";
import { ProjectContext } from "../UserApp";
import {
  canAddEntries,
  entryHasEdge,
  isEntryRemovable,
  WorkbenchNodeEntry,
  WorkbenchNodeEntryId,
} from "./WorkbenchData";
import { PathType } from "../Api/DataTransformation";
import { useUpdateNodeInternals } from "reactflow";
import { displayableLabel } from "./NodeDescriptorUtil";

export function CSVNodeComponent(
  props: WorkbenchNodeProps<CSVNodeEntryProps>
): JSX.Element {
  const { projectState, projectDispatch } = useContext(ProjectContext);
  const { rfEdges } = projectState;
  const nodeData = props.data;
  const nodeId = nodeData.dataID;

  const updateNodeInternals = useUpdateNodeInternals();

  const [state, dispatch] = useReducer(
    CSVNodeReducer,
    null,
    () => new CSVNodeState()
  );
  const [deleteEntryConfirmationNeeded, setDeleteEntryConfirmationNeeded] =
    useState<WorkbenchNodeEntryId>(undefined);

  const modifiedNodeData = state.nodeData;

  // these get dispatched to the project
  const addEntry = useCallback(
    (entryId: WorkbenchNodeEntryId, delta: number) => {
      projectDispatch(["addSpecificEntry", { nodeId, entryId, delta }]);
      updateNodeInternals(nodeId);
    },
    // projectDispatch is guaranteed to be stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nodeId]
  );
  const removeEntry = useCallback(
    (entryId: WorkbenchNodeEntryId) => {
      projectDispatch(["removeSpecificEntry", { nodeId, entryId }]);
      updateNodeInternals(nodeId);
    },
    // projectDispatch is guaranteed to be stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nodeId]
  );

  // these are managed by the node's own reducer
  const startEditing = useCallback((entryId: WorkbenchNodeEntryId) => {
    dispatch(["startEditing", { entryId }]);
  }, []);
  const updateEdit = useCallback(
    (entryId: WorkbenchNodeEntryId, wipLabel: string) => {
      dispatch(["updateEdit", { wipLabel, entryId }]);
    },
    []
  );
  const confirmEdit = useCallback((entryId: WorkbenchNodeEntryId) => {
    dispatch(["confirmEdit", { entryId }]);
  }, []);
  const discardEdit = useCallback((entryId: WorkbenchNodeEntryId) => {
    dispatch(["discardEdit", { entryId }]);
  }, []);

  const localEntryHasEdge = useCallback(
    (entryId: WorkbenchNodeEntryId) => {
      return entryHasEdge(rfEdges, nodeData, entryId);
    },
    [rfEdges, nodeData]
  );
  const entryRemovable = useCallback(
    (entryId: WorkbenchNodeEntryId): boolean => {
      return isEntryRemovable(nodeData, entryId);
    },
    [nodeData]
  );
  const confirmAndRemoveEntry = useCallback((entryId: WorkbenchNodeEntryId) => {
    setDeleteEntryConfirmationNeeded(entryId);
  }, []);

  // needed to make each entry interact with the main node
  const getEntryProps = useCallback(
    (entry: WorkbenchNodeEntry) => {
      const entryStatus = state.entryStatuses.get(entry.id as number) ?? {
        editInProgress: false,
        labelInProgress: "",
      };
      const props: CSVNodeEntryProps = {
        entry,
        entryHasEdge: localEntryHasEdge,
        entryStatus,
        startEditing,
        updateEdit,
        confirmEdit,
        discardEdit,
        removeEntry,
        addEntry,
        confirmAndRemoveEntry,
        entryRemovable,
      };
      return props;
    },
    [
      state.entryStatuses,
      localEntryHasEdge,
      startEditing,
      updateEdit,
      confirmEdit,
      discardEdit,
      removeEntry,
      addEntry,
      confirmAndRemoveEntry,
      entryRemovable,
    ]
  );

  // notify the node any time the workbench data changes, including a reload
  useEffect(() => {
    dispatch(["navigate", { nodeData }]);
  }, [nodeData]);

  // notify the workbench any time our node data changes
  useEffect(
    () => {
      if (modifiedNodeData) {
        projectDispatch(["modifyNodeData", { modifiedNodeData }]);
      }
    },
    // projectDispatch is guaranteed to be stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modifiedNodeData]
  );

  return (
    <>
      <DeleteConfirmationModal
        isOpen={deleteEntryConfirmationNeeded !== undefined}
        header={"Delete Entry"}
        bodyText={`Are you sure you want to delete this entry? It currently has an edge attached to it.`}
        onDelete={() => {
          removeEntry(deleteEntryConfirmationNeeded);
          setDeleteEntryConfirmationNeeded(undefined);
        }}
        onCancel={() => setDeleteEntryConfirmationNeeded(undefined)}
      />
      {WorkbenchNodeComponent({
        ...props,
        entryComponent: CSVNodeEntryComponent,
        getEntryProps,
      })}
    </>
  );
}

interface CSVNodeEntryProps extends WorkbenchNodeEntryProps {
  entry: WorkbenchNodeEntry;
  entryHasEdge: (entryId: WorkbenchNodeEntryId) => boolean;
  entryStatus: CSVNodeEntryStatus;
  startEditing: (entryId: WorkbenchNodeEntryId) => void;
  updateEdit: (entryId: WorkbenchNodeEntryId, wipLabel: string) => void;
  confirmEdit: (entryId: WorkbenchNodeEntryId) => void;
  discardEdit: (entryId: WorkbenchNodeEntryId) => void;
  removeEntry: (
    entryId: WorkbenchNodeEntryId,
    removeEvenIfHasEdge: boolean
  ) => void;
  addEntry: (entryId: WorkbenchNodeEntryId, delta: number) => void;
  confirmAndRemoveEntry: (entryId: WorkbenchNodeEntryId) => void;
  entryRemovable: (entryId: WorkbenchNodeEntryId) => boolean;
}

function CSVNodeEntryComponent(props: CSVNodeEntryProps): JSX.Element {
  const entry = props.entry;
  const status = props.entryStatus;

  const overflowActions: OverflowActions[] = [
    {
      label: "Rename",
      onClick: () => props.startEditing(entry.id),
    },
    { label: "Add Above", onClick: () => props.addEntry(entry.id, 0) },
    { label: "Add Below", onClick: () => props.addEntry(entry.id, 1) },
    {
      label: "Delete",
      onClick: () => {
        if (props.entryHasEdge(entry.id)) {
          props.confirmAndRemoveEntry(entry.id);
        } else {
          props.removeEntry(entry.id, false);
        }
      },
      disabled: !props.entryRemovable(entry.id),
    },
  ];

  // the dropdown should always be on the inside of the widget
  const float = entry.path.pathType == PathType.OUTPUT ? "left" : "right";

  return status.editInProgress ? (
    <input
      autoFocus
      defaultValue={entry.label ?? ""}
      type="text"
      onChange={(e) => props.updateEdit(entry.id, e.target.value)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          // whitespace-only fields are not allowed
          if (status.labelInProgress.trim().length == 0) {
            props.discardEdit(entry.id);
          } else {
            props.confirmEdit(entry.id);
          }
        } else if (e.key === "Escape") {
          props.discardEdit(entry.id);
        }
        e.stopPropagation();
      }}
      onBlur={() => props.discardEdit(entry.id)}
      className="nodrag"
    ></input>
  ) : (
    <>
      <span style={{ ...COMMON_TEXT_STYLING, justifyItems: "space-between" }}>
        {displayableLabel("", entry.label, entry.labelRange)}
        {canAddEntries(entry.path, 1) && (
          <OverflowMenu overflowActions={overflowActions} float={float} />
        )}
      </span>
    </>
  );
}
