/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import React from "react";
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
} from "baseui/modal";

import { ButtonDestructiveStyle } from "../DesignSystem/SimorButtons";
import { TOP_ITEM_ZINDEX } from "../Utils/SiteProps";

export const DeleteConfirmationModal = ({
  isOpen,
  header,
  bodyText,
  onDelete,
  onCancel,
}: {
  isOpen: boolean;
  header: string;
  bodyText: string;
  onDelete: () => void;
  onCancel: () => void;
}): JSX.Element => {
  return (
    <Modal
      overrides={{
        Root: {
          style: {
            zIndex: TOP_ITEM_ZINDEX,
          },
        },
      }}
      isOpen={isOpen}
      onClose={onCancel}
      // The unstable_ModalBackdropScroll will be removed in the next major baseui version.
      // It was recommended to set it to true to prepare for its removal.
      unstable_ModalBackdropScroll={true}
    >
      <ModalHeader>{header}</ModalHeader>
      <ModalBody>{bodyText}</ModalBody>
      <ModalFooter>
        <ModalButton kind={"tertiary"} onClick={onCancel}>
          Cancel
        </ModalButton>
        <ModalButton
          kind={"primary"}
          overrides={ButtonDestructiveStyle}
          onClick={onDelete}
        >
          Delete
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
};
