/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import React, { useLayoutEffect, useRef } from "react";
import { Position } from "reactflow";
import "reactflow/dist/style.css";
import { PathType } from "../Api/DataTransformation";
import { DirectionalStack } from "../DesignSystem/Containers";

import {
  alignHandleTuple,
  defaultGetEntryComponent,
  WidgetHandleRefTuple,
  WorkbenchNodeComponent,
  WorkbenchNodeEntryProps,
  WorkbenchNodeProps,
} from "./WorkbenchNode";
import { findNodeHandleForEntry, getHandlePosition } from "./WorkbenchData";

export interface DividedListNodeProps<
  P extends WorkbenchNodeEntryProps = WorkbenchNodeEntryProps
> extends WorkbenchNodeProps<P> {
  centerComponent?: React.ReactNode;
}

export function DividedListNodeComponent<
  P extends WorkbenchNodeEntryProps = WorkbenchNodeEntryProps
>(props: DividedListNodeProps<P>): JSX.Element {
  const handleRefs = useRef(new Map<number, WidgetHandleRefTuple>());
  const data = props.data;

  // use the component supplied for the props, if any
  const myGetEntryComponent =
    props.getEntryComponent ?? defaultGetEntryComponent;

  // see if top/bottom outnumber left/right
  let numTopBottom = 0;
  let numLeftRight = 0;
  data.entries.forEach((entry) => {
    const handle = findNodeHandleForEntry(data, entry.id);
    if (
      handle.path.pathType == PathType.INPUT ||
      handle.path.pathType == PathType.OUTPUT
    ) {
      const position = getHandlePosition(handle, handle.path.pathType);
      if (position == Position.Top || position == Position.Bottom) {
        numTopBottom++;
      } else {
        numLeftRight++;
      }
    }
  });
  const verticalDivide = numLeftRight >= numTopBottom;

  const both = data.entries.filter(
    (entry) => entry.path.pathType == PathType.BOTH
  );
  const input = data.entries.filter(
    (entry) => entry.path.pathType == PathType.INPUT
  );
  const output = data.entries.filter(
    (entry) => entry.path.pathType == PathType.OUTPUT
  );

  // this is needed to make the handles line up with their widgets
  useLayoutEffect(() =>
    handleRefs.current.forEach(alignHandleTuple, [handleRefs])
  );

  return WorkbenchNodeComponent({
    ...props,
    mainComponent: (
      <>
        {both.map((entry) =>
          myGetEntryComponent(
            entry,
            findNodeHandleForEntry(data, entry.id),
            handleRefs,
            props
          )
        )}
        <DirectionalStack
          direction={verticalDivide ? "row" : "column"}
          gap={"0px"}
          style={{ justifyContent: "space-between" }}
        >
          <DirectionalStack
            direction={verticalDivide ? "column" : "row"}
            gap={"0px"}
          >
            {input.map((entry) =>
              myGetEntryComponent(
                entry,
                findNodeHandleForEntry(data, entry.id),
                handleRefs,
                props
              )
            )}
          </DirectionalStack>

          {props.centerComponent}

          <DirectionalStack
            direction={verticalDivide ? "column" : "row"}
            gap={"0px"}
          >
            {output.map((entry) =>
              myGetEntryComponent(
                entry,
                findNodeHandleForEntry(data, entry.id),
                handleRefs,
                props
              )
            )}
          </DirectionalStack>
        </DirectionalStack>
      </>
    ),
  });
}
