/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { InMemoryCache, makeVar, ReactiveVar } from "@apollo/client";

// Local state can be saved in reactive vars. These values can be added to a query. Ex:
// export const cache = new InMemoryCache({
//   typePolicies: {
//     Query: {
//       fields: {
//         cartItems: {
//           read() {
//             return cartItemsVar();
//           }
//         }
//       }
//     }
//   }
// });
// Anytime the cartItemsVar is updated in the application, Apollo Client notifies every active query that includes the cartItems field.
//
// See: https://www.apollographql.com/docs/react/local-state/managing-state-with-field-policies/#storing-local-state-in-reactive-variables
export const cache = new InMemoryCache({
  addTypename: false,
});

// When calling these reactive vars in react components (not through a query), it is necessary to wrap them in the
// useReactiveVar hook to make sure the react component notices a state change and updates.
// "With the useReactiveVar hook, React components can also include reactive variable
// values in their state directly, without wrapping them in a query." -Apollo docs
//
// See: https://www.apollographql.com/docs/react/local-state/reactive-variables/
export const isSensitiveSecurityContextVar: ReactiveVar<boolean> =
  makeVar<boolean>(true);

export const setIsSensitiveSecurityContextVar = (value: boolean): boolean =>
  isSensitiveSecurityContextVar(value);
