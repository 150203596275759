/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
export default `query FindModelsWithText($textSubstring: String!, $querySettings: QuerySettingsInput) {
  findModelsWithText (textSubstring: $textSubstring, querySettings: $querySettings) {
    pageCount,
    results {
      id,
      metadata {
        title,
        version,
        description,
        keywords
      },
      securityMarkings {
        classificationLevel
        compartments {
          classificationLevel
          agencyToCompartments
        }
      },
      files{
        __typename
        ... on Redaction {
          message
        }
        ... on ModelFile {
          fileId,
          fileName
          securityMarkings {
            classificationLevel
            compartments {
              classificationLevel
              agencyToCompartments
            }
          }
        }
      }
    } 
  },
}`;
