/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import {
  AuthenticatedUser,
  JwtRefreshResponse,
  LoginRequest,
  LoginResponse,
} from "Api";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { notifyGeneralError } from "../Shared/Errors";

export interface DecodedAuthToken {
  aud: string;
  exp: number;
  iss: string;
}

function scheduleTask(
  millisFromNow: number,
  doTask: () => Promise<void>
): () => void {
  const renewTask = window.setTimeout(() => {
    doTask();
  }, millisFromNow);

  return () => {
    window.clearTimeout(renewTask);
  };
}

function getNewAuthToken(refreshResponse?: JwtRefreshResponse): string {
  if (!refreshResponse) {
    throw Error("Refresh response was empty");
  }

  if (refreshResponse.errors) {
    throw Error(
      `Errors occurred on the server while refreshing; ${refreshResponse.errors.toString()}`
    );
  }
  return refreshResponse.authToken;
}

export class SimorAuth {
  public constructor(
    private _user: AuthenticatedUser,
    private _authorized: boolean,
    private onUpdateUser?: (newUser: AuthenticatedUser) => void,
    private onUpdateAuthToken?: (
      newAuthToken: string,
      expiresAtUtcMillis?: number
    ) => void
  ) {}

  public get user(): AuthenticatedUser {
    return this._user;
  }

  /**
   * @returns true if there's no user data in local storage.
   */
  public isAuthorized(): boolean {
    return this._authorized;
  }

  public async refreshAuthToken(): Promise<void> {
    const cancelRefreshToken = axios.CancelToken.source();
    try {
      // No axiosContext is needed here; authorization read from
      // httpOnly cookie on the server side.
      const postResponse = await axios.get("/refresh_token", {
        cancelToken: cancelRefreshToken.token,
      });

      const newAuthToken = getNewAuthToken(postResponse?.data);
      this.handleNewToken(newAuthToken);
    } catch (error) {
      notifyGeneralError(error, "Refresh unsuccessful");
    }
  }

  private handleNewToken(newAuthToken?: string): void {
    let decodedNewToken: DecodedAuthToken | null = null;

    if (newAuthToken) {
      decodedNewToken = jwtDecode<DecodedAuthToken>(newAuthToken);
    }

    let expiresAtUtcMillis: number | null = null;
    if (decodedNewToken) {
      expiresAtUtcMillis = decodedNewToken.exp * 1000;
    }

    if (this.onUpdateAuthToken) {
      this.onUpdateAuthToken(newAuthToken, expiresAtUtcMillis);
    }

    if (expiresAtUtcMillis) {
      this.scheduleRenewal(expiresAtUtcMillis);
    }
  }

  private scheduleRenewal(expiresAtUtcMillis: number): void {
    const remainingTime = expiresAtUtcMillis - Date.now();

    // Refresh with 10% of our allotted time remaining to allow for
    // server communication issues. To make sure we don't run into
    // server time drift issues, make sure this value doesn't fall
    // below 10 seconds of the expected expiration time.
    const safeRefreshWindow = Math.max(remainingTime * 0.1, 10000);

    // Determine when to do the refresh, but never make it more frequent than
    // every 10 seconds.
    const millisFromNowToRefresh = Math.max(
      remainingTime - safeRefreshWindow,
      10000
    );
    scheduleTask(millisFromNowToRefresh, () => this.refreshAuthToken());
  }

  /**
   * Log the user in via their username
   */
  public async loginUser(username: string): Promise<void> {
    const cancelLoginToken = axios.CancelToken.source();
    const loginRequest: LoginRequest = {
      username: username,
    };
    try {
      // No axiosContext is needed here; logging in does not require an
      // authorization header.
      const postResponse = await axios.post("/login", loginRequest, {
        cancelToken: cancelLoginToken.token,
      });
      const loginResponse: LoginResponse = postResponse?.data;
      this.handleNewToken(loginResponse?.authToken);
      if (this.onUpdateUser) {
        this.onUpdateUser(loginResponse?.user);
      }
    } catch (error) {
      notifyGeneralError(error, "Login unsuccessful.");
    }
  }

  /**
   * No local session state is cleared. Must be handled separately
   */
  public async logoutFromServer(): Promise<void> {
    try {
      await axios.post("/logout");
      if (this.onUpdateUser) {
        this.onUpdateUser(undefined);
      }
      if (this.handleNewToken) {
        this.handleNewToken(undefined);
      }
    } catch (error) {
      notifyGeneralError(error, "Logout unsuccessful.");
    }
  }
}
