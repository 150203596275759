/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import { NodeDescriptor, isDataFormat, ChunkType } from "../Api/Api";
import {
  isRange,
  Path,
  PathAction,
  PathElement,
  PathType,
  rangeToExplicit,
} from "../Api/DataTransformation";
import { deepEquals } from "../Utils/Comparison";
import { NumberBucket } from "../Utils/Types";
import {
  ReactFlowNode,
  WorkbenchNodeData,
  WorkbenchNodeEntry,
  WorkbenchNodeEntryId,
  WorkbenchNodeHandle,
  WorkbenchNodeHandleId,
} from "./WorkbenchData";

/**
 * Creates a ReactFlowNode with the specified ID and position, and suitable for the specified descriptor.
 */
export function nodeFromDescriptor(
  dataID: string,
  descriptor: NodeDescriptor,
  position: ReactFlowNode["position"]
): ReactFlowNode {
  // get all entries for all paths (start index at 1)
  const entries = descriptor.allPaths.map((path, index) =>
    createEntryForPath(descriptor, index + 1, path)
  );

  // set up a counter to keep track of all IDs assigned to handles (start at 1)
  const handleIdCounter: NumberBucket = { value: 1 };

  // create handles for entries
  // (each entry has as many handles as path junctions)
  const handles = entries.flatMap((entry) =>
    createHandlesForEntry(handleIdCounter, entry)
  );

  const nodeData: WorkbenchNodeData = {
    dataID,
    title: isDataFormat(descriptor) ? dataID : descriptor.displayName,
    descriptor,
    entries,
    handles,
  };

  // the following UUIDs will always correspond to their respective formats
  let type = undefined;
  switch (descriptor.id) {
    case "41a4cb29-4017-4239-93d8-af41a56136dc":
      type = "csvFormatNode";
      break;
    case "1898e64f-a1d0-48e2-b66c-292f45018aeb":
    case "bddec0ea-8bcc-4cf4-8def-04d1af521d30":
      type = "ewirdbFormatNode";
      break;
    case "88c0b853-dfcd-4768-93b3-d37f3a070637":
    case "a1cfffdf-ada0-4574-8427-a93f7d06e671":
    case "40fc9949-4840-4a32-b2d4-f76da0b5f5e4":
    case "270e8606-dfd7-4f48-a394-e1cecc05cb4a":
      type = "transformTypeNode";
      break;
    case "e9b3419d-88e7-4bd5-b0ce-4a0ecc65070e":
      type = "modifiableTransformTypeNode";
      break;
    case "ea8ea360-a2ad-4579-92a0-c7f389efcfad":
      type = "javascriptTransformTypeNode";
      break;
    default:
      type = "dataFormatNode";
  }

  return {
    id: dataID,
    type,
    position,
    data: { ...nodeData },
  };
}

/**
 * Returns the index of the last path element.
 */
export function lastPathElementIndex(path: Path | PathElement[]): number {
  const pathElements =
    (path as Path).pathElements !== undefined
      ? (path as Path).pathElements
      : (path as PathElement[]);

  return pathElements.length - 1;
}

/**
 * Returns the last path element.
 */
export function lastPathElement(path: Path | PathElement[]): PathElement {
  const pathElements =
    (path as Path).pathElements !== undefined
      ? (path as Path).pathElements
      : (path as PathElement[]);

  if (pathElements.length == 0) {
    return undefined;
  }
  return pathElements[pathElements.length - 1];
}

/**
 * Creates a node entry corresponding to this path.
 */
export function createEntryForPath(
  descriptor: NodeDescriptor,
  entryIndex: number,
  path: Path
): WorkbenchNodeEntry {
  const lastElementIndex = lastPathElementIndex(path);
  if (lastElementIndex < 0) {
    return undefined;
  }

  let pathElements: PathElement[];

  // if the last element has a min or max number, but no byNumber, set one
  const lastElement = path.pathElements[lastElementIndex];
  if (
    lastElement.byNumber == null &&
    (lastElement.minNumber != null || lastElement.maxNumber != null)
  ) {
    const replacementElement: PathElement = {
      ...lastElement,
      byNumber: (lastElement.minNumber ?? 0) > 0 ? lastElement.minNumber : 1,
    };
    pathElements = path.pathElements
      .slice(0, lastElementIndex)
      .concat(replacementElement);
  } else {
    pathElements = path.pathElements;
  }

  // we currently write a single entry regardless of whether it's a range
  return entryFromLastPathElement(
    descriptor,
    entryIndex as WorkbenchNodeEntryId,
    path.pathType,
    pathElements
  );
}

/**
 * Creates all handles needed for this entry: one handle per path junction.
 */
export function createHandlesForEntry(
  handleIdCounter: NumberBucket,
  entry: WorkbenchNodeEntry
): WorkbenchNodeHandle[] {
  const handles = new Array<WorkbenchNodeHandle>();

  // generate handles for every path subset except the end
  // eslint-disable-next-line no-loops/no-loops
  for (
    let subsetLength = 1;
    subsetLength < entry.path.pathElements.length;
    subsetLength++
  ) {
    const subsetElements = pathSubset(entry.path.pathElements, subsetLength);
    const subsetPath: Path = { ...entry.path, pathElements: subsetElements };

    const handle: WorkbenchNodeHandle = {
      id: handleIdCounter.value++ as WorkbenchNodeHandleId,
      path: subsetPath,
    };
    handles.push(handle);
  }

  // generate a handle for the end
  const lastHandle: WorkbenchNodeHandle = {
    id: handleIdCounter.value++ as WorkbenchNodeHandleId,
    entryId: entry.id,
    path: entry.path,
  };
  handles.push(lastHandle);

  return handles;
}

/**
 * Creates a default label suitable for this path element.
 */
export function labelFromPathElement(
  pathType: PathType,
  element: PathElement
): string {
  // non-ranged path actions
  if (element.action == PathAction.TRANSFORM) {
    const text =
      pathType == PathType.INPUT
        ? "In "
        : pathType == PathType.OUTPUT
        ? "Out "
        : "Transform ";

    return (
      text + (element.byString ?? element.byNumber?.toString() ?? "Unknown")
    );
  } else if (element.action == PathAction.MOVE_TO_CHILD) {
    return element.byString ?? "Leaf " + element.byNumber;
  } else if (element.action == PathAction.MOVE_TO_NEXT) {
    return element.byString ?? "Row " + element.byNumber;
  } else if (element.action == PathAction.MOVE_TO_SIBLING) {
    return (
      element.byString ??
      "Column " + String.fromCharCode(65 + element.byNumber - 1)
    );
  }
  // ranged path actions
  else if (isRange(element.action)) {
    // determine the label as if this were not a range
    const explicitElement = {
      ...element,
      action: rangeToExplicit(element.action),
      byNumber: element.byNumber ?? element.minNumber ?? 1,
    };
    return labelFromPathElement(pathType, explicitElement);
  }

  return "<unhandled path action>";
}

/**
 * Creates a label range suitable for this path element, or null if not applicable.
 */
export function labelRangeFromPathElement(element: PathElement): string | null {
  if (isRange(element.action)) {
    const minLabel =
      element.minNumber != null
        ? element.minNumber >= 10
          ? "10+"
          : element.minNumber.toString()
        : "<no minimum>";

    const maxLabel =
      element.maxNumber != null
        ? element.maxNumber >= 10
          ? "N"
          : element.maxNumber.toString()
        : "<no maximum>";

    return "(" + minLabel + " to " + maxLabel + ")";
  }

  return null;
}

/**
 * Returns a label suitable for display, taking into account possible omitted values.
 */
export function displayableLabel(
  defaultLabel: string,
  label?: string,
  labelRange?: string
): string {
  return (
    ((label ?? "").length > 0 ? label : defaultLabel) +
    ((labelRange ?? "").length > 0 ? " " + labelRange : "")
  );
}

/**
 * Creates a node entry from the last element in this path.
 */
export function entryFromLastPathElement(
  descriptor: NodeDescriptor,
  id: WorkbenchNodeEntryId,
  pathType: PathType,
  pathElements: PathElement[]
): WorkbenchNodeEntry {
  const lastElementIndex = lastPathElementIndex(pathElements);
  if (lastElementIndex < 0) {
    return undefined;
  }
  const lastElement = pathElements[lastElementIndex];
  const label = labelFromPathElement(pathType, lastElement);
  const labelRange =
    descriptor.id != "41a4cb29-4017-4239-93d8-af41a56136dc"
      ? labelRangeFromPathElement(lastElement)
      : null;

  return {
    id,
    originalLabel: true,
    label,
    labelRange,
    path: { nodeDescriptorID: descriptor.id, pathType, pathElements },
  };
}

/**
 * Figures out what chunk type corresponds to this position in the format or transform.
 */
export function determineChunkType(
  descriptor: NodeDescriptor,
  pathElements: PathElement[]
): ChunkType {
  if (pathElements.length == 0) {
    return "NONE";
  }

  // if this path goes to a range, we know it's a repetition
  if (isRange(lastPathElement(pathElements).action)) {
    return "REPETITION";
  }

  // find other paths that are longer than this path
  const successors = successorPaths(descriptor.allPaths, pathElements);

  // if none, it's an item
  if (successors.length == 0) {
    return "ITEM";
  }

  // otherwise examine an element that comes next
  if (
    successors.find((successor) => lastPathElement(successor).byNumber != null)
  ) {
    return "LIST_GROUP";
  }

  return "MAP_GROUP";
}

/**
 * Shifts the last path element index by the specified delta.  Non-numeric paths are not affected.
 */
export function modifyPathEndpoint(path: Path, delta: number): Path {
  // divide up the path
  const lastElementIndex = lastPathElementIndex(path);
  if (lastElementIndex < 0) {
    return path;
  }
  const firstElements = path.pathElements.slice(0, lastElementIndex);
  const lastElement = path.pathElements[lastElementIndex];

  // make a new set of elements
  const newElements = firstElements.concat(
    modifyPathElement(lastElement, delta)
  );

  return { ...path, pathElements: newElements };
}

/**
 * Shifts this path element index by the specified delta.  Non-numeric path elements are not affected.
 */
function modifyPathElement(
  pathElement: PathElement,
  delta: number
): PathElement {
  if (pathElement.byNumber) {
    return { ...pathElement, byNumber: pathElement.byNumber + delta };
  }
  return pathElement;
}

/**
 * Returns whether the sequence of path elements begins with the prefix subsequence.
 */
export function pathStartsWith(
  pathElements: PathElement[],
  prefix: PathElement[]
): boolean {
  if (pathElements.length < prefix.length) {
    return false;
  }
  const candidates = pathElements.slice(0, prefix.length);
  return arePathsEqual(candidates, prefix);
}

/**
 * Returns all paths that could be immediate successors of the provided path, based on the provided array.
 */
export function successorPaths(
  allPaths: Path[],
  pathElements: PathElement[]
): PathElement[][] {
  if (pathElements.length == 0) {
    return allPaths
      .filter((path) => path.pathElements.length == 1)
      .map((path) => path.pathElements);
  }

  const somePaths = new Array<PathElement[]>();

  // find all paths that could be successor paths
  allPaths.forEach((candidatePath) => {
    if (
      pathStartsWith(candidatePath.pathElements, pathElements) &&
      candidatePath.pathElements.length > pathElements.length
    ) {
      candidatePath.pathElements;
      somePaths.push(candidatePath.pathElements);
    }
  });

  // make the collection unique
  return somePaths.filter(
    (list_i, i) =>
      somePaths.find((list_j, j) => i != j && deepEquals(list_i, list_j)) ==
      undefined
  );
}

/**
 * Returns a subset of the path elements.
 */
export function pathSubset(
  pathElements: PathElement[],
  length: number
): PathElement[] {
  return pathElements.slice(0, length);
}

/**
 * Returns the path immediately preceding this one.
 */
export function pathParent(pathElements: PathElement[]): PathElement[] {
  if (pathElements.length > 0) {
    const lastIndex = lastPathElementIndex(pathElements);
    if (lastIndex > 0) {
      return pathElements.slice(0, lastIndex);
    }
  }
  return [];
}

/**
 * Returns whether two paths are equal.
 */
export function arePathsEqual(
  pathElements1: PathElement[],
  pathElements2: PathElement[]
): boolean {
  if (pathElements1.length != pathElements2.length) {
    return false;
  }
  return deepEquals(pathElements1, pathElements2);
}
