/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import React from "react";
import { useStyletron } from "baseui";
import { Table } from "baseui/table-semantic";
import { compareCaseInsensitive } from "../Utils/Sort";

const columns = ["Name", "Value"];

const CustomPropsTable = ({
  customProps,
}: {
  customProps: Record<string, any>;
}): JSX.Element => {
  const [css] = useStyletron();

  const rows: any[] = React.useMemo(() => {
    const sortedProps = [...Object.keys(customProps)].sort(
      compareCaseInsensitive
    );
    return sortedProps.map((key) => {
      const value = customProps[key];
      return [key, value];
    });
  }, [customProps]);

  return (
    <div className={css({ height: "1600px", width: "60%" })}>
      <Table columns={columns} data={rows} />
    </div>
  );
};

export default CustomPropsTable;
