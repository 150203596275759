/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { ApolloError, gql, useMutation } from "@apollo/client";
import * as React from "react";

import addModel from "../Api/Gql/AddModel";

import { notify } from "../Shared/Notify";
import { Button } from "baseui/button";
import { DetailsHeaderBar } from "../Shared/DetailsPages";
import { ModelDetailsState, ModelDetailsReducer } from "./ModelDetailsState";
import { ModelDetailsPage } from "./ModelDetailsPage";
import { createUUID } from "../Utils/Types";

export const ModelDetailsCreatePage = (): JSX.Element => {
  const [state, dispatch] = React.useReducer(
    ModelDetailsReducer,
    new ModelDetailsState(createUUID())
  );

  const [addModelMutation] = useMutation(gql(addModel), {
    variables: { model: state.edited.model },
    onCompleted: () => {
      notify.positive("Model saved");
    },
    onError: (error: ApolloError) => {
      notify.negative(`Error uploading model; ${error.message}`);
    },
  });

  const handleAddModel = React.useCallback((): void => {
    addModelMutation();
    history.back();
  }, [addModelMutation]);

  const ModelDetailsCreateButtons = React.useMemo((): JSX.Element => {
    return (
      <DetailsHeaderBar title={state.metadataValues.title}>
        <Button kind={"tertiary"} onClick={() => history.back()}>
          {"Cancel"}
        </Button>
        <Button
          kind={"primary"}
          disabled={!state.modelChanged}
          onClick={() => handleAddModel()}
        >
          {"Save"}
        </Button>
      </DetailsHeaderBar>
    );
  }, [handleAddModel, state.metadataValues.title, state.modelChanged]);

  return (
    <ModelDetailsPage
      state={state}
      dispatch={dispatch}
      headerActionButtons={ModelDetailsCreateButtons}
    />
  );
};
