/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import {
  PathTreeNode,
  treeFromEntries,
  WorkbenchTreeNodeData,
} from "./EWIRDBNodeUtil";

export class EWIRDBNodeState {
  nodeData: WorkbenchTreeNodeData = undefined;
  treeData: PathTreeNode[] = [];
  nodeIdsToNodes: Map<string, PathTreeNode> = new Map<string, PathTreeNode>();
  nodeIdsToParentIds: Map<string, string> = new Map<string, string>();
}

type NavigateAction = ["navigate", { nodeData: WorkbenchTreeNodeData }];
type UpdateTreeDataAction = ["updateTreeData", { treeData: PathTreeNode[] }];

type EWIRDBNodeAction = NavigateAction | UpdateTreeDataAction;

export function EWIRDBNodeReducer(
  state: EWIRDBNodeState,
  action: EWIRDBNodeAction
): EWIRDBNodeState {
  switch (action[0]) {
    case "navigate": {
      const [{}, { nodeData }] = action;
      return buildNewState(nodeData);
    }
    // this is for updating the tree data in such a way that it is still consistent with the node data it came from
    case "updateTreeData": {
      const [{}, { treeData }] = action;
      return { ...state, treeData };
    }
  }
}

function processTree(
  treeRoot: PathTreeNode,
  processNode: (treeNode: PathTreeNode) => boolean
): void {
  const processChildren = processNode(treeRoot);
  if (processChildren) {
    treeRoot.children?.forEach((child) => {
      processTree(child, processNode);
    });
  }
}

function buildNewState(nodeData: WorkbenchTreeNodeData): EWIRDBNodeState {
  const treeRoot = treeFromEntries(nodeData);
  // don't include the "root" node
  const treeData = treeRoot.children;

  // any time we get new node data, we'll need to refresh the references and parents
  const nodeIdsToNodes = new Map<string, PathTreeNode>();
  const nodeIdsToParentIds = new Map<string, string>();
  const refreshReferences = (treeNode: PathTreeNode): boolean => {
    if (treeNode.id) {
      const treeNodeId = treeNode.id.toString();
      nodeIdsToNodes.set(treeNodeId, treeNode);
      treeNode.children?.forEach((child) => {
        if (child.id) {
          nodeIdsToParentIds.set(child.id.toString(), treeNodeId);
        }
      });
    }
    return true;
  };

  // refresh the tree
  treeData?.forEach((node) => processTree(node, refreshReferences));

  // return our new state
  return {
    nodeData,
    treeData,
    nodeIdsToNodes,
    nodeIdsToParentIds,
  };
}

// return a flat view of all tree nodes that are visible
export function getAllVisibleTreeNodes(
  treeData: PathTreeNode[]
): PathTreeNode[] {
  const accumulator = new Array<PathTreeNode>();
  const accumulate = (treeNode: PathTreeNode): boolean => {
    accumulator.push(treeNode);
    // don't iterate into collapsed subtrees
    return treeNode.isExpanded;
  };
  treeData.forEach((item) => processTree(item, accumulate));
  return accumulator;
}
