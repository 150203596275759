/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { AppInitializationResponse, AppInitializationStatus } from "Api";
import axios from "axios";
import { Button } from "baseui/button";
import { HeadingXXLarge, LabelLarge } from "baseui/typography";
import * as React from "react";
import { CenteredContent } from "./DesignSystem/Containers";
import { notifyGeneralError } from "./Shared/Errors";
import { notify } from "./Shared/Notify";
import { LOGO_URL } from "./Utils/SiteLogo.mjs";
import { APP_TITLE_TITLECASE } from "./Utils/SiteProps";

export const isAppInitialized = async (): Promise<boolean> => {
  try {
    const getResponse = await axios.get("/isAppInitialized");
    const isInitializedResponse: AppInitializationStatus = getResponse?.data;
    return isInitializedResponse.initialized;
  } catch (error) {
    notifyGeneralError(
      error,
      "An error occurred while verifying the app is initialized."
    );
  }
};

export const AppInitialization = ({
  onInitialization,
}: {
  onInitialization(isInitialized: boolean): void;
}): JSX.Element => {
  const initializeApp = async (): Promise<void> => {
    try {
      const postResponse = await axios.post("/initializeApp");
      const initializeAppResponse: AppInitializationResponse =
        postResponse?.data;
      if (initializeAppResponse.status.initialized) {
        onInitialization(true);
        notify.positive(initializeAppResponse.message);
      } else {
        notify.negative(initializeAppResponse.message);
      }
    } catch (error) {
      notifyGeneralError(
        error,
        "An error occurred while initializing the application."
      );
    }
  };

  return (
    <CenteredContent>
      <img src={LOGO_URL.toString()} height="200rem"></img>
      <HeadingXXLarge>{`Welcome to ${APP_TITLE_TITLECASE}!`}</HeadingXXLarge>
      <LabelLarge>
        {`To get started please initialize the ${APP_TITLE_TITLECASE} application.`}
      </LabelLarge>
      <Button onClick={() => initializeApp()}>Initialize Application</Button>
    </CenteredContent>
  );
};
