/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { TreeNodeData, TreeView } from "baseui/tree-view";
import React, {
  createRef,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";
import {
  EWIRDBNodeReducer,
  EWIRDBNodeState,
  getAllVisibleTreeNodes,
} from "./EWIRDBNodeState";
import {
  alignHandle,
  createHandleComponentsForHandle,
  HandleRefTuple,
  WorkbenchNodeComponent,
  WorkbenchNodeProps,
} from "./WorkbenchNode";
import { COMMON_TEXT_STYLING } from "../Utils/SiteProps";
import { ProjectContext } from "../UserApp";
import { PathTreeNode, WorkbenchTreeNodeData } from "./EWIRDBNodeUtil";

export function EWIRDBNodeComponent(props: WorkbenchNodeProps): JSX.Element {
  const { projectDispatch } = useContext(ProjectContext);
  const nodeData = props.data;
  const treeRef = createRef<HTMLDivElement>();
  const handleRefs = useRef(new Map<number, HandleRefTuple>());

  const [state, dispatch] = useReducer(
    EWIRDBNodeReducer,
    null,
    () => new EWIRDBNodeState()
  );

  // this isn't currently used, but we'll keep it in case we need it in the future
  const _updateTreeData = useCallback((treeData: PathTreeNode[]) => {
    dispatch(["updateTreeData", { treeData }]);
  }, []);

  const onToggle = useCallback(
    (node: TreeNodeData) => {
      // find this tree node in our workbench node state
      const expansionState =
        (nodeData as WorkbenchTreeNodeData).expansionState ?? new Map();
      const expanded = expansionState[node.id] ?? false;

      // toggle it
      expansionState[node.id] = !expanded;

      // modify it
      const modifiedNodeData = { ...nodeData, expansionState };
      projectDispatch(["modifyNodeData", { modifiedNodeData }]);
    },
    // projectDispatch is guaranteed to be stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nodeData]
  );

  // get only the visible nodes so that we don't create too many handles
  const visibleTreeNodes = useMemo(
    () => getAllVisibleTreeNodes(state.treeData),
    [state.treeData]
  );

  // notify the node any time the workbench data changes, including a reload
  useEffect(() => {
    dispatch(["navigate", { nodeData }]);
  }, [nodeData]);

  // similarly to WorkbenchNode, line the handles up with their widgets
  useLayoutEffect(() => {
    if (treeRef.current && handleRefs.current) {
      // first, map the tree node widgets to their IDs
      const widgetMap = new Map<string, HTMLElement>();

      // select all children of the tree that have a data-nodeid attribute
      const treeElementList = treeRef.current.querySelectorAll("[data-nodeid]");
      treeElementList.forEach((element) => {
        widgetMap.set(
          element.getAttribute("data-nodeid"),
          element as HTMLElement
        );
      });

      // next, align the handles
      visibleTreeNodes.forEach((treeNode) => {
        if (treeNode.info) {
          const widgetNodeId = treeNode.id.toString();
          let widget = widgetMap.get(widgetNodeId);

          if (widget) {
            // if this node is expanded, align based on its first child
            // (otherwise the bounding box covers not only the node but also the children)
            if (treeNode.isExpanded) {
              widget = widget.childNodes.item(0) as HTMLElement;
            }

            const refTuple = handleRefs.current.get(treeNode.info.id as number);
            alignHandle(widget, refTuple.inputHandle, refTuple.inputPosition);
            alignHandle(widget, refTuple.outputHandle, refTuple.outputPosition);
          }
        }
      });
    }
  }, [treeRef, handleRefs, visibleTreeNodes]);

  return WorkbenchNodeComponent({
    ...props,
    mainComponent: (
      <>
        {visibleTreeNodes
          // every node should now have a handle, but filter for sanity's sake
          .filter((treeNode) => treeNode.info)
          // create handles and assign handle refs so they can be moved into position
          .map((treeNode) => {
            const refTuple: HandleRefTuple = {
              inputHandle: null,
              inputPosition: null,
              outputHandle: null,
              outputPosition: null,
            };
            handleRefs.current.set(treeNode.info.id as number, refTuple);

            return createHandleComponentsForHandle(
              treeNode.info,
              treeNode.info.path.pathType,
              refTuple
            );
          })}
        <div ref={treeRef}>
          <TreeView
            data={state.treeData}
            onToggle={onToggle}
            overrides={{
              TreeLabel: {
                style: {
                  ...COMMON_TEXT_STYLING,
                },
              },
            }}
          />
        </div>
      </>
    ),
  });
}
