/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { Tag } from "baseui/tag";
import { Textarea } from "baseui/textarea";
import * as React from "react";
import { ModelKeywordTags } from "../MetadataEditor/ModelKeywordTags";

import {
  FieldSpec,
  MultiLineTextFieldSpec,
  PickListFieldSpec,
  SimpleTextFieldSpec,
  TagListFieldSpec,
} from "./FieldSpec";
import { PickList } from "./PickList";

// Each editor type should not make any assumptions about the value being a specific type.
// The editors are responsible for making a best-effort coercion of the value to
// something they can edit. For example, a number might be coerced to a string to edit
// in a text editor. If no such coercion is possible, editors should just discard the value
// but not notify of a change.
//
// This approach opens up the possibility to make the schema more malleable in the future
// without users potentially destroying their data.

// TODO: Lots of duplication here with the wrapping FormControl; this can be pulled out
//  to the MetadataPropsEditor after they've all been converted.

export const SimpleTextEditor = ({
  field,
  value,
  disabled,
  onChange,
}: {
  field: SimpleTextFieldSpec;
  value: any;
  disabled?: boolean;
  onChange?: (newValue: string) => void;
}): JSX.Element => {
  const valueAsText = React.useMemo(() => {
    return normalizeTextControlValue(value);
  }, [value]);

  function handleTextChange(event: React.FormEvent<HTMLInputElement>): void {
    const newText = normalizeTextPropertyValue(event.currentTarget.value);
    onChange(newText);
  }

  return (
    <FormControl label={() => field.title}>
      <Input
        value={valueAsText}
        onChange={handleTextChange}
        disabled={disabled}
      />
    </FormControl>
  );
};

export const MultiLineTextEditor = ({
  field,
  value,
  disabled,
  onChange,
}: {
  field: MultiLineTextFieldSpec;
  value: any;
  disabled?: boolean;
  onChange?: (newValue: string) => void;
}): JSX.Element => {
  const valueAsText = React.useMemo(() => {
    return normalizeTextControlValue(value);
  }, [value]);

  function handleTextChange(event: React.FormEvent<HTMLTextAreaElement>): void {
    const newText = normalizeTextPropertyValue(event.currentTarget.value);
    onChange(newText);
  }

  return (
    <FormControl label={() => field.title}>
      <Textarea
        value={valueAsText}
        onChange={handleTextChange}
        disabled={disabled}
      />
    </FormControl>
  );
};

export const TagListEditor = ({
  field,
  value,
  disabled,
  onChange,
}: {
  field: TagListFieldSpec;
  value: any;
  disabled?: boolean;
  onChange?: (newValue: any) => void;
}): JSX.Element => {
  return (
    <FormControl label={() => field.title}>
      {disabled ? (
        <>
          {value?.map((keyword, index) => (
            <Tag key={index} closeable={false}>
              {keyword}
            </Tag>
          ))}
        </>
      ) : (
        <ModelKeywordTags
          dataTestidBase={`model-${field.propertyName}`}
          keywords={value === null ? [] : value}
          onChange={onChange}
        />
      )}
    </FormControl>
  );
};

export const PickListEditor = ({
  field,
  value,
  disabled,
  onChange,
}: {
  field: PickListFieldSpec;
  value: any;
  disabled?: boolean;
  onChange?: (newValue: any) => void;
}): JSX.Element => {
  const values = React.useMemo(() => {
    if (Array.isArray(value)) {
      return value.map((valueEntry) => {
        return {
          id: valueEntry,
        };
      });
    } else {
      return [];
    }
  }, [value]);

  const handlePickListChange = React.useCallback(
    (values) => {
      const propertyValues = values?.map((value) => value.id);
      onChange(propertyValues);
    },
    [onChange]
  );

  return (
    <FormControl label={() => field.title}>
      <PickList
        pickListData={field.schema}
        value={values}
        onChange={handlePickListChange}
        disabled={disabled}
        multi
      />
    </FormControl>
  );
};

export const FieldEditor = ({
  field,
  value,
  disabled,
  onChange,
}: {
  field: FieldSpec;
  value: any;
  disabled?: boolean;
  onChange?: (newValue: any) => void;
}): JSX.Element => {
  switch (field.type) {
    case "SimpleTextField":
      return (
        <SimpleTextEditor
          field={field}
          value={value}
          disabled={disabled}
          onChange={onChange}
        />
      );
    case "MultiLineTextField":
      return (
        <MultiLineTextEditor
          field={field}
          value={value}
          disabled={disabled}
          onChange={onChange}
        />
      );
    case "TagListField":
      return (
        <TagListEditor
          field={field}
          value={value}
          disabled={disabled}
          onChange={onChange}
        />
      );
    case "PickListField":
      return (
        <PickListEditor
          field={field}
          value={value}
          disabled={disabled}
          onChange={onChange}
        />
      );
  }
};

// Text controls don't deal well with null values. React will complain about
// having a mix of controlled and uncontrolled components. We get around this in
// the controls themselves by normalizing null to an empty string.
function normalizeTextControlValue(text: string): string {
  return text ? `${text}` : "";
}

// Since text controls are rendering null as empty string, we need to
// be able to reverse that. We fold empty string into null to make sure
// the checks to see if an object is changed is as simple as possible.
// From a user's perspective, there is no difference between null and
// empty string anyway.
function normalizeTextPropertyValue(text: string): string {
  return text === "" ? null : text;
}
