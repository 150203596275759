/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { useLocation } from "react-router-dom";
import { Button } from "baseui/button";
import { HeadingMedium, LabelXSmall } from "baseui/typography";
import { gql, useMutation, useQuery } from "@apollo/client";
import { SiteContext } from "../Utils/SiteProps";
import GetFiles from "../Api/Gql/GetFiles";
import {
  FileInfo,
  FileInfoInput,
  GeneralResponse,
  MetadataScan,
  ModelMetadataSchema,
  ProtectedUrl,
  SecurityMarkings,
} from "Api";
import { FormControl } from "baseui/form-control";
import { fileSizeAsText, fileUploadDateAsText } from "../Api/ApiSerialization";
import { SecurityMarkingsEditor } from "../Shared/SecurityMarkingsEditor";
import { MetadataScanEditor } from "../MetadataEditor/MetadataScanEditor";

import updateFileInfo from "../Api/Gql/UpdateFile";
import getUserDefinedSchema from "../Api/Gql/GetUserDefinedSchema";

import { deepEquals } from "../Utils/Comparison";
import { shallowCopy } from "../Utils/Array";
import { AxiosContext } from "../Utils/AuthContext";
import { FileTopics } from "./ModeledTopics";
import { handleApolloError, notifyGeneralError } from "../Shared/Errors";
import { BackToSearchLink } from "../Shared/BackToSearchLink";
import { allMetadataFields } from "../Model/ModelMetadataFields";
import { FieldSpec } from "../Shared/FieldSpec";
import { DetailsHeaderBar, DetailsPage } from "../Shared/DetailsPages";
import { LoadingPlaceholder } from "../Shared/LoadingPlaceholder";
import { parseUUID, UUID } from "../Utils/Types";
import { notify } from "../Shared/Notify";

interface FileDetailsQueryParams {
  fileId: UUID;
}

function useFileDetailsParams(): FileDetailsQueryParams {
  const searchParams = new URLSearchParams(useLocation().search);
  const fileDetailsParams: FileDetailsQueryParams = {
    fileId: parseUUID(searchParams.get("fileId")),
  };
  return fileDetailsParams;
}

export const FileDetailsPage = (): JSX.Element => {
  const fileDetailsParams = useFileDetailsParams();

  const { loading, error, data, refetch } = useQuery(gql(GetFiles), {
    variables: { ids: [fileDetailsParams.fileId] },
  });

  if (!fileDetailsParams.fileId) {
    return <InvalidFileDetailsQueryParameters />;
  }

  if (loading) {
    return <LoadingPlaceholder message="Loading file details" />;
  }

  if (error) {
    return (
      <LabelXSmall>{`Could not retrieve file details; ${error.message}`}</LabelXSmall>
    );
  }

  const fileInfos = data.getFiles as FileInfo[];
  const fileInfo = fileInfos[0];
  if (!fileInfo) {
    return (
      <LabelXSmall>{`No file found for id ${fileDetailsParams.fileId}`}</LabelXSmall>
    );
  }

  return <FileEditor fileInfo={fileInfo} onSave={refetch} />;
};

const InvalidFileDetailsQueryParameters = (): JSX.Element => {
  return (
    <LabelXSmall>
      {
        "File details query parameter format is invalid; please specify fileId as a UUID."
      }
    </LabelXSmall>
  );
};

const defaultMarkings = (): SecurityMarkings => {
  const newDefault: SecurityMarkings = {
    classificationLevel: null,
    compartments: null,
  };
  return newDefault;
};

export const FileEditor = ({
  fileInfo,
  onSave,
}: {
  fileInfo: FileInfo;
  onSave: () => void;
}): JSX.Element => {
  // Baseline markings help make sure we can safely use the equals method,
  // even when security markings are null.
  const baselineMarkings = React.useMemo(() => {
    return fileInfo?.securityMarkings ?? defaultMarkings();
  }, [fileInfo?.securityMarkings]);

  const [securityMarkings, setSecurityMarkings] =
    React.useState<SecurityMarkings>(baselineMarkings);

  const securityMarkingsChanged = React.useMemo(() => {
    return !deepEquals(baselineMarkings, securityMarkings);
  }, [baselineMarkings, securityMarkings]);

  const reversedMetadataScans = React.useMemo<MetadataScan[]>(
    () => shallowCopy(fileInfo?.metadataScans)?.reverse() ?? [],
    [fileInfo.metadataScans]
  );

  const { data } = useQuery(gql(getUserDefinedSchema));
  const userDefinedModelSchema =
    data?.getUserDefinedSchema as ModelMetadataSchema;

  const allDefinedFields = React.useMemo(() => {
    return allMetadataFields(userDefinedModelSchema);
  }, [userDefinedModelSchema]);

  const [updateFileMutation] = useMutation(gql(updateFileInfo), {
    onCompleted: (_) => {
      onSave();
    },
    onError: (error) => {
      handleApolloError(
        error,
        "An error occured while saving file information."
      );
    },
  });
  const siteProps = React.useContext(SiteContext);
  const axiosContext = React.useContext(AxiosContext);

  const handleDownload = React.useCallback(async () => {
    try {
      const downloadFileTokenResponse = await axiosContext.get(
        `/downloadFileToken?fileId=${fileInfo.id}`
      );
      const data: GeneralResponse = downloadFileTokenResponse.data;

      if (data.status == 0 || data.status == 200) {
        // everything ok, so download the file
        const protectedUrl: ProtectedUrl = data.payload;
        window.location.href = `${siteProps.topUrl}${protectedUrl.urlFragment}`;
      } else {
        // show negative toast
        notify.negative(
          "Unable to download file" +
            (data.message.length > 0 ? ": " + data.message : "")
        );
      }
    } catch (error) {
      notifyGeneralError(
        error,
        `Could not get secure download token for file ${fileInfo.id}`
      );
    }
  }, [axiosContext, fileInfo.id, siteProps.topUrl]);

  const handleSave = React.useCallback(() => {
    const newFileInfo: FileInfoInput = {
      id: fileInfo.id,
      securityMarkings: securityMarkings,
    };
    updateFileMutation({
      variables: { fileInfo: newFileInfo },
    });
  }, [fileInfo.id, securityMarkings, updateFileMutation]);

  return (
    <DetailsPage>
      <BackToSearchLink />
      <DetailsHeaderBar title={fileInfo?.attributes?.name}>
        <Button kind={"tertiary"} onClick={handleDownload}>
          Download
        </Button>
        <Button
          kind={"primary"}
          disabled={!securityMarkingsChanged}
          onClick={handleSave}
        >
          Save
        </Button>
      </DetailsHeaderBar>
      <SecurityMarkingsEditor
        securityMarkings={securityMarkings}
        onMarkingsChanged={setSecurityMarkings}
      />
      <FormControl label={() => "Uploaded on"}>
        <LabelXSmall>{`${fileUploadDateAsText(fileInfo)}`}</LabelXSmall>
      </FormControl>
      <FormControl label={() => "File Size"}>
        <LabelXSmall>{`${fileSizeAsText(fileInfo)}`}</LabelXSmall>
      </FormControl>
      {fileInfo?.attributes?.name?.includes(".pdf") && (
        <FileTopics fileId={fileInfo.id} />
      )}
      {reversedMetadataScans.map((metadataScan, index) => (
        <FileMetadataScan
          key={metadataScan.id}
          allSchemaFields={allDefinedFields}
          metadataScan={metadataScan}
          title={`Scan ${reversedMetadataScans.length - index}`}
        />
      ))}
    </DetailsPage>
  );
};

const FileMetadataScan = ({
  allSchemaFields,
  metadataScan,
  title,
}: {
  allSchemaFields: FieldSpec[];
  metadataScan: MetadataScan;
  title: string;
}): JSX.Element => {
  return (
    <>
      <HeadingMedium>{title}</HeadingMedium>
      <div style={{ marginLeft: "1rem" }}>
        <MetadataScanEditor
          modelFields={allSchemaFields}
          metadataScan={metadataScan}
          defaultViewTab={1}
        />
      </div>
    </>
  );
};
