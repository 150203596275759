/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
// Kobayashi Maru "Light" theme from the Air Force, v1.4.
//
// https://software.af.mil/softwarefactory/kobayashi-maru/
import { KmTheme } from "./KmTheme";

const defaultTextColor = "#292a2d";
const secondaryTextColor = "#51555b";
const primaryBlue = "#005a8f";
const primaryLight = "#2f7aa7";
const primaryDark = "#004872";

export const KmLightTheme: KmTheme = {
  colors: {
    backgroundColor: "#eaeef4",
    surfaceElementColor: "#ffffff",
    defaultTextColor: defaultTextColor,
    secondaryTextColor: secondaryTextColor,
    primaryBlue: primaryBlue,
    primaryLight: primaryLight,
    primaryDark: primaryDark,
    primaryElementFontColor: "#ffffff",
    linkColor: "#005a8f",
    selectedTabColor: primaryBlue,
    helpTextColor: secondaryTextColor,
    errorTextColor: "#cc0000",
  },
  fileUpload: {
    dropAreaLineColorHover: defaultTextColor,
    dropAreaLineColor: secondaryTextColor,
  },
  button: {
    primaryHover: primaryDark,
  },
};
