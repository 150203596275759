/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { ClassificationLevel } from "../Api/Api";
import { classificationAsText } from "../Api/ApiSerialization";
import { Option } from "baseui/select";

const getUserClassificationOptions = (
  clearanceLevel: ClassificationLevel
): ClassificationLevel[] => {
  const options = [];
  options.push(ClassificationLevel.UNCLASSIFIED);
  if (clearanceLevel == ClassificationLevel.UNCLASSIFIED) return options;
  options.push(ClassificationLevel.CONFIDENTIAL);
  if (clearanceLevel == ClassificationLevel.CONFIDENTIAL) return options;
  options.push(ClassificationLevel.SECRET);
  if (clearanceLevel == ClassificationLevel.SECRET) return options;
  options.push(ClassificationLevel.TOP_SECRET);
  return options;
};

export interface ClassificationComboOption extends Option {
  classificationLevel: ClassificationLevel;
}

export const getClassificationComboOptions = (
  isSensitiveSecurityContext: boolean,
  userClearanceLevel: ClassificationLevel
): ClassificationComboOption[] => {
  const userClassificationOptions =
    getUserClassificationOptions(userClearanceLevel);
  return userClassificationOptions.map((classLevel) => {
    return {
      id: classLevel,
      classificationLevel: classLevel,
      label: classificationAsText(classLevel, isSensitiveSecurityContext),
    };
  });
};
