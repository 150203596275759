/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import SearchInput from "../Shared/SearchInput";
import SearchResultTable from "./FileSearchResultTable";
import { gql, useLazyQuery } from "@apollo/client";
import { handleApolloError } from "../Shared/Errors";

export function FileSearchPage(): JSX.Element {
  const SEARCH_FILES_QUERY = gql(
    `query SearchFiles($nameSubstring: String!) {
      searchFiles (nameSubstring: $nameSubstring) {
        id,
        securityMarkings {
          classificationLevel
        }
        attributes {
          name,
          uploadDateUtc,
          sizeMbs
        }
      }
    }`
  );

  const [searchFiles, { loading, data }] = useLazyQuery(SEARCH_FILES_QUERY, {
    fetchPolicy: "network-only",
    onError: (error) => handleApolloError(error, "Error fetching files!"),
  });

  function handleSearch(searchValue: string): void {
    searchFiles({ variables: { nameSubstring: searchValue } });
  }

  return (
    <>
      <SearchInput placeholder="Search files" onSearch={handleSearch} />
      {loading ? (
        <div>Loading</div>
      ) : (
        <SearchResultTable files={data?.searchFiles || []} />
      )}
    </>
  );
}
