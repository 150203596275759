/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { Button, ButtonOverrides, KIND } from "baseui/button";
import { ChevronDown } from "baseui/icon";
import { StatefulPopover, PLACEMENT } from "baseui/popover";
import { StatefulMenu } from "baseui/menu";
import * as React from "react";
import { CommandBar } from "../DesignSystem/CommandBar";
import { RightAlignedRow } from "../DesignSystem/Containers";
import { BulkAddModal } from "./BulkAddModal";
import { OverflowActions } from "../Shared/OverflowMenu";
import { useHistory } from "react-router-dom";
import { modelDetailsCreatePagePath } from "../Shared/NavigationLinks";

const modelCommandButtonStyle: ButtonOverrides = {
  BaseButton: {
    style: {
      width: "7rem",
    },
  },
};

export const ModelSearchCommandBar = ({
  refetchQueryName,
}: {
  refetchQueryName: string;
}): JSX.Element => {
  return (
    <RightAlignedRow style={{ margin: "1rem" }}>
      <CommandBar>
        <AddDropdown />
        <BulkAddModelButton refetchQueryName={refetchQueryName} />
      </CommandBar>
    </RightAlignedRow>
  );
};

const AddDropdown = (): JSX.Element => {
  const history = useHistory();
  const itemSelectActions: OverflowActions[] = [
    {
      label: "Data Source",
      onClick: () => history.push(modelDetailsCreatePagePath()),
    },
  ];

  return (
    <>
      <StatefulPopover
        focusLock
        placement={PLACEMENT.bottomLeft}
        content={
          <StatefulMenu
            items={itemSelectActions}
            onItemSelect={(selectedItem) => {
              selectedItem.item.onClick();
            }}
            overrides={{
              List: { style: { height: "auto", width: "138px" } },
            }}
          />
        }
      >
        <Button
          data-testid={"add-resource-button"}
          kind={KIND.tertiary}
          endEnhancer={() => <ChevronDown size={24} />}
        >
          Add
        </Button>
      </StatefulPopover>
    </>
  );
};

// TODO: This button and the BulkAddModelButton could be commonized. Also the upload modal template file code from custom mdoel fields.

const BulkAddModelButton = ({
  refetchQueryName,
}: {
  refetchQueryName: string;
}): JSX.Element => {
  const [bulkAddModalIsOpen, setBulkAddModalIsOpen] = React.useState(false);
  return (
    <>
      <Button
        kind="tertiary"
        overrides={modelCommandButtonStyle}
        onClick={() => setBulkAddModalIsOpen(true)}
      >
        {"Bulk Add"}
      </Button>
      <BulkAddModal
        isOpen={bulkAddModalIsOpen}
        refetchQueryName={refetchQueryName}
        onClose={() => setBulkAddModalIsOpen(false)}
      />
    </>
  );
};
