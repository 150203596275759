/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import * as React from "react";

import {
  FileInfo,
  ModelMetadataSchema,
  NewFileInfo,
  SecurityMarkings,
} from "Api";

import { DetailsPage } from "../Shared/DetailsPages";
import { ModelDetailsState, ModelDetailsPageAction } from "./ModelDetailsState";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import GetFiles from "../Api/Gql/GetFiles";
import MetadataPropsEditor from "../MetadataEditor/MetadataPropsEditor";
import { MetadataScanEditor } from "../MetadataEditor/MetadataScanEditor";
import { SimpleTextEditor } from "../Shared/Editors";
import { FieldSpec } from "../Shared/FieldSpec";
import { ObservableFileUploader } from "../Shared/ObservableFileUploader";
import {
  OperationOutcome,
  failureOutcome,
  successOutcome,
} from "../Shared/OperationOutcome";
import { ClassificationLevelEditor } from "../Shared/SecurityMarkingsEditor";
import { isBlank } from "../Utils/Strings";
import { FieldAdder } from "./FieldAdder";
import { UploadStatus } from "./FileUpload";
import { TITLE_FIELD } from "./ModelMetadataFields";
import { ModelFileTree } from "./ModelFileTree";
import { LoadingPlaceholder } from "../Shared/LoadingPlaceholder";
import { VerticalSpacer } from "../DesignSystem/Containers";
import { handleApolloError } from "../Shared/Errors";
import getUserDefinedSchema from "../Api/Gql/GetUserDefinedSchema";

export const ModelDetailsPage = ({
  state,
  dispatch,
  headerActionButtons,
  modelLoading,
}: {
  state: ModelDetailsState;
  dispatch: React.Dispatch<ModelDetailsPageAction>;
  headerActionButtons?: React.ReactNode;
  modelLoading?: boolean;
}): JSX.Element => {
  const { data: schemaData, loading: schemaLoading } = useQuery(
    gql(getUserDefinedSchema),
    {
      onError: (error) => {
        handleApolloError(error, "Error getting model data!");
      },
    }
  );

  // Putting this dispatch call in a useEffect was necessary to prevent an error.
  // See: https://stackoverflow.com/questions/62336340/cannot-update-a-component-while-rendering-a-different-component-warning
  React.useEffect(() => {
    const schema = schemaData?.getUserDefinedSchema as ModelMetadataSchema;
    dispatch(["setModelSchema", schema]);
  }, [dispatch, schemaData]);

  const [getFileInfos] = useLazyQuery(gql(GetFiles), {
    fetchPolicy: "network-only",
    onCompleted: (fileData) => {
      const fileInfos = fileData?.getFiles as FileInfo[];
      dispatch(["updateSuccessfulUploads", fileInfos]);
    },
  });

  const handleUploadCompleted = React.useCallback(
    (successfulUploads: NewFileInfo[], failedUploads: NewFileInfo[]) => {
      if (successfulUploads) {
        const succesfulUploadIds = successfulUploads.map((value) => value.id);
        getFileInfos({
          variables: { ids: succesfulUploadIds },
        });
      }
      if (failedUploads) {
        dispatch(["updateFailedUploads", failedUploads]);
      }
    },
    [dispatch, getFileInfos]
  );

  const verifyCanAddField = React.useCallback(
    (newField: FieldSpec): OperationOutcome => {
      if (isBlank(newField.propertyName)) {
        return failureOutcome("Field name may not be blank or only whitespace");
      }

      const lowerCaseNewFieldName = newField.propertyName.toLowerCase();

      const existingFieldsWithName = state.fields.all
        .map((field) => field.propertyName.toLowerCase())
        .filter(
          (lowerCaseFieldName) => lowerCaseFieldName === lowerCaseNewFieldName
        );

      return existingFieldsWithName.length > 0
        ? failureOutcome(
            `There is already a field named '${newField.propertyName}'`
          )
        : successOutcome();
    },
    [state.fields.all]
  );

  const handleAddField = React.useCallback(
    (newField: FieldSpec, initialValue: any) => {
      const addOutcome = verifyCanAddField(newField);
      if (addOutcome.successful) {
        dispatch([
          "editProperty",
          { propertyName: newField.propertyName, value: initialValue },
        ]);
      }
      return addOutcome;
    },
    [verifyCanAddField, dispatch]
  );

  if (modelLoading || schemaLoading) {
    return <LoadingPlaceholder message="Loading model details" />;
  }

  return (
    <DetailsPage>
      {headerActionButtons}
      <FlexGrid
        flexGridColumnCount={2}
        flexGridColumnGap="scale800"
        flexGridRowGap="scale800"
        height="scale10000"
      >
        <FlexGridItem>
          <SimpleTextEditor
            field={TITLE_FIELD}
            value={state.metadataValues[TITLE_FIELD.propertyName]}
            onChange={(value) =>
              dispatch([
                "editProperty",
                { propertyName: TITLE_FIELD.propertyName, value },
              ])
            }
          />
        </FlexGridItem>
        <FlexGridItem {...classificationItemProps}>
          <ClassificationLevelEditor
            securityMarkings={state.edited.model.securityMarkings}
            onClassificationChanged={(newClassification) => {
              const newSecurityMarking: SecurityMarkings = {
                ...state.edited.model.securityMarkings,
                classificationLevel: newClassification,
              };
              dispatch(["editMarkings", newSecurityMarking]);
            }}
          />
        </FlexGridItem>
        <FlexGridItem {...fileItemProps}>
          <ObservableFileUploader
            onUploadStart={(cancelToken, filePathsToUpload) =>
              dispatch(["addUploads", { cancelToken, filePathsToUpload }])
            }
            onUploadComplete={handleUploadCompleted}
          />
          <VerticalSpacer height="2rem" />
          <ModelFileTree
            fileTreeRoot={state.edited.fileTreeRoot}
            dispatch={dispatch}
          />
        </FlexGridItem>
        <FlexGridItem>
          {state.selectedFileTreeNode?.type === "File" ? (
            <>
              {state.selectedFileTreeNode.file?.status ===
              UploadStatus.FAILED ? (
                "An unexpected error occured"
              ) : state.selectedFileTreeNode.file?.status ===
                UploadStatus.IN_PROGRESS ? (
                "Analyzing file..."
              ) : (
                <MetadataScanEditor
                  modelFields={state.fields.tab}
                  metadataScan={state.selectedFileTreeNode.file?.metadataScan}
                />
              )}
            </>
          ) : (
            <>
              <MetadataPropsEditor
                fields={state.fields.tab}
                metadataValues={state.metadataValues}
                onValueChange={(propertyName, value) =>
                  dispatch(["editProperty", { propertyName, value }])
                }
              />
              <FieldAdder
                canAddField={verifyCanAddField}
                onAddField={handleAddField}
              />
            </>
          )}
        </FlexGridItem>
      </FlexGrid>
    </DetailsPage>
  );
};

const classificationItemProps = {
  overrides: {
    Block: {
      style: () => ({
        width: "20%",
        flexGrow: 0,
      }),
    },
  },
};

const fileItemProps = {
  overrides: {
    Block: {
      style: () => ({
        width: "30%",
        flexGrow: 0,
      }),
    },
  },
};
