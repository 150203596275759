/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { WorkbenchNodeEntryProps } from "./WorkbenchNode";

import {
  DividedListNodeComponent,
  DividedListNodeProps,
} from "./DividedListNode";
import React, { useCallback, useContext, useState } from "react";
import { OverflowActions, OverflowMenu } from "../Shared/OverflowMenu";
import {
  canAddEntries,
  entryHasEdge,
  isEntryRemovable,
  WorkbenchNodeEntry,
  WorkbenchNodeEntryId,
} from "./WorkbenchData";
import { ProjectContext } from "../UserApp";
import { COMMON_TEXT_STYLING } from "../Utils/SiteProps";
import { DeleteConfirmationModal } from "../Shared/DeleteConfirmationModal";
import { PathType, Path } from "../Api/DataTransformation";
import { useUpdateNodeInternals } from "reactflow";
import { Button } from "baseui/button";
import { FaPlus } from "react-icons/fa";
import { displayableLabel } from "./NodeDescriptorUtil";

export function ModifiableDividedListNodeComponent<
  P extends ModifiableDividedListNodeEntryProps = ModifiableDividedListNodeEntryProps
>(props: DividedListNodeProps<P>): JSX.Element {
  const { projectState, projectDispatch } = useContext(ProjectContext);
  const { rfEdges } = projectState;
  const nodeId = props.data.dataID;
  const nodeData = props.data;

  const updateNodeInternals = useUpdateNodeInternals();

  const [deleteEntryConfirmationNeeded, setDeleteEntryConfirmationNeeded] =
    useState<WorkbenchNodeEntryId>(undefined);

  // these get dispatched to the project
  const addEntry = useCallback(
    (entryId: WorkbenchNodeEntryId, delta: number) => {
      projectDispatch(["addSpecificEntry", { nodeId, entryId, delta }]);
      updateNodeInternals(nodeId);
    },
    // projectDispatch is guaranteed to be stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nodeId]
  );
  const addBrandNewEntry = useCallback(
    (path: Path) => {
      projectDispatch(["addBrandNewEntry", { nodeId, path }]);
      updateNodeInternals(nodeId);
    },
    // projectDispatch is guaranteed to be stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nodeId]
  );
  const removeEntry = useCallback(
    (entryId: WorkbenchNodeEntryId) => {
      projectDispatch(["removeSpecificEntry", { nodeId, entryId }]);
      updateNodeInternals(nodeId);
    },
    // projectDispatch is guaranteed to be stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nodeId]
  );
  const entryRemovable = useCallback(
    (entryId: WorkbenchNodeEntryId): boolean => {
      return isEntryRemovable(nodeData, entryId);
    },
    [nodeData]
  );

  const localEntryHasEdge = useCallback(
    (entryId: WorkbenchNodeEntryId) => {
      return entryHasEdge(rfEdges, nodeData, entryId);
    },
    [rfEdges, nodeData]
  );
  const confirmAndRemoveEntry = useCallback((entryId: WorkbenchNodeEntryId) => {
    setDeleteEntryConfirmationNeeded(entryId);
  }, []);

  const getEntryProps = useCallback(
    (entry: WorkbenchNodeEntry) => {
      const props: ModifiableDividedListNodeEntryProps = {
        entry,
        addEntry,
        entryHasEdge: localEntryHasEdge,
        removeEntry,
        confirmAndRemoveEntry,
        entryRemovable,
      };
      return props;
    },
    [
      addEntry,
      localEntryHasEdge,
      confirmAndRemoveEntry,
      removeEntry,
      entryRemovable,
    ]
  );

  const filteredEntryTypes = nodeData.entries.filter(
    (path) =>
      path.path.pathType == PathType.INPUT ||
      path.path.pathType == PathType.BOTH
  );

  return (
    <>
      <DeleteConfirmationModal
        isOpen={deleteEntryConfirmationNeeded !== undefined}
        header={"Delete Entry"}
        bodyText={`Are you sure you want to delete this entry? It currently has an edge attached to it.`}
        onDelete={() => {
          removeEntry(deleteEntryConfirmationNeeded);
          setDeleteEntryConfirmationNeeded(undefined);
        }}
        onCancel={() => setDeleteEntryConfirmationNeeded(undefined)}
      />
      {DividedListNodeComponent({
        ...props,
        entryComponent: ModifiableDividedListNodeEntryComponent,
        getEntryProps,
        footer: (
          <div style={{ marginBottom: "5px" }}>
            {filteredEntryTypes.length == 0 && (
              <Button
                size="mini"
                onClick={() =>
                  addBrandNewEntry(
                    props.data.descriptor.allPaths.find(
                      (path) => path.pathType == PathType.INPUT
                    )
                  )
                }
              >
                Add Input <FaPlus />
              </Button>
            )}
          </div>
        ),
      })}
    </>
  );
}

export interface ModifiableDividedListNodeEntryProps
  extends WorkbenchNodeEntryProps {
  entry: WorkbenchNodeEntry;
  addEntry: (entryId: WorkbenchNodeEntryId, delta: number) => void;
  entryHasEdge: (entryId: WorkbenchNodeEntryId) => boolean;
  confirmAndRemoveEntry: (entryId: WorkbenchNodeEntryId) => void;
  removeEntry: (
    entryId: WorkbenchNodeEntryId,
    removeEvenIfHasEdge: boolean
  ) => void;
  entryRemovable: (entryId: WorkbenchNodeEntryId) => boolean;
}

function ModifiableDividedListNodeEntryComponent(
  props: ModifiableDividedListNodeEntryProps
): JSX.Element {
  const entry = props.entry;

  const overflowActions: OverflowActions[] = [
    { label: "Add Above", onClick: () => props.addEntry(entry.id, 0) },
    { label: "Add Below", onClick: () => props.addEntry(entry.id, 1) },
    {
      label: "Delete",
      onClick: () => {
        if (props.entryHasEdge(entry.id)) {
          props.confirmAndRemoveEntry(entry.id);
        } else {
          props.removeEntry(entry.id, false);
        }
      },
      disabled: !props.entryRemovable(entry.id),
    },
  ];

  // the dropdown should always be on the inside of the widget
  const float = entry.path.pathType == PathType.OUTPUT ? "left" : "right";

  return (
    <span style={{ ...COMMON_TEXT_STYLING, justifyItems: "space-between" }}>
      <>{displayableLabel("", entry.label, entry.labelRange)}</>
      {canAddEntries(entry.path, 1) && (
        <OverflowMenu overflowActions={overflowActions} float={float} />
      )}
    </span>
  );
}
