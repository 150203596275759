/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { AppNavBar, NavItemT, setItemActive } from "baseui/app-nav-bar";
import { Link, useHistory, useLocation } from "react-router-dom";
import { PLACEMENT, StatefulTooltip } from "baseui/tooltip";
import { LabelSmall, LabelXSmall } from "baseui/typography";
import { DeleteAlt, Overflow } from "baseui/icon";

import { KmTopAppBar } from "../DesignSystem/KobayashiMaru/KmTopAppBar";
import { gql, useQuery, useApolloClient } from "@apollo/client";
import { SimorAuthContext } from "../Utils/AuthContext";
import appInfo from "../Api/Gql/AppInfo";
import { formattedDateTime } from "../Api/ApiSerialization";
import { AppInfo, MigrationExecutionState } from "Api";
import { APP_TITLE_UPPERCASE } from "../Utils/SiteProps";
import { LOGO_URL } from "../Utils/SiteLogo.mjs";
import { ProjectInfo } from "../UserApp";

const homeLinkStyle: React.CSSProperties = {
  textDecoration: "none",
  color: KmTopAppBar.defaultTextColor,
  fontSize: "1.5rem",
  fontFamily: "Roboto",
};

export interface HeaderBarProps {
  projectInfo: ProjectInfo;
}

export function HeaderBar(props: HeaderBarProps): JSX.Element {
  const simorAuth = React.useContext(SimorAuthContext);
  const user = simorAuth.user;
  const history = useHistory();
  const location = useLocation();
  const client = useApolloClient();

  const { projectDispatch } = props.projectInfo;

  const currentPathIncludesPathOfNavItem = React.useCallback(
    (navItem: NavItemT): boolean => {
      const currentLocationParentPath = location.pathname.split("/")[1];
      const url = (navItem.info?.url as string) ?? "";
      const navItemParentPath = url.split("/")[1];
      return navItemParentPath === currentLocationParentPath;
    },
    [location]
  );

  const transformEngineNavItem = {
    icon: null,
    label: "Transform Engine",
    info: { url: "/" },
  };

  const repositoryNavItem = {
    icon: null,
    label: "Repository",
    info: { url: "/repository" },
  };

  const devToolsNavItem = {
    icon: null,
    label: "Dev Tools",
    info: { url: "/devtools" },
  };

  const initialItems: NavItemT[] = [
    transformEngineNavItem,
    repositoryNavItem,
    devToolsNavItem,
  ];
  const [mainItems, setMainItems] = React.useState<NavItemT[]>(initialItems);

  const handleMainItemSelect = React.useCallback(
    (item: NavItemT) => {
      if (item.info?.url) {
        if (item == transformEngineNavItem) {
          projectDispatch(["reloadDataSources", {}, projectDispatch]);
        }
        setMainItems((prev) => setItemActive(prev, item));
        sessionStorage.clear();
        client.cache.reset();
        history.push(item.info.url);
      }
    },
    // no need to add either projectDispatch or transformEngineNavItem here
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client.cache, history, setMainItems]
  );

  const userItems = [
    {
      icon: Overflow,
      label: "User Preferences",
      info: { url: "/userPreferences" },
    },
    {
      icon: Overflow,
      label: "Transform Fields",
      info: { url: "/transformFields" },
    },
    { icon: DeleteAlt, label: "Logout", info: { url: "/" } },
  ];

  const handleUserItemSelect = React.useCallback(
    (item: NavItemT) => {
      if (item.label === "Logout") {
        sessionStorage.clear();
        client.cache.reset();
        simorAuth.logoutFromServer();
        history.push(item.info.url);
      } else {
        history.push(item.info.url);
      }
    },
    [simorAuth, client, history]
  );

  return (
    <>
      <AppNavBar
        title={APP_TITLE_UPPERCASE}
        mainItems={mainItems}
        isMainItemActive={currentPathIncludesPathOfNavItem}
        onMainItemSelect={handleMainItemSelect}
        mapItemToNode={function convertItem(item: NavItemT): React.ReactNode {
          if (item.info?.component) {
            return item.info.component();
          } else {
            // This is consistent with the default baseweb behavior for defaultMapItemToNode
            // https://github.com/uber/baseweb/blob/master/src/app-nav-bar/utils.js
            return item.label;
          }
        }}
        username={user?.person?.name?.displayName}
        usernameSubtitle={
          user == undefined || user?.isAdmin == undefined
            ? undefined
            : user?.isAdmin
            ? "Admin"
            : "User"
        }
        userItems={userItems}
        onUserItemSelect={handleUserItemSelect}
        // NOTE: VS Code notes this as a compilation error, but it is still
        // valid typescript and will compile and run.
        overrides={{
          AppName: {
            style: () => ({
              color: KmTopAppBar.primaryElementFontColor,
            }),
            // eslint-disable-next-line react/display-name
            component: () => (
              <AppDescriptor
                homeDestination={transformEngineNavItem.info.url}
              />
            ),
          },
          Spacing: {},
          MainMenuItem: {
            style: (props) => ({
              outline: null,
              color: KmTopAppBar.defaultTextColor,
              borderBottomColor: props.$active
                ? KmTopAppBar.defaultTextColor
                : KmTopAppBar.headerColor,
              ":hover": {
                color: KmTopAppBar.secondaryTextColor,
              },
            }),
          },
          Root: {
            style: () => ({
              outline: `${KmTopAppBar.headerColor} solid`,
              backgroundColor: KmTopAppBar.headerColor,
            }),
          },
        }}
      />
    </>
  );
}

const AppDescriptor = ({
  homeDestination,
}: {
  homeDestination: string;
}): JSX.Element => {
  return (
    <>
      <Link style={homeLinkStyle} to={homeDestination}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <img
            src={LOGO_URL.toString()}
            height="50rem"
            style={{ marginRight: "0.5rem" }}
          ></img>
          <AppVersionInfo />
        </div>
      </Link>
    </>
  );
};

const AppInfoTooltip = ({
  appInfoValues,
}: {
  appInfoValues: AppInfo;
}): JSX.Element => {
  return (
    <LabelSmall color={KmTopAppBar.defaultTextColor}>
      <>
        Build Date: {appInfoValues?.version?.buildDate}
        <br />
        Commit: {appInfoValues?.version?.abbreviatedCommitHash}
        <MigrationStatus
          migrationState={appInfoValues?.schemaStatus?.lastExecutedMigration}
        />
      </>
    </LabelSmall>
  );
};

const AppVersionInfo = (): JSX.Element => {
  const { loading, error, data } = useQuery(gql(appInfo));
  const appInfoValues = data?.appInfo as AppInfo;

  return (
    <>
      {loading || error ? (
        <></>
      ) : (
        <StatefulTooltip
          placement={PLACEMENT.right}
          content={<AppInfoTooltip appInfoValues={appInfoValues} />}
        >
          <LabelXSmall marginTop={"10px"} color={KmTopAppBar.defaultTextColor}>
            &nbsp; {appInfoValues?.version?.descriptor}
          </LabelXSmall>
        </StatefulTooltip>
      )}
    </>
  );
};

const MigrationStatus = ({
  migrationState,
}: {
  migrationState: MigrationExecutionState;
}): JSX.Element => {
  switch (migrationState.__typename) {
    case "ExecutedMigration":
      return (
        <div style={{ display: "block", whiteSpace: "pre" }}>
          {`Latest Data Migration: {\n` +
            `    Id: ${migrationState.changeId} \n` +
            `    Executed: ${
              formattedDateTime(migrationState.timestamp) ?? "N/A"
            } \n` +
            `}`}
        </div>
      );
    case "NoMigrationsExecuted":
      return <div>{migrationState.message}</div>;
    case "ErrorGettingMigrations":
      return <div>{migrationState.errorMessage}</div>;
    default:
      const _exhaustiveCheck: never = migrationState;
      return _exhaustiveCheck;
  }
};
