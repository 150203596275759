/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { FileUploader } from "baseui/file-uploader";
import { notify, notifyBatchResponse } from "../../Shared/Notify";
import { DirectionalStack } from "../../DesignSystem/Containers";
import axios from "axios";
import { AxiosContext } from "../../Utils/AuthContext";
import {
  BulkAddModelResponse,
  ElementUploadFailure,
  ElementUploadSuccess,
  ModelQueryResponse,
} from "Api";
import { ModalButton, ModalFooter } from "baseui/modal";
import { Table } from "baseui/table-semantic";
import { Button } from "baseui/button";
import { gql, useMutation, useQuery } from "@apollo/client";
import findModelsWithText from "../../Api/Gql/FindModelsWithText";
import { handleApolloError } from "../../Shared/Errors";
import { deleteModelMutation } from "../../Api/Gql/DeleteModel";

const SEARCH_MODELS_QUERY = gql(findModelsWithText);
const parsedDeleteModelGql = gql(deleteModelMutation);

const FIND_MODELS_WITH_TEXT_QUERY_NAME = "FindModelsWithText";

const getAllFailedMessage = (
  failedElements?: ElementUploadFailure[]
): string => {
  const errorMessages = failedElements.map((element) => element.failureMessage);
  const uniqueErrorMessages = [...new Set(errorMessages)].join(", ");
  return `Failed to upload all files because of ${uniqueErrorMessages}.`;
};

const getPartialSuccessMessage = (
  failedElements: ElementUploadFailure[],
  successfulElements?: ElementUploadSuccess[],
  duplicateElements?: string[]
): string => {
  const failedFileNames = failedElements
    .map((element) => element.uploadedFileName)
    .join(", ");

  if (duplicateElements) {
    return `Added ${successfulElements.length} files; ${duplicateElements.length} files were previously uploaded. Could not upload ${failedFileNames}.`;
  } else {
    return `Added ${successfulElements.length} files. Could not upload ${failedFileNames}.`;
  }
};

const getSingleSuccessMessage = (
  successfulElement: ElementUploadSuccess
): string => {
  return `Added ${successfulElement.uploadedFileName}`;
};

const getFewSuccessMessage = (
  successfulElements: ElementUploadSuccess[]
): string => {
  const successfulFileNames = successfulElements
    .map((element) => element.uploadedFileName)
    .join(", ");
  return `Added ${successfulFileNames}`;
};

const getManySuccessMessage = (
  successfulElements: ElementUploadSuccess[]
): string => {
  return `Added ${successfulElements.length} files.`;
};

const getSuccessMessageManyDuplicate = (
  successfulElements: ElementUploadSuccess[],
  duplicateElements: string[]
): string => {
  return `Added ${successfulElements.length} files; ${duplicateElements.length} files were previously uploaded.`;
};

const getSuccessMessageSingleDuplicate = (
  successfulElements: ElementUploadSuccess[],
  duplicateElement: string
): string => {
  return `Added ${successfulElements.length} files. ${duplicateElement} was previously uploaded.`;
};

const notifyBulkAddResponse = (bulkAddResponse: BulkAddModelResponse): void => {
  const { successfulUploads, failedUploads, operationError } = bulkAddResponse;

  const duplicateUploads = successfulUploads
    ?.filter((success) => success.isDuplicate)
    .map((success) => success.uploadedFileName);

  if (operationError) {
    notify.negative(`Upload could not start because ${operationError}`);
  } else {
    notifyBatchResponse(
      successfulUploads,
      duplicateUploads,
      failedUploads,
      getAllFailedMessage,
      getPartialSuccessMessage,
      getSingleSuccessMessage,
      getFewSuccessMessage,
      getManySuccessMessage,
      getSuccessMessageManyDuplicate,
      getSuccessMessageSingleDuplicate
    );
  }
};

export const AddFileModal = ({
  onBack,
  onFinish,
}: {
  onBack: () => void;
  onFinish: () => void;
}): JSX.Element => {
  const [selectedFiles, setSelectedFiles] = React.useState<File[]>([]);
  const [isConfirmation, setIsConfirmation] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [hasSearched, setHasSearched] = React.useState(false);
  const axiosContext = React.useContext(AxiosContext);

  const { data } = useQuery(SEARCH_MODELS_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      textSubstring: searchText,
    },
    onError: (error) => handleApolloError(error, "Error fetching models!"),
    skip: !searchText,
  });

  React.useEffect(() => {
    if (searchText && !hasSearched) {
      setHasSearched(true);
    }
  }, [searchText, hasSearched]);

  const [deleteModel] = useMutation(parsedDeleteModelGql, {
    refetchQueries: [FIND_MODELS_WITH_TEXT_QUERY_NAME],
  });

  const handleDelete = React.useCallback(
    (modelId: string) => {
      deleteModel({ variables: { modelId: modelId } });
    },
    [deleteModel]
  );

  const textQueryResponse: ModelQueryResponse = data?.findModelsWithText;
  const resultData = React.useMemo(() => {
    return textQueryResponse?.results ?? [];
  }, [textQueryResponse?.results]);

  React.useEffect(() => {
    if (hasSearched && resultData.length > 0) {
      resultData.forEach((result) => {
        handleDelete(result.id);
      });

      setSearchText("");
      setHasSearched(false);
    }
  }, [handleDelete, hasSearched, resultData]);

  const handleNext = (): void => {
    setIsConfirmation(true);
  };

  const handleBack = (): void => {
    if (isConfirmation) {
      setIsConfirmation(false);
    } else {
      onBack();
    }
  };

  const onRemoveFile = (fileToRemove: File): void => {
    const fileNameWithoutExtension = fileToRemove.name
      .split(".")
      .slice(0, -1)
      .join(".");
    setSearchText(fileNameWithoutExtension);
    setHasSearched(false);

    setSelectedFiles((prevFiles) =>
      prevFiles.filter((file) => file !== fileToRemove)
    );
  };

  const handleFilesSelected = React.useCallback(
    async (files: File[]): Promise<void> => {
      setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
      const postToken = axios.CancelToken.source();

      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`${index}`, file, file.name);
      });

      try {
        const httpBulkAddResponse = await axiosContext.post(
          "/bulkAddModels",
          formData,
          {
            cancelToken: postToken.token,
          }
        );

        const bulkAddResponse =
          httpBulkAddResponse.data as BulkAddModelResponse;
        notifyBulkAddResponse(bulkAddResponse);
      } catch (reason) {
        if (axios.isCancel(reason)) {
          // User cancelled; no notification necessary.
          return;
        }
      }
    },
    [axiosContext, setSelectedFiles]
  );

  const getFileExtension = (filename): string => {
    return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  };

  const tableData: (string | JSX.Element)[][] = selectedFiles.map((file) => [
    file.name,
    getFileExtension(file.name),
    <Button
      key={`remove-${file.name}`}
      onClick={() => onRemoveFile(file)}
      kind={"primary"}
      size={"compact"}
    >
      {"Remove"}
    </Button>,
  ]);

  const renderContent = (): JSX.Element => {
    if (isConfirmation) {
      return (
        <div>
          <h3>Confirmation</h3>
          <p>You have selected the following files:</p>
          {selectedFiles.length > 0 && (
            <div>
              <h3>Selected Files:</h3>
              <Table columns={["Filename", "Extension"]} data={tableData} />
            </div>
          )}
          <p>Proceed to populating Transform Engine with these files?</p>
        </div>
      );
    }

    return (
      <DirectionalStack>
        {`Choose files to upload. Each file will be used to populate the transform engine.`}
        <FileSelector onFilesSelected={handleFilesSelected} />
        {selectedFiles.length > 0 && (
          <div>
            <h3>Selected Files:</h3>
            <Table columns={["Filename", "Extension", ""]} data={tableData} />
          </div>
        )}
      </DirectionalStack>
    );
  };

  return (
    <div>
      {renderContent()}
      <div style={{ position: "relative", top: "35px" }}>
        <ModalFooter>
          <ModalButton kind="tertiary" onClick={handleBack}>
            Back
          </ModalButton>
          {!isConfirmation && (
            <ModalButton
              kind="tertiary"
              onClick={handleNext}
              disabled={selectedFiles.length === 0}
            >
              Next
            </ModalButton>
          )}
          {isConfirmation && (
            <ModalButton kind="tertiary" onClick={onFinish}>
              Finish
            </ModalButton>
          )}
        </ModalFooter>
      </div>
    </div>
  );
};

const FileSelector = ({
  onFilesSelected,
}: {
  onFilesSelected: (files: File[]) => void;
}): JSX.Element => {
  const [isUploading, setIsUploading] = React.useState(false);

  return (
    <>
      <FileUploader
        multiple={true}
        onDrop={async (acceptedFiles, _) => {
          try {
            setIsUploading(true);
            onFilesSelected(acceptedFiles);
          } finally {
            setIsUploading(false);
          }
        }}
        progressMessage={isUploading ? `Uploading... hang tight.` : ""}
      />
    </>
  );
};
