/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import * as React from "react";
import {
  Modal,
  ModalButton,
  ModalFooter,
  ModalHeader,
  ModalBody,
} from "baseui/modal";
import { RightAlignedRow } from "../DesignSystem/Containers";
import { AddFileModal } from "./TransformActions/AddFileModal";

type Page =
  | "main"
  | "Create New Transform"
  | "Upload Files and Recommend Transform"
  | "Select a Previous Transform Session"
  | "Load Transform from Template"
  | "Confirmation";

export const TransformEngineActionsModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}): JSX.Element => {
  const [pageHistory, setPageHistory] = React.useState<Page[]>(["main"]);
  const [selectedOption, setSelectedOption] = React.useState<Page | null>(null);

  const closeModal = React.useCallback(() => {
    onClose();
    setPageHistory(["main"]);
    setSelectedOption(null);
  }, [onClose]);

  const handleNext = (): void => {
    if (selectedOption) {
      setPageHistory((prevHistory) => {
        if (!prevHistory.includes(selectedOption)) {
          return [...prevHistory, selectedOption];
        }
        return prevHistory;
      });
    }

    if (currentPage != "main") {
      setPageHistory((prevHistory) => {
        return [...prevHistory, "Confirmation"];
      });
    }
  };

  const handleOptionClick = (page: Page): void => {
    setSelectedOption(page);
  };

  const handleBack = (): void => {
    setPageHistory((prevHistory) => {
      if (prevHistory.length > 1) {
        setSelectedOption(null);
        return prevHistory.slice(0, -1);
      }
      setSelectedOption(null);
      return prevHistory;
    });
  };

  const currentPage = pageHistory[pageHistory.length - 1];

  const renderContent = (): JSX.Element => {
    const content = (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        {(() => {
          switch (currentPage) {
            case "main":
              return (
                <>
                  <ModalButton
                    onClick={() => handleOptionClick("Create New Transform")}
                    kind={
                      selectedOption == "Create New Transform"
                        ? "primary"
                        : "secondary"
                    }
                    style={{ marginBottom: "10px" }}
                  >
                    Create New Transform
                  </ModalButton>

                  <ModalButton
                    kind={
                      selectedOption == "Upload Files and Recommend Transform"
                        ? "primary"
                        : "secondary"
                    }
                    onClick={() =>
                      handleOptionClick("Upload Files and Recommend Transform")
                    }
                    style={{ marginBottom: "10px" }}
                  >
                    Upload Files and Recommend Transform
                  </ModalButton>

                  <ModalButton
                    kind={
                      selectedOption == "Select a Previous Transform Session"
                        ? "primary"
                        : "secondary"
                    }
                    onClick={() =>
                      handleOptionClick("Select a Previous Transform Session")
                    }
                    style={{ marginBottom: "10px" }}
                  >
                    Select a Previous Transform Session
                  </ModalButton>

                  <ModalButton
                    kind={
                      selectedOption == "Load Transform from Template"
                        ? "primary"
                        : "secondary"
                    }
                    onClick={() =>
                      handleOptionClick("Load Transform from Template")
                    }
                  >
                    Load Transform from Template
                  </ModalButton>
                </>
              );
            case "Create New Transform":
              return <p>Create a new transform from scratch</p>;
            case "Upload Files and Recommend Transform":
              return (
                <AddFileModal
                  onBack={handleBack}
                  onFinish={closeModal}
                ></AddFileModal>
              );
            case "Select a Previous Transform Session":
              return (
                <p>
                  This page will include a list of previous transform sessions
                  to populate engine with
                </p>
              );
            case "Load Transform from Template":
              return (
                <p>
                  This page will include transform templates to populate
                  transform engine with
                </p>
              );
            case "Confirmation":
              return (
                <p>This is a confirmation of the {selectedOption} action</p>
              );
            default:
              return null;
          }
        })()}
      </div>
    );

    return content;
  };

  const getPageTitle = (page: Page): string => {
    switch (page) {
      case "main":
        return "Select an option to begin";
      case "Create New Transform":
        return page;
      case "Upload Files and Recommend Transform":
        return page;
      case "Select a Previous Transform Session":
        return page;
      case "Load Transform from Template":
        return page;
      case "Confirmation":
        return `${selectedOption} Action Summary`;
    }
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Modal isOpen={isOpen} onClose={closeModal} closeable={false} autoFocus>
        <ModalHeader>{getPageTitle(currentPage)}</ModalHeader>
        <ModalBody>{renderContent()}</ModalBody>
        <ModalFooter>
          {currentPage == "main" && (
            <RightAlignedRow>
              <ModalButton
                disabled={selectedOption == null && currentPage == "main"}
                kind="tertiary"
                onClick={handleNext}
              >
                Next
              </ModalButton>

              <ModalButton kind="tertiary" onClick={closeModal}>
                Cancel
              </ModalButton>
            </RightAlignedRow>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
};
