/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { useCallback, useEffect, useRef, useState } from "react";

/**
 * This example is adopted from a answer on stack overflow for a way to consistently and
 * effectively handle async data calls.
 * https://stackoverflow.com/questions/56450975/to-fix-cancel-all-subscriptions-and-asynchronous-tasks-in-a-useeffect-cleanup-f
 *
 * A hook to fetch async data.
 * @class useAsyncA
 * @param {async} _.asyncFunc         Promise like async function
 * @param {bool} _.immediate=false    Invoke the function immediately
 * @param {object} _.funcParams       Function initial parameters
 * @param {object} _.initialData      Initial data
 * @example
 *   const { execute, loading, data, error } = useAync({
 *    asyncFunc: async () => { return 'data' },
 *    immediate: false,
 *    funcParams: { data: '1' },
 *    initialData: 'Hello'
 *  })
 */
export const useAsyncA = ({
  asyncFunc,
  immediate,
  funcParams,
  initialData,
}: {
  asyncFunc: ({}) => Promise<any>;
  immediate: boolean;
  funcParams?: any;
  initialData?: any;
}): any => {
  const [loading, setLoading] = useState(immediate);
  const [data, setData] = useState(initialData);
  const [error, setError] = useState(null);
  const mountedRef = useRef(true);

  const execute = useCallback(
    (params) => {
      setLoading(true);
      return asyncFunc({ ...funcParams, ...params })
        .then((res) => {
          if (!mountedRef.current) return null;
          setData(res);
          setError(null);
          setLoading(false);
          return res;
        })
        .catch((err) => {
          if (!mountedRef.current) return null;
          setError(err);
          setLoading(false);
          throw err;
        });
    },
    [asyncFunc, funcParams]
  );

  useEffect(() => {
    if (immediate) {
      execute(funcParams);
    }
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    execute,
    loading,
    data,
    error,
  };
};

/**
 * Another hook adapted from a different StackOverflow page.
 * https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
 */
export function useAsyncB<T>(
  asyncFn: () => Promise<T>,
  onFulfilled?: (data: T) => void,
  onRejected?: (data: T) => void
): void {
  useEffect(() => {
    let isActive = true;
    asyncFn().then(
      (data: T) => {
        if (isActive && onFulfilled) {
          onFulfilled(data);
        }
      },
      (data: T) => {
        if (isActive && onRejected) {
          onRejected(data);
        }
      }
    );
    return () => {
      isActive = false;
    };
  }, [asyncFn, onFulfilled, onRejected]);
}
