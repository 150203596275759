/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
export interface SuccessOutcome {
  type: "Success";
  successful: true;
}

export function successOutcome(): SuccessOutcome {
  const outcome: SuccessOutcome = { type: "Success", successful: true };
  return outcome;
}

export function isSuccess(
  outcome: OperationOutcome
): outcome is SuccessOutcome {
  return outcome.type === "Success";
}

export interface FailureOutcome {
  type: "Failure";
  successful: false;
  message?: string;
}

export function failureOutcome(message?: string): FailureOutcome {
  const outcome: FailureOutcome = {
    type: "Failure",
    successful: false,
    message: message,
  };
  return outcome;
}

export function isFailure(
  outcome: OperationOutcome
): outcome is FailureOutcome {
  return outcome.type === "Failure";
}

export type OperationOutcome = SuccessOutcome | FailureOutcome;
