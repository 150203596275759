/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
// Extensions for working with the data provided by the API in SIMOR.

import { ModelMetadata, ModelInfoInput, ModelMetadataSchema } from "Api";
import { deepEquals } from "../Utils/Comparison";
import { EMPTY_UUID } from "../Utils/Types";

const EMPTY_MODEL_METADATA: ModelMetadata = {
  title: "",
  version: "",
  description: "",
  keywords: [],
};

export function emptyModelMetadata(): ModelMetadata {
  return JSON.parse(JSON.stringify(EMPTY_MODEL_METADATA));
}

export function emptyModelInfoInput(): ModelInfoInput {
  return {
    id: EMPTY_UUID,
    securityMarkings: null,
    fileFolders: null,
    metadata: emptyModelMetadata(),
  };
}

export function emptyModelMetadataSchema(): ModelMetadataSchema {
  return {
    id: EMPTY_UUID,
    fields: {
      selectMultiple: null,
    },
  };
}

export function isModelChanged(
  original: ModelInfoInput,
  edited: ModelInfoInput
): boolean {
  if (edited.id !== original.id) {
    return true;
  }

  return !deepEquals(edited, original);
}
