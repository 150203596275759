/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
// Kobayashi Maru "Dark" theme from the Air Force, v1.4.
//
// https://software.af.mil/softwarefactory/kobayashi-maru/

import { KmTheme } from "./KmTheme";

const defaultTextColor = "#ffffff";
const secondaryTextColor = "#d4d8dd";
const primaryBlue = "#4dacff";
const primaryLight = "#92cbff";
const primaryDark = "#3a87cf";

export const topBarAppHeaderColor = "#172635";

export const KmDarkTheme: KmTheme = {
  colors: {
    backgroundColor: "#101923",
    surfaceElementColor: "#1b2d3e",
    defaultTextColor: defaultTextColor,
    secondaryTextColor: secondaryTextColor,
    primaryBlue: primaryBlue,
    primaryLight: primaryLight,
    primaryDark: primaryDark,
    primaryElementFontColor: "#080c11",
    linkColor: "#4dacff",
    selectedTabColor: primaryBlue,
    helpTextColor: secondaryTextColor,
    errorTextColor: "#cc0000",
  },
  fileUpload: {
    dropAreaLineColorHover: defaultTextColor,
    dropAreaLineColor: secondaryTextColor,
  },
  button: {
    primaryHover: primaryDark,
  },
};
