/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { Button } from "baseui/button";
import { ButtonGroup, MODE } from "baseui/button-group";
import { Textarea } from "baseui/textarea";
import { LabelSmall } from "baseui/typography";
import * as React from "react";
import { MetadataScan, ScanResult } from "../Api/Api";
import {
  concatUnspecifiedFields,
  flattenPresentMetadata,
} from "../Model/ModelMetadataFields";
import { FieldSpec } from "../Shared/FieldSpec";
import CustomPropsTable from "./CustomPropsTable";
import MetadataPropsEditor from "./MetadataPropsEditor";

export const MetadataScanEditor = ({
  metadataScan,
  modelFields,
  defaultViewTab,
}: {
  metadataScan?: MetadataScan;
  modelFields: FieldSpec[];
  defaultViewTab?: number;
}): JSX.Element => {
  const [selected, setSelected] = React.useState<number>(defaultViewTab ?? 0);

  const metadataValues = React.useMemo(() => {
    return flattenPresentMetadata(metadataScan?.metadata);
  }, [metadataScan?.metadata]);

  const aggregatePresentFields = React.useMemo(() => {
    // Add each field to be shown, if it is present.
    const presentFields: FieldSpec[] = modelFields.filter(
      (field) => metadataValues[field.propertyName] != null
    );

    return concatUnspecifiedFields(
      presentFields,
      metadataScan?.metadata?.customProps
    );
  }, [modelFields, metadataValues, metadataScan?.metadata?.customProps]);

  if (metadataScan?.scanResult !== ScanResult.SUCCESS) {
    switch (metadataScan?.scanResult) {
      case ScanResult.FILETYPE_NOT_SUPPORTED:
        return (
          <LabelSmall>
            {"Automatic analysis for files of this type are not yet supported."}
          </LabelSmall>
        );
      case ScanResult.FILE_DOES_NOT_EXIST:
        return <LabelSmall>{"This file does not exist."}</LabelSmall>;
      case ScanResult.ERROR:
        if (metadataScan.scanMessage) {
          return <LabelSmall>{metadataScan.scanMessage}</LabelSmall>;
        }
      // fall-through
      default:
        return (
          <LabelSmall>
            {"An unexpected error occured. Unable to display results."}
          </LabelSmall>
        );
    }
  }

  const textContent = metadataScan?.metadata?.content;

  const contentType =
    textContent && textContent.length > 0
      ? metadataValues["Content-Type"]
      : null;
  const displayTextContent =
    textContent && textContent.length > 0 && aggregatePresentFields.length == 0;

  if (contentType?.startsWith("image/")) {
    return (
      <>
        <img src={"data:" + contentType + ";base64," + textContent} />
      </>
    );
  }

  if (displayTextContent) {
    return (
      <>
        <Textarea value={textContent} />
      </>
    );
  }

  return (
    <>
      <ButtonGroup
        mode={MODE.radio}
        selected={selected}
        onClick={(_event, index) => {
          setSelected(index);
        }}
      >
        <Button>Table</Button>
        <Button>List</Button>
      </ButtonGroup>
      {selected == 0 && <CustomPropsTable customProps={metadataValues} />}
      {selected == 1 && (
        <MetadataPropsEditor
          fields={aggregatePresentFields}
          metadataValues={metadataValues}
        />
      )}
    </>
  );
};
