/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";

import { Accordion, Panel } from "baseui/accordion";
import { Button } from "baseui/button";
import { PLACEMENT, ToasterContainer } from "baseui/toast";
import { HeadingXXLarge, LabelLarge } from "baseui/typography";

import { gql, useQuery } from "@apollo/client";

import appInfo from "./Api/Gql/AppInfo";
import { AppInfo } from "Api";

import { SimorAuthContext } from "./Utils/AuthContext";
import { CenteredContent } from "./DesignSystem/Containers";
import { MigrationUpdate, showMigrationPage } from "./MigrationUpdate";
import { UserApp } from "./UserApp";
import { notify } from "./Shared/Notify";
import { handleApolloError } from "./Shared/Errors";
import { setIsSensitiveSecurityContextVar } from "./GlobalState";
import { AppInitialization, isAppInitialized } from "./AppInitialization";
import { useAsyncA } from "./Utils/UseAsyncHook";
import { APP_TITLE_TITLECASE } from "./Utils/SiteProps";
import { LOGO_URL } from "./Utils/SiteLogo.mjs";

export const DexterApp = (): JSX.Element => {
  const simorAuth = React.useContext(SimorAuthContext);
  return simorAuth.isAuthorized() ? (
    <CheckAppReady />
  ) : (
    <ToasterContainer placement={PLACEMENT.topRight}>
      <UserUnauthorized />
    </ToasterContainer>
  );
};

const UserUnauthorized = (): JSX.Element => {
  const [appInitialized, setAppInitialized] = React.useState(true);

  const { data } = useAsyncA({
    asyncFunc: isAppInitialized,
    immediate: true,
    initialData: true,
  });

  React.useEffect(() => {
    setAppInitialized(data);
  }, [data]);

  return (
    <>
      {appInitialized ? (
        <ChooseUser />
      ) : (
        <AppInitialization onInitialization={setAppInitialized} />
      )}
    </>
  );
};

const CheckAppReady = (): JSX.Element => {
  const [migrationIsNeeded, setMigrationIsNeeded] = React.useState(false);
  const [appInfoValues, setAppInfoValues] = React.useState({} as AppInfo);
  const [ignoreRequiredMigration, setIgnoreRequiredMigration] =
    React.useState(false);

  useQuery(gql(appInfo), {
    onCompleted: (data: { appInfo: AppInfo }) => {
      setIsSensitiveSecurityContextVar(
        data?.appInfo.configurations.isSensitiveSecurityContext
      );
      setAppInfoValues(data?.appInfo);
      setMigrationIsNeeded(showMigrationPage(data?.appInfo.schemaStatus));
    },
    onError: (error) => {
      handleApolloError(
        error,
        "An error occured while attempting to query for AppInfo."
      );
    },
  });

  const handleMigrationCompleted = (): void => {
    setMigrationIsNeeded(false);
    notify.positive(
      `Migration to version "${appInfoValues.schemaStatus.expectedMigrationId}" complete.`
    );
  };

  return !migrationIsNeeded || ignoreRequiredMigration ? (
    <UserApp />
  ) : (
    <ToasterContainer placement={PLACEMENT.topRight}>
      <MigrationUpdate
        appInfo={appInfoValues}
        onMigrationIgnore={() => setIgnoreRequiredMigration(true)}
        onMigrationComplete={() => handleMigrationCompleted()}
      />
    </ToasterContainer>
  );
};

const ChooseUser = (): JSX.Element => {
  const simorAuth = React.useContext(SimorAuthContext);

  return (
    <CenteredContent>
      <img src={LOGO_URL.toString()} height="200rem"></img>
      <HeadingXXLarge>{`Welcome to ${APP_TITLE_TITLECASE}!`}</HeadingXXLarge>
      <LabelLarge>{"Choose a user to get started"}</LabelLarge>
      <Button
        onClick={() => simorAuth.loginUser("DexterUser")}
        kind={"primary"}
      >
        {"DexterUser"}
      </Button>
      <div style={{ paddingTop: "2rem", width: "20rem" }}>
        <Accordion>
          <Panel title="Other Test Users">
            <Button
              onClick={() => simorAuth.loginUser("Bob Smith")}
              kind={"tertiary"}
            >
              {"Access Restricted User"}
            </Button>
            <Button
              onClick={() => simorAuth.loginUser("Juliet Devereux")}
              kind={"tertiary"}
            >
              {"Power User"}
            </Button>
          </Panel>
        </Accordion>
      </div>
    </CenteredContent>
  );
};
