/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { isBlank } from "../Utils/Strings";
import { FileUpload } from "./FileUpload";
import { compareCaseInsensitive, equalIgnoreCase } from "../Utils/Sort";
import { replaceBy } from "../Utils/Array";
import { createUUID } from "../Utils/Types";

export interface FileNode {
  type: "File";
  id: string;
  isSelected: boolean;
  file?: FileUpload;
}

export interface FolderNode {
  type: "Folder";
  id: string;
  title: string;
  children?: FileTreeNode[];
  isEditing: boolean;
  newTitle: string;
  isExpanded: boolean;
  isSelected: boolean;
}

export type FileTreeNode = FileNode | FolderNode;

export const ROOT_FILE_TREE_NODE_ID = "ROOT_FILE_TREE_NODE";
export const isRootNode = (node: FileTreeNode): boolean =>
  node.id === ROOT_FILE_TREE_NODE_ID;

export const uniqueFileTreeNodeId = (): string => {
  const uuid = createUUID();
  return uuid + "_FTN";
};

export function newFolder(title: string, id?: string): FolderNode {
  return {
    type: "Folder",
    id: id ?? uniqueFileTreeNodeId(),
    isExpanded: false,
    isSelected: false,
    isEditing: false,
    newTitle: title,
    title: title,
    children: [],
  };
}

export function newFile(file: FileUpload): FileNode {
  return {
    type: "File",
    id: uniqueFileTreeNodeId(),
    file,
    isSelected: false,
  };
}

export type ModelFileTreeAction =
  | AddFolderAction
  | CancelFolderEditAction
  | CommitFolderEditAction
  | DeleteFileTreeNodeAction
  | EditFolderNameAction
  | SelectFileTreeNodeAction
  | SetFolderExpandedAction
  | StartEditFolderNameAction;

type CancelFolderEditAction = ["cancelFolderEdit", FolderNode];

export function applyCancelFolderEdit(
  root: FolderNode,
  editedNode: FolderNode
): FolderNode {
  const changedNode: FolderNode = {
    ...editedNode,
    newTitle: editedNode.title,
    isEditing: false,
  };

  return replaceNodeById(root, changedNode);
}

type CommitFolderEditAction = ["commitFolderEdit", FolderNode];

export function applyCommitFolderEdit(
  root: FolderNode,
  editedNode: FolderNode
): FolderNode {
  if (!isEditLegal(root, editedNode)) {
    return root;
  }

  const changedNode: FolderNode = {
    ...editedNode,
    title: editedNode.newTitle,
    isEditing: false,
  };

  return replaceNodeById(root, changedNode);
}

function isEditLegal(root: FolderNode, editedNode: FolderNode): boolean {
  if (isBlank(editedNode.newTitle)) {
    return false;
  }

  const pathToExistingNode = findNodePathById(root, editedNode.id);
  if (pathToExistingNode && pathToExistingNode.length > 1) {
    // Make sure the edit is legal.
    const parentIndex = pathToExistingNode.length - 2;
    const parent = pathToExistingNode[parentIndex] as FolderNode;

    // Find any children that aren't the edited node that have the same name.
    const existingChildWithName = parent.children.findIndex((child) => {
      if (child.id === editedNode.id) {
        return false;
      }

      return child.type === "File"
        ? equalIgnoreCase(child.file?.info.name, editedNode.newTitle)
        : equalIgnoreCase(child.title, editedNode.newTitle);
    });

    return existingChildWithName === -1;
  }

  return true;
}

type DeleteFileTreeNodeAction = ["deleteFileTreeNode", FileTreeNode];

export function applyDelete(
  root: FolderNode,
  nodeToRemove: FileTreeNode
): FolderNode {
  const nodePath = findNodePathById(root, nodeToRemove.id);

  if (!nodePath || nodePath.length === 0) {
    throw Error(`Node path not found for node ${nodeToRemove.id}`);
  }

  if (nodePath.length === 1) {
    throw Error(`Can't delete the root node ${nodeToRemove.id}`);
  }
  const oldRemovedNodeParent = nodePath[nodePath.length - 2] as FolderNode;

  const newRemovedNodeParent: FolderNode = {
    ...oldRemovedNodeParent,
    children: oldRemovedNodeParent.children?.filter(
      (child) => child.id !== nodeToRemove.id
    ),
  };
  return replaceNodeById(root, newRemovedNodeParent, false);
}

type EditFolderNameAction = [
  "editFolderName",
  { editedFolder: FolderNode; newTitle: string }
];

export function applyEditFolderName(
  root: FolderNode,
  editedNode: FolderNode,
  newTitle: string
): FolderNode {
  const changedNode: FolderNode = {
    ...editedNode,
    newTitle,
  };

  return replaceNodeById(root, changedNode, false);
}

type StartEditFolderNameAction = ["startEditFolderName", FolderNode];

export function applyStartEditFolderName(
  root: FolderNode,
  editedNode: FolderNode
): FolderNode {
  const changedNode: FolderNode = {
    ...editedNode,
    isEditing: true,
    newTitle: editedNode.title,
  };

  return replaceNodeById(root, changedNode, false);
}

type AddFolderAction = ["addFolder", FolderNode];

export function applyInsertFolder(
  root: FolderNode,
  parent: FolderNode
): FolderNode {
  const insertedFolder = newFolder("Untitled Folder");
  insertedFolder.isEditing = true;
  insertedFolder.newTitle = "";

  const changedParent: FolderNode = {
    ...parent,
    // Always put it at the beginning to make it easy to find; don't re-sort the children.
    children: [insertedFolder, ...parent.children],
  };
  changedParent.isExpanded = true;

  return replaceNodeById(root, changedParent, false);
}

export function applyReplaceFiles(
  subroot: FolderNode,
  fileUploads: FileUpload[]
): FolderNode {
  return {
    ...subroot,
    children: subroot?.children.map((child) => {
      if (child.type === "Folder") {
        return applyReplaceFiles(child, fileUploads);
      }
      const newUploadMatchingChild = fileUploads.filter(
        (fileUpload) => fileUpload.info.id === child.file.info.id
      )[0];

      return newUploadMatchingChild
        ? {
            ...child,
            file: newUploadMatchingChild,
          }
        : child;
    }),
  };
}

type SelectFileTreeNodeAction = ["selectFileTreeNode", FileTreeNode];

export function applySelection(
  root: FolderNode,
  nodeToChange: FileTreeNode,
  selected: boolean
): FolderNode {
  const changedNode: FileTreeNode = {
    ...nodeToChange,
    isSelected: selected,
  };

  return replaceNodeById(root, changedNode);
}

type SetFolderExpandedAction = [
  "setFolderExpanded",
  { folderToChange: FolderNode; expanded: boolean }
];

export function applyExpanded(
  root: FolderNode,
  folderToChange: FolderNode,
  expanded: boolean
): FolderNode {
  const changedNode: FolderNode = {
    ...folderToChange,
    isExpanded: expanded,
  };

  return replaceNodeById(root, changedNode);
}

export function replaceNodeById(
  root: FolderNode,
  replacementNode: FileTreeNode,
  sort: boolean = true
): FolderNode {
  const pathToExistingNode = findNodePathById(root, replacementNode.id);
  if (!pathToExistingNode || pathToExistingNode.length === 0) {
    throw Error(
      `node ${replacementNode.id} not found in the tree under root ${root.id}`
    );
  }
  if (pathToExistingNode.length === 1) {
    if (replacementNode.type !== "Folder") {
      throw Error(
        `Replacing root, but new root is not a folder for ${replacementNode.id}`
      );
    }
    return replacementNode;
  }

  const replacementNodeIndex = pathToExistingNode.length - 1;
  pathToExistingNode[replacementNodeIndex] = replacementNode;

  // eslint-disable-next-line no-loops/no-loops
  for (
    let parentIndex = replacementNodeIndex - 1;
    parentIndex >= 0;
    parentIndex--
  ) {
    const newChild = pathToExistingNode[parentIndex + 1];
    const oldParent = pathToExistingNode[parentIndex] as FolderNode;
    const newChildren = replaceBy(
      oldParent.children,
      [newChild],
      (node) => node.id
    );
    if (sort) {
      newChildren.sort(compareFileTreeNodes);
    }
    const newParent: FolderNode = {
      ...oldParent,
      children: newChildren,
    };
    pathToExistingNode[parentIndex] = newParent;
  }

  // We know it's a folder node, because the passed in root is a folder node.
  return pathToExistingNode[0] as FolderNode;
}

export function compareFileTreeNodes(a: FileTreeNode, b: FileTreeNode): number {
  if (a.type == "Folder") {
    if (b.type == "Folder") {
      // Both a and b are folders
      return compareCaseInsensitive(a.title, b.title);
    } else {
      // a is a folder and b is a file; a is "less than" b
      return -1;
    }
  } else {
    if (b.type == "Folder") {
      // a is a file and b is a folder; a is "greater than" b
      return 1;
    } else {
      // Both a and b are files.
      return compareCaseInsensitive(a.file.info.name, b.file.info.name);
    }
  }
}

export interface ChildPath {
  child: string;
  descendantPathAfterChild: string;
}

export function childPath(path: string): ChildPath {
  const nextPathSegmentStart = path.indexOf("/");
  // We're at the end of the path string; insert the nodes here.
  const nextPathSegmentEnd =
    nextPathSegmentStart !== -1 ? nextPathSegmentStart : path.length;

  const child = path.substring(0, nextPathSegmentEnd);

  const descendantPathAfterChild = path.substring(nextPathSegmentEnd + 1);
  return {
    child,
    descendantPathAfterChild,
  };
}

export function findNodeById(treeNode: FileTreeNode, id: string): FileTreeNode {
  const nodePath = findNodePathById(treeNode, id);

  return nodePath && nodePath.length > 0
    ? nodePath[nodePath.length - 1]
    : undefined;
}

export function findNodePathById(
  treeNode: FileTreeNode,
  id: string
): FileTreeNode[] {
  if (treeNode.id === id) {
    return [treeNode];
  }

  if (treeNode.type === "File") {
    return undefined;
  }

  const descendentsWithId = treeNode.children
    ?.map((child) => findNodePathById(child, id))
    .filter((child) => child);

  return descendentsWithId && descendentsWithId.length > 0
    ? [treeNode, ...descendentsWithId[0]]
    : undefined;
}
