/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { FieldSpec } from "../Shared/FieldSpec";
import { FieldEditor } from "../Shared/Editors";
import { LabelXSmall } from "baseui/typography";

const MetadataPropsEditor = ({
  fields,
  metadataValues,
  onValueChange,
}: {
  fields: FieldSpec[];
  metadataValues: Record<string, any>;
  onValueChange?: (propertyName: string, value: any) => void;
}): JSX.Element => {
  const editingDisabled = React.useMemo(() => {
    return onValueChange == null;
  }, [onValueChange]);
  return (
    <>
      {fields &&
        fields.map((field, index) => {
          const propertyName = field.propertyName;

          return metadataValues ? (
            <div
              key={propertyName + index}
              data-testid={`model-metadata-${propertyName}`}
            >
              <FieldEditor
                field={field}
                value={metadataValues[propertyName]}
                disabled={editingDisabled}
                onChange={(newValue) => {
                  onValueChange(propertyName, newValue);
                }}
              />
            </div>
          ) : (
            <div
              key={propertyName + index}
              data-testid={`model-metadata-${propertyName}`}
            >
              <LabelXSmall>Field `${propertyName}` not found</LabelXSmall>
            </div>
          );
        })}
    </>
  );
};

export default MetadataPropsEditor;
