/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { Input } from "baseui/input";
import { Search } from "baseui/icon";

const searchInputStyle: React.CSSProperties = {
  paddingTop: "50px",
  display: "flex",
  width: "50%",
  margin: "auto",
};

// TODO: Possibly integrate this into theming so they
// can be used across future search inputs
const searchInputOverrides = {
  Root: {
    style: (props) => ({
      borderLeftColor: props.$isFocused
        ? props.$theme.km.colors.primaryBlue
        : props.$theme.colors.border,
      borderRightColor: props.$isFocused
        ? props.$theme.km.colors.primaryBlue
        : props.$theme.colors.border,
      borderTopColor: props.$isFocused
        ? props.$theme.km.colors.primaryBlue
        : props.$theme.colors.border,
      borderBottomColor: props.$isFocused
        ? props.$theme.km.colors.primaryBlue
        : props.$theme.colors.border,
    }),
  },
};

export interface SearchInputProps {
  onSearch: (searchQuery: string) => void;
  placeholder?: string;
}

export default function SearchInput({
  onSearch,
  placeholder,
}: SearchInputProps): JSX.Element {
  const [searchValue, setSearchValue] = React.useState("");

  function handleKeyPress(keyEvent: React.KeyboardEvent): void {
    if (keyEvent.key === "Enter") {
      onSearch(searchValue);
    }
  }

  return (
    <div style={searchInputStyle}>
      <Input
        value={searchValue}
        onChange={(e) =>
          setSearchValue((e.target as HTMLTextAreaElement).value)
        }
        onKeyPress={handleKeyPress}
        placeholder={placeholder}
        startEnhancer={<Search size="18px" />}
        overrides={searchInputOverrides}
      />
    </div>
  );
}
