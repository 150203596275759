/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { gql, useLazyQuery } from "@apollo/client";
import { Button } from "baseui/button";
import { Spinner } from "baseui/spinner";
import { Tag, VARIANT } from "baseui/tag";
import * as React from "react";
import modelTopics from "../Api/Gql/ModelTopics";
import { handleApolloError } from "../Shared/Errors";
import { UUID } from "../Utils/Types";

export const FileTopics = ({ fileId }: { fileId: UUID }): JSX.Element => {
  const [getModelTopics, { loading, data }] = useLazyQuery(gql(modelTopics), {
    variables: { id: fileId },
    fetchPolicy: "network-only",
    onError: (error) => handleApolloError(error, "Error fetching file topics!"),
  });

  return (
    <div style={{ width: "200px" }}>
      <Button kind={"primary"} onClick={() => getModelTopics()}>
        Get File Topics
      </Button>
      <div>
        {loading ? (
          <Spinner />
        ) : (
          data?.modelTopics?.topics.map((topic, index) => (
            <Tag variant={VARIANT.solid} key={index} closeable={false}>
              {topic}
            </Tag>
          ))
        )}
      </div>
    </div>
  );
};
