/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";

/**
 * Centers the element's children both vertically and horizontally.
 */
export const CenteredContent = ({
  children,
}: {
  children?: React.ReactNode;
}): JSX.Element => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        justifyItems: "center",
        alignItems: "center",
        rowGap: "1rem",
        height: "100vh",
      }}
    >
      {React.Children.map(children, (child) => {
        return child;
      })}
    </div>
  );
};

export const CenterControls = ({
  children,
}: {
  children?: React.ReactNode;
}): JSX.Element => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        justifyItems: "center",
        alignItems: "center",
        marginBottom: "1rem",
      }}
    >
      {React.Children.map(children, (child) => {
        return child;
      })}
    </div>
  );
};

export const RightAlignedRow = ({
  children,
  style,
}: {
  children?: React.ReactNode;
  style?: React.CSSProperties;
}): JSX.Element => {
  return (
    <Row
      style={{
        ...style,
        justifyContent: "flex-end",
      }}
    >
      {React.Children.map(children, (child) => {
        return child;
      })}
    </Row>
  );
};

// TODO: Eliminate duplication with RightAlignedRow
export const Row = ({
  children,
  style,
  gap,
}: {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  gap?: string | number;
}): JSX.Element => {
  return (
    <div
      style={{
        ...style,
        display: "flex",
        flexDirection: "row",
        gap: gap,
      }}
    >
      {React.Children.map(children, (child) => {
        return child;
      })}
    </div>
  );
};

export const DirectionalStack = ({
  gap = "1.5rem",
  direction = "column",
  children,
  style,
}: {
  gap?: string;
  direction?: "row" | "row-reverse" | "column" | "column-reverse";
  children?: React.ReactNode;
  style?: React.CSSProperties;
}): JSX.Element => {
  return (
    <div
      style={{
        ...style,
        display: "flex",
        flexDirection: direction,
        gap: gap,
      }}
    >
      {React.Children.toArray(children)}
    </div>
  );
};

export const VerticalSpacer = ({ height }: { height: string }): JSX.Element => {
  return <div style={{ height: height }}></div>;
};

export const HorizontalSpacer = ({ width }: { width: string }): JSX.Element => {
  return <div style={{ width: width }}></div>;
};

// TODO: Remove left/right, add check for no more than 2 elements.
export const EndAnchoredRow = ({
  children,
  style,
}: {
  children?: React.ReactNode;
  style?: React.CSSProperties;
}): JSX.Element => {
  const childCount = React.Children.count(children);
  if (childCount > 2) {
    throw Error(
      `Cannot have more than 2 children for an EndAnchoredRow, but had ${childCount}`
    );
  }
  return (
    <div
      style={{
        ...style,
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
      }}
    >
      {React.Children.toArray(children)}
    </div>
  );
};
