/* eslint-disable prettier/prettier */
/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import { Dispatch } from "react";

import { CancelToken } from "./CancelToken";

type IdleState = { step: "idle"; wasRejected?: true };
export const IDLE: PromiseState<never, never> = { step: "idle" };
type PendingState<T> = { step: "waiting"; promise: Promise<T>; cancelToken: CancelToken };
type FulfilledState<T> = { step: "fulfilled"; value: T };
type RejectedState<E> = { step: "rejected"; error: E };
export type PromiseState<T, E = unknown> = IdleState | PendingState<T> | FulfilledState<T> | RejectedState<E>;

export function SumRequestsInFlight(promiseStates: PromiseState<unknown>[]): number {
    return promiseStates.reduce((total, promiseState) => (promiseState.step == "waiting" ? total + 1 : total), 0);
}

export function IsWaiting<T>(promiseState: PromiseState<T>): promiseState is PendingState<T> {
    return promiseState.step == "waiting";
}

export function IfWaiting<R>(promiseState: PromiseState<unknown>, process: () => R): R | undefined {
    return promiseState.step == "waiting" ? process() : undefined;
}

export function IsFulfilled<T>(promiseState: PromiseState<T>): promiseState is FulfilledState<T> {
    return promiseState.step == "fulfilled";
}

export function IfFulfilled<T>(promiseState: PromiseState<T>): T | undefined;
export function IfFulfilled<T, R>(promiseState: PromiseState<T>, process: (value: T) => R): R | undefined;
export function IfFulfilled<T, R = T>(promiseState: PromiseState<T>, process?: (value: T) => R): T | R | undefined {
    return promiseState.step == "fulfilled" ? (process ? process(promiseState.value) : promiseState.value) : undefined;
}

export function IfRejected<E>(promiseState: PromiseState<unknown, E>): E | undefined;
export function IfRejected<R, E>(promiseState: PromiseState<unknown, E>, process: (error: E) => R): R | undefined;
export function IfRejected<R, E>(
    promiseState: PromiseState<unknown, E>,
    process?: (error: E) => R
): unknown | undefined {
    return promiseState.step == "rejected" ? (process ? process(promiseState.error) : promiseState.error) : undefined;
}

export function IfWasRejected(promiseState: PromiseState<unknown>): true | undefined;
export function IfWasRejected<R>(promiseState: PromiseState<unknown>, process: () => R): R | undefined;
export function IfWasRejected<R>(promiseState: PromiseState<unknown>, process?: () => R): true | R | undefined {
    return promiseState.step == "rejected" || (promiseState.step == "idle" && promiseState.wasRejected)
        ? process
            ? process()
            : true
        : undefined;
}

export function IsFinished<T, E = unknown>(
    promiseState: PromiseState<T, E>
): promiseState is FulfilledState<T> | RejectedState<E> {
    return promiseState.step == "fulfilled" || promiseState.step == "rejected";
}

export function PatchPromise<T, E>(promiseState: PromiseState<T, E>, update: (old: T) => T): PromiseState<T, E> {
    // the "update current value" operation only makes sense if we have a settled value
    if (promiseState.step == "fulfilled") {
        return { step: "fulfilled", value: update(promiseState.value) };
    } else {
        return promiseState;
    }
}

export type AsyncAction<T> = (cancelToken: CancelToken) => Promise<T>;
type StartAction<T, E> = ["start", AsyncAction<T>, Dispatch<PromiseOutcome<T, E>>];
type ResolveAction<T> = ["resolve", Promise<T>, T];
type RejectAction<T, E> = ["reject", Promise<T>, E];
type ClearStateAction = ["forget"];
type DismissErrorAction = ["dismissError"];
type PatchValueAction<T> = ["update", T];
export type PromiseOutcome<T, E = unknown> = ResolveAction<T> | RejectAction<T, E>;
export type PromiseAction<T, E> =
    | StartAction<T, E>
    | PromiseOutcome<T, E>
    | ClearStateAction
    | DismissErrorAction
    | PatchValueAction<T>;

export function ReducePromise<T>(state: PromiseState<T>, action: PromiseAction<T, unknown>): PromiseState<T>;
export function ReducePromise<T, E>(
    state: PromiseState<T, E>,
    action: PromiseAction<T, E>,
    decodeError: (error: unknown) => E
): PromiseState<T, E>;
export function ReducePromise<T>(
    state: PromiseState<T>,
    action: PromiseAction<T, unknown>,
    decodeError?: (error: unknown) => unknown
): PromiseState<T> {
    switch (action[0]) {
        case "start": {
            // cancel any already-inflight promise
            if (state.step == "waiting") {
                state.cancelToken.cancel();
            }

            const [{}, generator, dispatchResult] = action;
            const cancelToken = new CancelToken();
            const promise = generator(cancelToken);
            promise.then(
                (value) => {
                    dispatchResult(["resolve", promise, value]);
                },
                (error: unknown) => {
                    // if decodeError is provided, we can make a promise about the error type (see the 3-argument overload),
                    // otherwise the error type remains unknown (see 2-argument overload)
                    const decodedError = decodeError ? decodeError(error) : error;
                    dispatchResult(["reject", promise, decodedError]);
                }
            );

            return { step: "waiting", promise, cancelToken };
        }
        case "resolve": {
            const [{}, loadedPromise, value] = action;
            // ignore a loaded value if it's not for the promise we're paying attention to
            if (state.step == "waiting" && state.promise == loadedPromise) {
                return { step: "fulfilled", value };
            } else {
                return state;
            }
        }
        case "reject": {
            const [{}, loadedPromise, error] = action;
            // ignore an error if it's not for the promise we're paying attention to
            if (state.step == "waiting" && state.promise == loadedPromise) {
                return { step: "rejected", error };
            } else {
                return state;
            }
        }
        case "forget":
            // cancel any already-inflight promise
            if (state.step == "waiting") {
                state.cancelToken.cancel();
            }

            return { step: "idle" };
        case "dismissError":
            // the "dismiss error" operation only makes sense if we are in an error state
            if (state.step == "rejected") {
                return { step: "idle", wasRejected: true };
            } else {
                return state;
            }
        case "update": {
            return PatchPromise(state, () => action[1]);
        }
    }
}

export type PromiseStateSet<T, E = unknown> = [string, PromiseState<T, E>][];
export const EMPTY_PROMISE_SET: PromiseStateSet<never, never> = [];

export function SumPromiseSetRequestsInFlight(set: PromiseStateSet<unknown>): number {
    return SumRequestsInFlight(set.map((entry) => entry[1]));
}

export function PromiseSetErrors<E = unknown>(set: PromiseStateSet<unknown, E>): [string, E][] {
    return set.flatMap(([key, state]) => (state.step == "rejected" ? [[key, state.error]] : []));
}

export function ReducedPromiseSetAndMatch<T, E>(
    set: PromiseStateSet<T, E>,
    key: string,
    action: PromiseAction<T, E>,
    decodeError: (error: unknown) => E
): [PromiseStateSet<T, E>, PromiseState<T, E>] {
    const promiseRecord = set.find(([entryKey]) => entryKey == key)?.[1] ?? IDLE;

    const updatedPromiseRecord = ReducePromise(promiseRecord, action, decodeError);

    // remove old promise entry
    const newSet = set.filter(([entryKey]) => entryKey != key);

    // re-add entry if it's still "interesting";
    // idle promises: can be re-created on demand
    // fulfilled promises: might be worth retaining later, but that requires more design work
    if (updatedPromiseRecord.step != "idle" && updatedPromiseRecord.step != "fulfilled") {
        newSet.unshift([key, updatedPromiseRecord]);
    }

    return [newSet, updatedPromiseRecord];
}

export function ReducePromiseSet<T, E>(
    set: PromiseStateSet<T, E>,
    key: string,
    action: PromiseAction<T, E>,
    decodeError: (error: unknown) => E
): PromiseStateSet<T, E> {
    return ReducedPromiseSetAndMatch(set, key, action, decodeError)[0];
}
