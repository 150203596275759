/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { NeutralLink } from "../DesignSystem/Links";
import { UUID } from "../Utils/Types";

export function fileDetailsPagePath(fileId: UUID): string {
  return `files/details?fileId=${fileId}`;
}

export const FileDetailsLink = ({
  fileId,
  fileName,
}: {
  fileId: UUID;
  fileName: string;
}): JSX.Element => {
  return (
    <NeutralLink to={fileDetailsPagePath(fileId)} displayName={fileName} />
  );
};

export function modelDetailsEditPagePath(modelId: UUID): string {
  return `models/details/edit?modelId=${modelId}`;
}

export function modelDetailsCreatePagePath(): string {
  return `models/details/create`;
}

export const ModelDetailsLink = ({
  modelId,
  modelName,
}: {
  modelId: UUID;
  modelName: string;
}): JSX.Element => {
  return (
    <NeutralLink
      to={modelDetailsEditPagePath(modelId)}
      displayName={modelName}
    />
  );
};
