/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
export default `mutation RescanModelFiles($modelId: UUID!) {
  rescanModelFiles(modelId: $modelId) {
    updatedModel {
      id,
      creationDate,
      lastModifiedDate,
      metadata {
        title,
        version,
        description,
        subject,
        keywords,
        notes,
        customProps,
        content
      },
      fileFolders {
        path
        files {
          __typename
          ... on Redaction {
            message
          }
          ... on ModelFile {
            fileId
            fileName
            metadataScan {
              id
              scanType {
                mimeType
                extension
              }
              scanResult
              metadata {
                title
                version
                description
                subject
                keywords
                notes
                customProps
                content
              }
            }
            securityMarkings {
              classificationLevel
              compartments {
                classificationLevel
                agencyToCompartments
              }
            }
          }
        }
      },
    }
    hasChanged
  }
}`;
