/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { ApolloClient, ApolloProvider, createHttpLink } from "@apollo/client";
import { createContext, PropsWithChildren, useMemo } from "react";
import { SimorAuth } from "./Auth";
import { AuthenticatedUser, User } from "Api";
import axios from "axios";
import { cache } from "../GlobalState";

export const SimorAuthContext = createContext(new SimorAuth(null, false));

export const AxiosContext = createContext(axios.create());

const activeUser = getStorageActiveUser();
const wasPreviouslyAuthorized = isAuthValid();

export function AuthProvider({
  children,
}: PropsWithChildren<any>): JSX.Element {
  const [user, setUser] = React.useState<AuthenticatedUser>(
    wasPreviouslyAuthorized ? activeUser : null
  );

  const handleUpdateUser = React.useCallback(
    (newUser: AuthenticatedUser) => {
      setStorageActiveUser(newUser);
      setUser(newUser);
    },
    [setUser]
  );

  const [authToken, setAuthToken] = React.useState<string>();
  const handleUpdateAuthToken = React.useCallback(
    (newAuthToken: string, expiresAtUtcMillis?: number) => {
      setAuthToken(newAuthToken);
      setStorageAuthExpirationTime(expiresAtUtcMillis);
    },
    [setAuthToken]
  );

  const managedAuth = useMemo(() => {
    return new SimorAuth(
      user,
      authToken ? true : false,
      handleUpdateUser,
      handleUpdateAuthToken
    );
  }, [user, authToken, handleUpdateUser, handleUpdateAuthToken]);

  const managedAxios = useMemo(() => {
    const authHeaders = authToken
      ? {
          Authorization: `Bearer ${authToken}`,
        }
      : null;
    return axios.create({
      headers: authHeaders,
    });
  }, [authToken]);

  React.useEffect(() => {
    if (wasPreviouslyAuthorized) {
      managedAuth.refreshAuthToken();
    }
    // Run with an empty dependencies array to ensure this only runs once
    // to load the expected state from local storage (if auth should be valid).

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gqlHttpLink = createHttpLink({
    uri: "/graphql",
    credentials: "include",
    headers: {
      authorization: authToken ? `Bearer ${authToken}` : "",
    },
  });

  const client = useMemo(
    () =>
      new ApolloClient({
        link: gqlHttpLink,
        cache: cache,
      }),
    [gqlHttpLink]
  );

  return (
    <SimorAuthContext.Provider value={managedAuth}>
      <AxiosContext.Provider value={managedAxios}>
        <ApolloProvider client={client}>{children}</ApolloProvider>
      </AxiosContext.Provider>
    </SimorAuthContext.Provider>
  );
}

function setStorageActiveUser(user: User): void {
  if (user) {
    localStorage.setItem("activeUser", JSON.stringify(user));
  } else {
    localStorage.removeItem("activeUser");
  }
}

function getStorageActiveUser(): AuthenticatedUser {
  const activeUserAsText = localStorage.getItem("activeUser");
  const user = activeUserAsText
    ? (JSON.parse(activeUserAsText) as AuthenticatedUser)
    : undefined;
  return user;
}

// Auth is not stored in local storage; it set in an http only cookie.
// Local storage is used to give you a reasonable guess about
// whether or not you should expect to be able to re-establish
// an authenticated session.
function isAuthValid(): boolean {
  const authExpirationTime = getStorageAuthExpirationTime();
  if (!authExpirationTime) {
    return false;
  }

  return authExpirationTime - Date.now() > 0;
}

function setStorageAuthExpirationTime(expiresAtUtcMillis?: number): void {
  if (expiresAtUtcMillis) {
    localStorage.setItem("authExpirationTime", expiresAtUtcMillis.toString());
  } else {
    localStorage.removeItem("authExpirationTime");
  }
}

function getStorageAuthExpirationTime(): number | null {
  const authExpirationTimeText = localStorage.getItem("authExpirationTime");
  if (!authExpirationTimeText) {
    return undefined;
  }
  const sessionExpirationTime = parseInt(authExpirationTimeText);

  if (sessionExpirationTime == NaN) {
    return undefined;
  }

  return sessionExpirationTime;
}
