/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { FormControl } from "baseui/form-control";
import { Select, Option } from "baseui/select";
import {
  ClassificationLevel,
  CompartmentMarking,
  SecurityMarkings,
} from "../Api/Api";
import { classificationAsText } from "../Api/ApiSerialization";
import {
  EndAnchoredRow,
  Row,
  DirectionalStack,
} from "../DesignSystem/Containers";
import { LabelSmall } from "baseui/typography";
import { Button } from "baseui/button";
import { removeBy, replaceBy } from "../Utils/Array";
import { Delete } from "baseui/icon";
import { KmButtons } from "../DesignSystem/KmButtons";
import { primitives } from "../DesignSystem/LightTheme";
import { useReactiveVar } from "@apollo/client";
import { isSensitiveSecurityContextVar } from "../GlobalState";
import { SimorAuthContext } from "../Utils/AuthContext";
import {
  ClassificationComboOption,
  getClassificationComboOptions,
} from "./Security";

export const SecurityMarkingsEditor = ({
  securityMarkings,
  onMarkingsChanged,
}: {
  securityMarkings: SecurityMarkings;
  onMarkingsChanged?: (newMarkings: SecurityMarkings) => any;
}): JSX.Element => {
  const handleMarkingsChanged = React.useCallback(
    (propertyName, newValue) => {
      if (!onMarkingsChanged) {
        return;
      }
      const newMarkings: SecurityMarkings = {
        ...securityMarkings,
      };
      if (newValue) {
        newMarkings[propertyName] = newValue;
      } else {
        delete newMarkings[propertyName];
      }
      onMarkingsChanged(newMarkings);
    },
    [securityMarkings, onMarkingsChanged]
  );

  return (
    <Row style={{ width: "100rem" }} gap={"10rem"}>
      <div style={{ width: "12rem" }}>
        <ClassificationLevelEditor
          securityMarkings={securityMarkings}
          onClassificationChanged={(value) =>
            handleMarkingsChanged("classificationLevel", value)
          }
        />
      </div>
      <div style={{ width: "40rem" }}>
        <CompartmentListEditor
          compartments={securityMarkings?.compartments}
          onCompartmentsChanged={(value) => {
            handleMarkingsChanged("compartments", value);
          }}
        />
      </div>
    </Row>
  );
};

export const ClassificationLevelEditor = ({
  securityMarkings,
  onClassificationChanged,
}: {
  securityMarkings: SecurityMarkings;
  onClassificationChanged?: (newValue: ClassificationLevel) => void;
}): JSX.Element => {
  const isDefaultSecurityContext = useReactiveVar(
    isSensitiveSecurityContextVar
  );
  const simorAuth = React.useContext(SimorAuthContext);
  const classificationOptions = React.useMemo(() => {
    return getClassificationComboOptions(
      isDefaultSecurityContext,
      simorAuth.user?.securityInfo?.clearanceLevel
    );
  }, [isDefaultSecurityContext, simorAuth.user?.securityInfo?.clearanceLevel]);

  // TODO: This feels like a lot of code for what it's doing.
  // There is probably a simpler way.
  const classificationOptionMatch = classificationOptions.find(
    (option) => option.id === securityMarkings?.classificationLevel
  );
  const selectedClassificationOption: Option[] = classificationOptionMatch
    ? [classificationOptionMatch]
    : [];

  return (
    <FormControl label={() => "Classification"}>
      <Select
        options={classificationOptions}
        labelKey="label"
        valueKey="id"
        onChange={(value) =>
          onClassificationChanged(value?.option?.id as ClassificationLevel)
        }
        value={selectedClassificationOption}
      />
    </FormControl>
  );
};

function createDefaultCompartment(
  classificationLevel: ClassificationLevel
): CompartmentMarking {
  const agencyToCompartments: Map<string, string[]> = new Map();
  agencyToCompartments["AEA"] = [];
  return {
    classificationLevel: classificationLevel,
    agencyToCompartments: agencyToCompartments,
  };
}

const CompartmentListEditor = ({
  compartments,
  onCompartmentsChanged,
}: {
  compartments: CompartmentMarking[];
  onCompartmentsChanged: (newCompartments: CompartmentMarking[]) => void;
}): JSX.Element => {
  const isSensitiveSecurityContext = useReactiveVar(
    isSensitiveSecurityContextVar
  );
  const simorAuth = React.useContext(SimorAuthContext);

  const missingClassificationOptions = React.useMemo<
    ClassificationComboOption[]
  >(() => {
    const assignedCompartments =
      compartments?.map((value) => value.classificationLevel) ?? [];
    return getClassificationComboOptions(
      isSensitiveSecurityContext,
      simorAuth.user?.securityInfo?.clearanceLevel
    ).filter(
      (option) => !assignedCompartments.includes(option.classificationLevel)
    );
  }, [
    compartments,
    isSensitiveSecurityContext,
    simorAuth.user?.securityInfo?.clearanceLevel,
  ]);
  const initialSelection = React.useMemo<ClassificationComboOption[]>(() => {
    return missingClassificationOptions?.length > 0
      ? [missingClassificationOptions[0]]
      : [];
  }, [missingClassificationOptions]);
  const [selectedClassification, setSelectedClassification] =
    React.useState<ClassificationComboOption[]>(initialSelection);

  const handleAddCompartment = React.useCallback(() => {
    const addedOption = selectedClassification[0];

    const updatedCompartments = compartments ? [...compartments] : [];
    const newCompartment = createDefaultCompartment(
      addedOption.classificationLevel
    );
    updatedCompartments.push(newCompartment);
    onCompartmentsChanged(updatedCompartments);

    const remainingOptions = removeBy(
      missingClassificationOptions,
      [addedOption],
      (option) => option.classificationLevel
    );
    const newSelection =
      remainingOptions?.length > 0 ? [remainingOptions[0]] : [];
    setSelectedClassification(newSelection);
  }, [
    selectedClassification,
    compartments,
    onCompartmentsChanged,
    setSelectedClassification,
    missingClassificationOptions,
  ]);

  const handleCompartmentChange = React.useCallback(
    (isDelete, compartment) => {
      const updatedCompartments = isDelete
        ? removeBy(
            compartments,
            [compartment],
            (compartment) => compartment.classificationLevel
          )
        : replaceBy(
            compartments,
            [compartment],
            (compartment) => compartment.classificationLevel
          );
      onCompartmentsChanged(updatedCompartments);
    },
    [compartments, onCompartmentsChanged]
  );

  return (
    <DirectionalStack>
      <EndAnchoredRow>
        <LabelSmall $style={{ paddingTop: "1rem" }}>
          {"Compartments"}
        </LabelSmall>
        <Row>
          <div style={{ width: "12rem" }}>
            <Select
              options={missingClassificationOptions}
              multi={false}
              labelKey="label"
              valueKey="id"
              onChange={(change) =>
                setSelectedClassification(
                  change.value as ClassificationComboOption[]
                )
              }
              value={selectedClassification}
            />
          </div>
          <Button
            kind="tertiary"
            disabled={selectedClassification?.length == 0}
            onClick={handleAddCompartment}
          >
            {"Add"}
          </Button>
        </Row>
      </EndAnchoredRow>
      {compartments?.map((compartment) => (
        <CompartmentEditor
          key={compartment.classificationLevel}
          compartmentName={"AEA"}
          onChange={handleCompartmentChange}
          compartment={compartment}
        />
      ))}
    </DirectionalStack>
  );
};

const COMPARTMENT_COMBO_OPTIONS: Option[] = [
  {
    id: "Walnuts",
    label: "Walnuts",
  },
  {
    id: "Pistachios",
    label: "Pistachios",
  },
  {
    id: "Peanuts",
    label: "Peanuts",
  },
  {
    id: "Almonds",
    label: "Almonds",
  },
  {
    id: "Cashews",
    label: "Cashews",
  },
];

// TODO FUTURE: If we decide to continue with this editor component,
// This should be migrated to just edit a single value line and not have access
// to the CompartmentMarking (which has all the value lines). This will make
// the component much easier to understand and use.
const CompartmentEditor = ({
  compartment,
  compartmentName,
  onChange,
}: {
  compartment: CompartmentMarking;
  compartmentName: string;
  onChange: (isDelete: boolean, compartment?: CompartmentMarking) => void;
}): JSX.Element => {
  const selectedCompartmentOptions = COMPARTMENT_COMBO_OPTIONS.filter(
    (option) =>
      compartment.agencyToCompartments[compartmentName].includes(option.id)
  );
  const isSensitiveSecurityContext = useReactiveVar(
    isSensitiveSecurityContextVar
  );
  const handleComboSelection = React.useCallback(
    (comboSelections) => {
      const selectedCompartments = comboSelections.map((item) => item.id);
      const agencyToCompartments: Map<string, string[]> = new Map<
        string,
        string[]
      >();
      agencyToCompartments[compartmentName] = selectedCompartments;

      const newCompartment: CompartmentMarking = {
        ...compartment,
        agencyToCompartments: agencyToCompartments,
      };
      onChange(false, newCompartment);
    },
    [compartment, compartmentName, onChange]
  );

  const handleDelete = React.useCallback(() => {
    onChange(true, compartment);
  }, [compartment, onChange]);

  return (
    <DirectionalStack>
      <EndAnchoredRow
        style={{
          height: "2.5rem",
          backgroundColor: primitives.subtleBackground,
        }}
      >
        <LabelSmall
          $style={{
            padding: "0.75rem 0rem 0rem 0.75rem",
          }}
        >
          {classificationAsText(
            compartment.classificationLevel,
            isSensitiveSecurityContext
          )}
        </LabelSmall>
        <Button
          kind="tertiary"
          onClick={handleDelete}
          overrides={KmButtons.style.subtle}
        >
          <Delete size={24} />
        </Button>
      </EndAnchoredRow>

      <EndAnchoredRow>
        <LabelSmall $style={{ paddingTop: "1rem" }}>{"NUTS"}</LabelSmall>
        <div style={{ width: "35rem" }}>
          <Select
            options={COMPARTMENT_COMBO_OPTIONS}
            multi={true}
            labelKey="label"
            valueKey="id"
            onChange={(changeParams) =>
              handleComboSelection(changeParams.value)
            }
            value={selectedCompartmentOptions}
          />
        </div>
      </EndAnchoredRow>
    </DirectionalStack>
  );
};
