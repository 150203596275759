/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { SimorAuthContext } from "./AuthContext";

export function RestrictedPage(): JSX.Element {
  const simorAuth = React.useContext(SimorAuthContext);

  return (
    <>
      {simorAuth.isAuthorized() ? (
        <AccessGrantedMessage />
      ) : (
        <>{"Access denied, please login"}</>
      )}
    </>
  );
}

const AccessGrantedMessage = (): JSX.Element => {
  const simorAuth = React.useContext(SimorAuthContext);
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {`Welcome, ${simorAuth.user.username}`}
    </div>
  );
};
