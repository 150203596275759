/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { Switch, Route, Link, useRouteMatch } from "react-router-dom";

import { BasewebKmTypography } from "../DesignSystem/BasewebKmTypography";
import { PageNotFound } from "../Utils/PageNotFound";
import createTestModels from "../Api/Gql/CreateTestModels";

import { WidgetZoo } from "./WidgetZoo";
import { Button } from "baseui/button";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useState } from "react";
import { CSVLink } from "react-csv";
import { SiteContext } from "../Utils/SiteProps";
import { AxiosContext } from "../Utils/AuthContext";
import { DatabaseTools } from "./DatabaseTools";
import { Input } from "baseui/input";
import { Radio, RadioGroup } from "baseui/radio";
import { LabelLarge } from "baseui/typography";
import { notify } from "../Shared/Notify";
import { handleApolloError } from "../Shared/Errors";
import { FileSize, TestModelSpecInput } from "../Api/Api";
import { Spinner } from "baseui/spinner";

const menuHeadingStyle: React.CSSProperties = {
  ...BasewebKmTypography.standard.heading1,
};

export function DevTools(): JSX.Element {
  const match = useRouteMatch();
  const siteContext = React.useContext(SiteContext);
  const axiosContext = React.useContext(AxiosContext);

  // this is really the response message, not the secret
  const [secret, setSecret] = useState("");
  const getSecret = React.useCallback(() => {
    axiosContext
      .get("/authRequired")
      .then((authRequiredResponse) => {
        setSecret(authRequiredResponse.data);
      })
      .catch((error) => {
        setSecret(error.message);
      });
  }, [axiosContext]);

  const GET_SECRET_QUERY = gql(`query {
    getSecret
  }`);
  const [getSecretGql, { loading, error, data }] = useLazyQuery(
    GET_SECRET_QUERY,
    {
      fetchPolicy: "network-only",
    }
  );
  function SecretGql(): JSX.Element {
    function handleSecretGql(): void {
      getSecretGql();
    }
    return (
      <>
        <Button onClick={handleSecretGql}>Get secret gql</Button>
        {loading ? (
          <div>Loading</div>
        ) : error ? (
          "Error, auth required!"
        ) : data ? (
          data.getSecret
        ) : (
          ""
        )}
      </>
    );
  }

  return (
    <React.Fragment>
      <div>
        <div style={menuHeadingStyle}>Development Tools</div>
        <nav>
          <ul>
            <li>
              <Link to="/">Spa Home</Link>
            </li>
            <li>
              <a href={`${siteContext.topUrl}/playground`}>
                GraphQL playground
              </a>
            </li>
            <li>
              <Link to={`${match.url}/about`}>About</Link>
            </li>
            <li>
              <Link to={`/files`}>Files</Link>
            </li>
            <li>
              <Link to={`${match.url}/databaseTools`}>Database Tools</Link>
            </li>
            <li>
              <Link to={`${match.url}/widgetzoo`}>Widgets</Link>
            </li>
            <li>
              <Link to={`${match.url}/restrictedEndpoint`}>
                Restricted REST Endpoint
              </Link>
            </li>
            <li>
              <Link to={`${match.url}/restrictedGQL`}>
                Restricted GQL query
              </Link>
            </li>
            <li>
              <Link to={"/restricted"}>Restricted page</Link>
            </li>
            <li>
              <Link to={`${match.url}/generateTestFiles`}>
                Generate Test Files
              </Link>
            </li>
            <li>
              <Link to={`${match.url}/generateSampleMDF`}>
                Generate Sample MDF File
              </Link>
            </li>
          </ul>
        </nav>
      </div>
      {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
      <Switch>
        <Route path={`${match.path}/about`}>
          <About />
        </Route>
        <Route path={`${match.path}/generateTestFiles`}>
          <GenerateTestFiles />
        </Route>
        <Route path={`${match.path}/generateSampleMDF`}>
          <GenerateSampleMDF />
        </Route>
        <Route path={`${match.path}/databaseTools`}>
          <DatabaseTools />
        </Route>
        <Route path={`${match.path}/widgetzoo`}>
          <WidgetZoo />
        </Route>
        <Route path={`${match.path}/restrictedEndpoint`}>
          <>
            <Button onClick={getSecret}>Get secret</Button>
            {secret ? secret : "Click to reveal"}
          </>
        </Route>
        <Route path={`${match.path}/restrictedGQL`}>
          <SecretGql />
        </Route>
        {/* Must be the last path element to prevent it from matching
                descendent paths */}
        <Route exact path={match.path}>
          <DevToolsHome />
        </Route>
        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>
    </React.Fragment>
  );
}

function DevToolsHome(): JSX.Element {
  return <h2>Dev Tools Home</h2>;
}

function About(): JSX.Element {
  const siteContext = React.useContext(SiteContext);
  return <h2>About: {siteContext.topUrl}</h2>;
}

function GenerateSampleMDF(): JSX.Element {
  const data = [
    { FileName: "SampleData1.xml", Date: "4/24/2022" },
    { FileName: "SampleData2.xml", Date: "4/24/2022" },
    { FileName: "SampleData3.xml", Date: "4/24/2022" },
    { FileName: "SampleData4.xml", Date: "4/24/2022" },
    { FileName: "SampleData5.xml", Date: "4/24/2022" },
    { FileName: "SampleData6.xml", Date: "4/24/2022" },
    { FileName: "SampleData7.xml", Date: "4/24/2022" },
    { FileName: "SampleData8.xml", Date: "4/24/2022" },
    { FileName: "SampleData9.xml", Date: "4/24/2022" },
    { FileName: "SampleData10.xml", Date: "4/24/2022" },
    { FileName: "SampleData11.xml", Date: "4/24/2022" },
  ];

  return (
    <>
      <h2 style={{ paddingLeft: "1rem", paddingBottom: "1rem" }}>
        Generate MDF
      </h2>
      <Button $style={{ width: "10rem", marginLeft: "2rem" }}>
        <CSVLink
          data={data}
          filename={"MDF.csv"}
          className="btn btn-primary"
          target="_blank"
          style={{ color: "white", textDecoration: "none" }}
        >
          Download MDF
        </CSVLink>
      </Button>
    </>
  );
}

function GenerateTestFiles(): JSX.Element {
  const [modelCount, setModelCount] = React.useState(10);
  const [fileSize, setFileSize] = React.useState<FileSize>("SMALL");

  const [createTestModelsMutation, { loading }] = useMutation(
    gql(createTestModels),
    {
      onCompleted: (data: { createTestModels: number }) => {
        notify.positive(`Created ${data.createTestModels} new models`);
      },
      onError: (error) => {
        handleApolloError(error, "An error occured while generating models");
      },
    }
  );

  const handleGenerate = React.useCallback(() => {
    const modelSpec: TestModelSpecInput = {
      modelCount: modelCount,
      fileSize: fileSize,
    };
    createTestModelsMutation({
      variables: { modelSpec: modelSpec },
    });
  }, [modelCount, fileSize, createTestModelsMutation]);

  if (loading) {
    return (
      <>
        <LabelLarge>{"Creating models..."}</LabelLarge>
        <Spinner />
      </>
    );
  }

  return (
    <>
      <h2 style={{ paddingLeft: "1rem", paddingBottom: "1rem" }}>
        Generate Test Files
      </h2>
      <div style={{ paddingLeft: "2rem" }}>
        <LabelLarge>Number of files</LabelLarge>
        <Input
          value={modelCount}
          type="number"
          min={1}
          onChange={(event) =>
            setModelCount(parseInt(event.currentTarget.value))
          }
          placeholder="Number of models..."
          size="compact"
          clearable
          clearOnEscape
        />
        <LabelLarge $style={{ Top: "1rem" }}>File Sizes: </LabelLarge>
        <RadioGroup
          align="horizontal"
          name="horizontal"
          onChange={(e) => setFileSize(e.target.value as FileSize)}
          value={fileSize}
        >
          <Radio value="SMALL">Small</Radio>
          <Radio value="MEDIUM">Medium</Radio>
          <Radio value="LARGE">Large</Radio>
          <Radio value="EXTRA_LARGE">Extra Large</Radio>
        </RadioGroup>
        <Button $style={{ width: "10rem" }} onClick={handleGenerate}>
          Generate
        </Button>
      </div>
    </>
  );
}
