/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { ClearedModelFile, FileInfo, MetadataScan, NewFileInfo } from "Api";
import { CancelTokenSource } from "axios";

export enum UploadStatus {
  IN_PROGRESS,
  SUCCESSFUL,
  FAILED,
}

export interface FileUpload {
  status: UploadStatus;
  info: NewFileInfo;
  metadataScan?: MetadataScan;
  cancelToken?: CancelTokenSource;
  redacted?: boolean;
}

export interface FilePath {
  path: string;
  file: NewFileInfo;
}

export function toClearedModelFileUpload(file: ClearedModelFile): FileUpload {
  switch (file.__typename) {
    case "Redaction":
      return {
        status: UploadStatus.SUCCESSFUL,
        info: { id: null, name: null },
        redacted: true,
      };
    case "ModelFile":
      return {
        status: UploadStatus.SUCCESSFUL,
        info: { id: file.fileId, name: file.fileName },
        metadataScan: file.metadataScan,
        redacted: false,
      };
    default:
      const _exhaustiveCheck: never = file;
      return _exhaustiveCheck;
  }
}

export function toFileInfoUpload(fileInfo: FileInfo): FileUpload {
  const metadataScans = fileInfo.metadataScans as MetadataScan[];
  const fileScan = metadataScans?.length > 0 ? metadataScans[0] : null;
  const finishedFileUpload: FileUpload = {
    status: UploadStatus.SUCCESSFUL,
    metadataScan: fileScan,
    info: {
      id: fileInfo.id,
      name: fileInfo.attributes?.name,
    },
    cancelToken: null,
  };
  return finishedFileUpload;
}

export const toFileUploadFailure = (failedUpload: NewFileInfo): FileUpload => {
  const failedFileUpload: FileUpload = {
    status: UploadStatus.FAILED,
    info: {
      id: failedUpload.id,
      name: failedUpload.name,
    },
    cancelToken: null,
  };
  return failedFileUpload;
};
