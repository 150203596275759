/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import React, { CSSProperties, useCallback } from "react";
import { useContext } from "react";
import { ProjectContext } from "../UserApp";
import { DividedListNodeProps } from "./DividedListNode";
import {
  ModifiableDividedListNodeComponent,
  ModifiableDividedListNodeEntryProps,
} from "./ModifiableDividedListNode";
import { WORKBENCH_NODE_PADDING } from "./WorkbenchNode";

export const JAVASCRIPT_NODE_TEXTAREA_CONTAINER_STYLE: CSSProperties = {
  padding: WORKBENCH_NODE_PADDING + "px",
};

export function JavaScriptNodeComponent(
  props: DividedListNodeProps<ModifiableDividedListNodeEntryProps>
): JSX.Element {
  const { projectDispatch } = useContext(ProjectContext);
  const nodeData = props.data;

  // dispatch changes to the project
  const modifySupplementalData = useCallback(
    (modifiedSupplementalData: string) => {
      const modifiedNodeData = {
        ...nodeData,
        supplementalData: modifiedSupplementalData,
      };
      projectDispatch(["modifyNodeData", { modifiedNodeData }]);
    },
    // projectDispatch is guaranteed to be stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nodeData]
  );

  return ModifiableDividedListNodeComponent({
    ...props,
    subtitle: (
      <div
        style={{
          width: "550px",
          marginBottom: "5px",
          paddingRight: "5px",
        }}
      >
        {
          "Type any JavaScript code into the text area. Any input or output handle can be treated as a variable, if all whitespace characters in the label are replaced with underscores."
        }
      </div>
    ),
    centerComponent: (
      <div style={JAVASCRIPT_NODE_TEXTAREA_CONTAINER_STYLE}>
        <textarea
          name="ScriptArea"
          cols={40}
          rows={5}
          autoFocus
          defaultValue={nodeData.supplementalData ?? ""}
          onChange={(e) => modifySupplementalData(e.target.value)}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
          className="nodrag"
        ></textarea>
      </div>
    ),
  });
}
