/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { PLACEMENT, ToasterContainer } from "baseui/toast";

import { useApolloClient } from "@apollo/client";
import { HeaderBar } from "./Header/HeaderBar";
import { Workbench } from "./Workbench/Workbench";
import { DevTools } from "./DevTools/DevTools";
import { PageNotFound } from "./Utils/PageNotFound";
import { ResourceSearchPage } from "./Model/ResourceSearchPage";
import { UserPreferences } from "./Header/UserPreferences";
import { RestrictedPage } from "./Utils/RestrictedPage";
import { FilesIndex } from "./File/FilesIndex";
import ModelFields from "./CustomModelFields/ModelFields";
import { ModelsIndex } from "./Model/ModelsIndex";
import { ProjectAction, ProjectReducer, ProjectState } from "./ProjectState";
import { SiteContext } from "./Utils/SiteProps";
import { AxiosContext } from "./Utils/AuthContext";
import { useContext, useEffect, useReducer, useRef } from "react";
import { ReactFlowProvider } from "reactflow";

export interface ProjectInfo {
  projectDispatch: React.Dispatch<ProjectAction>;
  projectState: ProjectState;
}

export const ProjectContext = React.createContext<ProjectInfo>({
  projectDispatch: (_value) => {},
  projectState: new ProjectState(),
});

/**
 * The Dexter application that has a user context
 */
export const UserApp = (): JSX.Element => {
  // refs/components that shouldn't change
  const apolloClient = useApolloClient();
  const axiosContext = useContext(AxiosContext);
  const siteProps = useContext(SiteContext);

  // the ProjectReducer is a state machine that manages all state associated with the project
  const [projectState, projectDispatch] = useReducer(
    ProjectReducer,
    null,
    () => new ProjectState()
  );

  // initialize project
  const isActiveRef = useRef(true);
  useEffect(() => {
    isActiveRef.current = true;
    projectDispatch([
      "navigate",
      { apolloClient, axiosContext, siteProps, isActiveRef },
      projectDispatch,
    ]);
    return () => {
      isActiveRef.current = false;
    };
  }, [apolloClient, axiosContext, siteProps]);

  return (
    <Router basename="/">
      <HeaderBar projectInfo={{ projectState, projectDispatch }} />
      <ToasterContainer placement={PLACEMENT.topRight}>
        {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
        <Switch>
          <Route exact path="/">
            <ProjectContext.Provider value={{ projectDispatch, projectState }}>
              <ReactFlowProvider>
                <Workbench />
              </ReactFlowProvider>
            </ProjectContext.Provider>
          </Route>
          <Route path="/devtools">
            <DevTools />
          </Route>
          <Route path="/files">
            <FilesIndex />
          </Route>
          <Route path="/models">
            <ModelsIndex />
          </Route>
          <Route exact path="/userPreferences">
            <UserPreferences />
          </Route>
          <Route exact path="/transformFields">
            <ModelFields />
          </Route>
          <Route exact path="/restricted">
            <RestrictedPage />
          </Route>
          <Route exact path="/repository">
            <ResourceSearchPage />
          </Route>
          <Route path="*">
            <PageNotFound />
          </Route>
        </Switch>
      </ToasterContainer>
    </Router>
  );
};
