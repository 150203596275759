/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { useStyletron } from "baseui";
import * as React from "react";
import { Link, useHistory } from "react-router-dom";
import { StyleObject } from "styletron-react";
import { SiteContext } from "../Utils/SiteProps";

// Link style definitions mirror Baseweb's StyledLink:
// https://github.com/uber/baseweb/blob/master/src/link/styled-components.js
const underlineOnHoverStyle: StyleObject = {
  textDecoration: "none",
  textUnderlinePosition: "under",
  fontSize: "inherit",
  lineHeight: "inherit",
  ":hover": {
    textDecoration: "underline",
  },
};

export const InlineLink = ({
  href,
  linkText,
}: {
  href: string;
  linkText: string;
}): JSX.Element => {
  const [css, theme] = useStyletron();

  return (
    // TODO: See if using a StyledLink could replace the anchor tag.
    <a
      href={href}
      className={css({
        ...underlineOnHoverStyle,
        color: theme.colors.primary,
        ...theme.typography.font250,
      })}
      download
    >
      {linkText}
    </a>
  );
};

export const NeutralLink = ({
  to,
  displayName,
}: {
  to: string;
  displayName: string;
}): JSX.Element => {
  const [css, theme] = useStyletron();
  const history = useHistory();
  const siteProps = React.useContext(SiteContext);
  return (
    // Setting the previous page in the below onClick is too specific to be included in this component.
    // This should be passed in through an onClick property.
    <Link
      to={to}
      onClick={() => siteProps.setPreviousPage(history.location.pathname)}
      className={css({
        ...underlineOnHoverStyle,
        color: theme.colors.black,
        ...theme.typography.font350,
      })}
    >
      {displayName}
    </Link>
  );
};
