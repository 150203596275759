/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import React, { useState, useMemo } from "react";
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import { FileInfo } from "Api";
import { sortFileInfo } from "../Api/FileInfo";
import { FileDetailsLink } from "../Shared/NavigationLinks";
import {
  fileUploadDateAsText,
  mbsSizeAsText,
  classificationAsText,
} from "../Api/ApiSerialization";
import { isSensitiveSecurityContextVar } from "../GlobalState";
import { useReactiveVar } from "@apollo/client";

const RightAlignedHeader = ({
  headerText,
}: {
  headerText: string;
}): JSX.Element => {
  return (
    <div style={{ position: "absolute", right: "28px" }}>{headerText}</div>
  );
};

const searchResultTableStyle: React.CSSProperties = {
  paddingTop: "80px",
  width: "80%",
  margin: "auto",
};

export interface FileSearchResultTableProps {
  files: FileInfo[];
}

export default function SearchResultTable({
  files,
}: FileSearchResultTableProps): JSX.Element {
  const [sortColumn, setSortColumn] = useState("uploadDate");
  const [sortAsc, setSortAsc] = useState(true);

  const sortedData = useMemo(() => {
    return sortFileInfo(files, sortAsc, sortColumn);
  }, [files, sortColumn, sortAsc]);

  function handleSort(id: string): void {
    if (id === sortColumn) {
      setSortAsc((asc) => !asc);
    } else {
      setSortColumn(id);
      setSortAsc(true);
    }
  }

  const isSensitiveSecurityContext = useReactiveVar(
    isSensitiveSecurityContextVar
  );

  return (
    <div style={searchResultTableStyle}>
      <TableBuilder
        data={sortedData}
        sortColumn={sortColumn}
        sortOrder={sortAsc ? "ASC" : "DESC"}
        onSort={handleSort}
      >
        <TableBuilderColumn id="name" header="File Name" sortable>
          {(fileInfo: FileInfo) => (
            <FileDetailsLink
              fileId={fileInfo.id}
              fileName={fileInfo.attributes.name}
            />
          )}
        </TableBuilderColumn>
        <TableBuilderColumn
          id="classificationLevel"
          header="Classification"
          sortable
        >
          {(fileInfo: FileInfo) => (
            <div data-testid={`${fileInfo.id}-classificationLevel`}>
              {classificationAsText(
                fileInfo.securityMarkings?.classificationLevel,
                isSensitiveSecurityContext
              )}
            </div>
          )}
        </TableBuilderColumn>
        <TableBuilderColumn
          id="uploadDateUtc"
          header={<RightAlignedHeader headerText="Upload Date" />}
          sortable
          numeric
        >
          {(fileInfo: FileInfo) => (
            <div data-testid={`${fileInfo.id}-uploadDate`}>
              {fileUploadDateAsText(fileInfo)}
            </div>
          )}
        </TableBuilderColumn>
        <TableBuilderColumn
          id="sizeMbs"
          header={<RightAlignedHeader headerText="File Size" />}
          sortable
          numeric
        >
          {(fileInfo: FileInfo) => (
            <div data-testid={`${fileInfo.id}-fileSize`}>
              {mbsSizeAsText(fileInfo.attributes.sizeMbs)}
            </div>
          )}
        </TableBuilderColumn>
      </TableBuilder>
    </div>
  );
}
