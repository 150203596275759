/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import { NodeDescriptor } from "Api";
import { Connection, Edge, Node, Position } from "reactflow";
import "reactflow/dist/style.css";
import { Path, PathType } from "../Api/DataTransformation";
import { NumberBucket } from "../Utils/Types";
import {
  createEntryForPath,
  createHandlesForEntry,
  lastPathElement,
  modifyPathEndpoint,
  pathParent,
  pathStartsWith,
} from "./NodeDescriptorUtil";

export type WorkbenchNodeEntryId = number & { __type: "WorkbenchNodeEntryId" };
export type WorkbenchNodeHandleId = number & {
  __type: "WorkbenchNodeHandleId";
};

export interface WorkbenchNodeEntry {
  id: WorkbenchNodeEntryId;
  label?: string;
  originalLabel: boolean;
  labelRange?: string;
  path: Path;
}

export interface WorkbenchNodeHandle {
  id: WorkbenchNodeHandleId;
  entryId?: WorkbenchNodeEntryId;
  path: Path;
  handlePosition?: Position; // if provided, this will override the implied position from the path's pathType
}

export interface WorkbenchNodeData {
  dataID: string; // this must be the same as the node ID
  title?: string;
  titleEditInProgress?: boolean;
  titleInProgress?: string;
  descriptor: NodeDescriptor;
  entries: WorkbenchNodeEntry[];
  handles: WorkbenchNodeHandle[];
  supplementalData?: string;
}

/**
 * TODO: React also support "Sub Flows" which may be a suitable replacement for this
 * See https://reactflow.dev/docs/examples/sub-flows/
 */

export interface ReactFlowNode extends Node<WorkbenchNodeData> {
  id: string;
  type: string;
  position: { x: number; y: number };
  data: WorkbenchNodeData;
}

export type ReactFlowEdge = Edge<any>;

// Returns a unique string segment for this entry. This is used as a key to distinguish React components.
export function getEntryComponentKey(entryId: WorkbenchNodeEntryId): string {
  return `entry-${entryId}`;
}

// Returns a unique string segment for this handle and type. This is used as a key to distinguish React components as well as an ID in ReactFlow.
export function getHandleComponentKey(
  handlePosition: Position,
  handleId: WorkbenchNodeHandleId
): string {
  return `${handlePosition.toLowerCase()}-handle-${handleId}`;
}

// Returns the handle ID used in this string.
export function extractHandleId(
  handleComponentKey: string
): WorkbenchNodeHandleId {
  const pos = handleComponentKey.indexOf("-handle-");
  if (pos >= 0) {
    return Number(
      handleComponentKey.substring(pos + 8)
    ) as WorkbenchNodeHandleId;
  } else {
    return undefined;
  }
}

// Returns the node handle referenced by the ReactFlow handle string
export function getHandleFromString(
  nodeData: WorkbenchNodeData,
  handleString: string
): WorkbenchNodeHandle {
  const handleId = extractHandleId(handleString);
  return findNodeHandle(nodeData, handleId);
}

export function findNodeEntry(
  nodeData: WorkbenchNodeData,
  entryId: WorkbenchNodeEntryId
): WorkbenchNodeEntry {
  return nodeData.entries.find((entry) => entry.id == entryId);
}

export function findNodeHandle(
  nodeData: WorkbenchNodeData,
  handleId: WorkbenchNodeHandleId
): WorkbenchNodeHandle {
  return nodeData.handles.find((handle) => handle.id == handleId);
}

export function findNodeHandleForEntry(
  nodeData: WorkbenchNodeData,
  entryId: WorkbenchNodeEntryId
): WorkbenchNodeHandle {
  return nodeData.handles.find((handle) => handle.entryId == entryId);
}

export function edgeMatches(
  edge: ReactFlowEdge,
  nodeId: string,
  handleId?: WorkbenchNodeHandleId
): boolean {
  return (
    (edge.source &&
      edge.sourceHandle &&
      edge.source == nodeId &&
      (handleId === undefined ||
        extractHandleId(edge.sourceHandle) == handleId)) ||
    (edge.target &&
      edge.targetHandle &&
      edge.target == nodeId &&
      (handleId === undefined ||
        extractHandleId(edge.targetHandle) == handleId))
  );
}

// Returns whether this node is connected to an edge
export function nodeHasEdge(
  allEdges: ReactFlowEdge[],
  nodeId: string
): boolean {
  return allEdges.some((edge) => edgeMatches(edge, nodeId));
}

// Returns whether this handle is connected to an edge
export function handleHasEdge(
  allEdges: ReactFlowEdge[],
  nodeId: string,
  handleId: WorkbenchNodeHandleId
): boolean {
  return allEdges.some((edge) => edgeMatches(edge, nodeId, handleId));
}

// Returns whether this entry is connected to an edge
export function entryHasEdge(
  allEdges: ReactFlowEdge[],
  nodeData: WorkbenchNodeData,
  entryId: WorkbenchNodeEntryId
): boolean {
  const handle = findNodeHandleForEntry(nodeData, entryId);
  if (handle) {
    return handleHasEdge(allEdges, nodeData.dataID, handle.id);
  } else {
    return false;
  }
}

export function getHandlePosition(
  handle: WorkbenchNodeHandle,
  pathType: PathType.INPUT | PathType.OUTPUT
): Position {
  return handle.handlePosition ?? pathType == PathType.INPUT
    ? Position.Left
    : Position.Right;
}

export function isNodeTitleValid(newTitle: string): boolean {
  const re = /^[\w\-. ]+$/;
  return re.test(newTitle);
}

export function modifyNodeData(
  existingNodes: ReactFlowNode[],
  modifiedNodeData: WorkbenchNodeData
): ReactFlowNode[] {
  return existingNodes.map((node: ReactFlowNode) => {
    return node.data.dataID == modifiedNodeData.dataID
      ? { ...node, data: modifiedNodeData }
      : node;
  });
}

export function modifyNodeEntry(
  existingNodeData: WorkbenchNodeData,
  modifiedNodeEntry: WorkbenchNodeEntry
): WorkbenchNodeData {
  return {
    ...existingNodeData,
    entries: existingNodeData.entries.map((entry: WorkbenchNodeEntry) => {
      return entry.id == modifiedNodeEntry.id ? modifiedNodeEntry : entry;
    }),
  };
}

// Shift entries with path endpoint numbers greater than or equal to the reference entry (but not the reference entry itself) by the specified delta.
export function modifyPathEndpoints(
  referenceEntry: WorkbenchNodeEntry,
  entries: WorkbenchNodeEntry[],
  delta: number
): WorkbenchNodeEntry[] {
  const referenceLastElement = lastPathElement(referenceEntry.path);
  if (referenceLastElement?.byNumber == null) {
    return entries;
  }

  return entries.map((entry) => {
    const lastElement = lastPathElement(entry.path);
    if (
      entry.id != referenceEntry.id &&
      entry.path.pathType == referenceEntry.path.pathType &&
      lastElement?.byNumber != null &&
      lastElement.byNumber >= referenceLastElement.byNumber
    ) {
      return { ...entry, path: modifyPathEndpoint(entry.path, delta) };
    } else {
      return entry;
    }
  });
}

export function doChangeTargetLabel(
  existingNodes: ReactFlowNode[],
  edge: ReactFlowEdge
): ReactFlowNode[] {
  const source = existingNodes.find((node) => edge.source == node.id);
  const target = existingNodes.find((node) => edge.target == node.id);

  // only change the label for CSV nodes
  const isTargetCSV =
    target.data.descriptor.id == "41a4cb29-4017-4239-93d8-af41a56136dc";

  if (source && target && isTargetCSV) {
    const sourceHandle = getHandleFromString(source.data, edge.sourceHandle);
    const targetHandle = getHandleFromString(target.data, edge.targetHandle);

    const sourceEntry = findNodeEntry(source.data, sourceHandle.entryId);
    const targetEntry = findNodeEntry(target.data, targetHandle.entryId);

    // only change the label if we have an entry and its label has never been changed
    if (targetEntry?.originalLabel) {
      let label: string = "";

      // we have a source entry
      if (sourceEntry !== undefined) {
        label = sourceEntry.label ?? "";
        if (label == "") {
          // this is probably an attribute, so take it from the path string
          const parentElements = pathParent(sourceEntry.path.pathElements);
          if (parentElements.length > 0) {
            const lastElement = parentElements[parentElements.length - 1];
            if (lastElement.byString && lastElement.byString != "") {
              label = lastElement.byString;
            }
          }
        }
      }
      // no source entry; take it from the source handle
      else {
        const lastElement =
          sourceHandle.path.pathElements[
            sourceHandle.path.pathElements.length - 1
          ];
        if (lastElement.byString && lastElement.byString != "") {
          label = lastElement.byString + " Group";
        }
      }

      const newTargetEntry = {
        ...targetEntry,
        label,
        originalLabel: false,
      };

      const newTarget = {
        ...target,
        data: {
          ...target.data,
          entries: target.data.entries.map((entry) => {
            return entry.id == newTargetEntry.id ? newTargetEntry : entry;
          }),
        },
      };

      const newNodes = existingNodes.map((node) => {
        return node.id == newTarget.id ? newTarget : node;
      });

      return newNodes;
    }
  }

  return existingNodes;
}

// this might not work in all situations, such as if the entry being examined is not the latest one in the range
export function canAddEntries(rangePath: Path, numberToAdd: number): boolean {
  const lastElement = lastPathElement(rangePath);

  // see if there is enough room in the range
  // (if a maxNumber isn't specified, assume we can't add)
  return (
    lastElement.byNumber == null ||
    (lastElement.maxNumber != null &&
      lastElement.byNumber + numberToAdd <= lastElement.maxNumber)
  );
}

export function doCreateEntriesForChunk(
  existingNodes: ReactFlowNode[],
  edge: ReactFlowEdge
): [ReactFlowNode[], Connection[]] {
  // default to no-op
  let nodesToReturn: ReactFlowNode[] = existingNodes;
  let connectionsToCreate: Connection[] = [];

  const source = existingNodes.find((node) => edge.source == node.id);
  const target = existingNodes.find((node) => edge.target == node.id);

  const sourceHandle = getHandleFromString(source.data, edge.sourceHandle);
  const targetHandle = getHandleFromString(target.data, edge.targetHandle);

  const sourcePath = sourceHandle.path;
  const targetPath = targetHandle.path;

  const sourcePosition = getHandlePosition(sourceHandle, PathType.OUTPUT);
  const targetPosition = getHandlePosition(targetHandle, PathType.INPUT);

  const sourceEntry = findNodeEntry(source.data, sourceHandle.entryId);
  const targetEntry = findNodeEntry(target.data, targetHandle.entryId);

  // for now, we need a target entry
  if (targetEntry === undefined) {
    return [nodesToReturn, connectionsToCreate];
  }

  // if the source is an entry, we don't need to make any extra connections
  if (sourceEntry !== undefined) {
    const connection = edge as Connection;
    return [nodesToReturn, [connection]];
  }

  // find any entries that start with this path
  // (we know from the above check that we don't have a sourceEntry at this point)
  const additionalEntries = source.data.entries.filter((entry) =>
    pathStartsWith(entry.path.pathElements, sourcePath.pathElements)
  );

  // add the entries if the destination has room for all of them
  if (canAddEntries(targetPath, additionalEntries.length - 1)) {
    let newTargetNodeData = target.data;
    let _newEntryId: WorkbenchNodeEntryId = undefined;
    let newHandleId: WorkbenchNodeHandleId = undefined;
    let addDelta = 1;

    // create new entries and new connections for each entry
    additionalEntries.forEach((entry, index) => {
      const handle = findNodeHandleForEntry(source.data, entry.id);

      // for the first entry, don't add it, just link the source to the original target entry
      if (index == 0) {
        newHandleId = targetHandle.id;
      }
      // add all subsequent entries
      else {
        [newTargetNodeData, _newEntryId, newHandleId] = addSpecificEntry(
          newTargetNodeData,
          targetEntry.id,
          addDelta++
        );
      }

      const newConnection: Connection = {
        source: source.id,
        target: target.id,
        sourceHandle: getHandleComponentKey(sourcePosition, handle.id),
        targetHandle: getHandleComponentKey(targetPosition, newHandleId),
      };
      connectionsToCreate = connectionsToCreate.concat(newConnection);
    });

    // modify the existing node with the new set of entries
    nodesToReturn = existingNodes.map((node) => {
      if (node.id == target.id) {
        return {
          ...node,
          data: newTargetNodeData,
        };
      } else {
        return node;
      }
    });
  }

  return [nodesToReturn, connectionsToCreate];
}

export function addSpecificEntry(
  oldNodeData: WorkbenchNodeData,
  entryId: WorkbenchNodeEntryId,
  delta: number
): [WorkbenchNodeData, WorkbenchNodeEntryId, WorkbenchNodeHandleId] {
  const entryIdPosition = oldNodeData.entries.findIndex(
    (entry) => entry.id == entryId
  );
  const oldEntry = oldNodeData.entries[entryIdPosition];

  // get a new entry ID number that is bigger than all the existing IDs
  const newEntryIdNumber = oldNodeData.entries.reduce(
    (candidateIdNumber: number, entry: WorkbenchNodeEntry) => {
      return candidateIdNumber > (entry.id as number)
        ? candidateIdNumber
        : (entry.id as number) + 1;
    },
    1
  );

  // same with the handle
  const newHandleIdNumber = oldNodeData.handles.reduce(
    (candidateIdNumber: number, handle: WorkbenchNodeHandle) => {
      return candidateIdNumber > (handle.id as number)
        ? candidateIdNumber
        : (handle.id as number) + 1;
    },
    1
  );

  const number_regex = /[0-9]+$/;
  let newLabel = oldEntry.label ?? "";
  if (number_regex.test(newLabel)) {
    const number_string = newLabel.match(number_regex)[0];
    newLabel = newLabel.slice(0, -number_string.length);

    // get a label that doesn't collide with an existing entry
    let labelNumber = 1;
    let testLabel = "";
    // eslint-disable-next-line no-loops/no-loops
    while (true) {
      testLabel = newLabel + labelNumber.toString();
      if (
        oldNodeData.entries.findIndex(
          (entry) => testLabel == (entry.label ?? "")
        ) < 0
      ) {
        // success; no collision
        break;
      }
      labelNumber++;
    }
    newLabel = testLabel;
  } else {
    newLabel += "+";
  }

  // copy the old item, but give it a new ID and label, and reset the originalLabel flag
  // and also maybe adjust the new item's path endpoint
  const newEntry = {
    ...oldEntry,
    id: newEntryIdNumber as WorkbenchNodeEntryId,
    label: newLabel,
    originalLabel: true,
    path: delta == 0 ? oldEntry.path : modifyPathEndpoint(oldEntry.path, delta),
  };

  // insert the entry
  const firstHalf = oldNodeData.entries.slice(0, entryIdPosition + delta);
  const secondHalf = oldNodeData.entries.slice(entryIdPosition + delta);
  const entriesAfterAdd = firstHalf.concat(newEntry, ...secondHalf);

  // shift all the path endpoints for the entries that are similar to the entry we are adding
  const shiftedEntries = modifyPathEndpoints(newEntry, entriesAfterAdd, +1);

  // add new handles for the new entry
  const handleIdCounter: NumberBucket = { value: newHandleIdNumber };
  const newHandles = createHandlesForEntry(handleIdCounter, newEntry);
  const modifiedHandles = oldNodeData.handles.concat(newHandles);

  // find the one handle corresponding to the actual entry
  const newHandle = newHandles.find((handle) => handle.entryId == newEntry.id);

  const newNodeData = {
    ...oldNodeData,
    entries: shiftedEntries,
    handles: modifiedHandles,
  };

  return [newNodeData, newEntry.id, newHandle.id];
}

export function addBrandNewEntry(
  oldNodeData: WorkbenchNodeData,
  path: Path
): [WorkbenchNodeData, WorkbenchNodeEntryId, WorkbenchNodeHandleId] {
  const existingEntries = oldNodeData.entries;

  // get a new entry ID number that is bigger than all the existing IDs
  const newEntryIdNumber = oldNodeData.entries.reduce(
    (candidateIdNumber: number, entry: WorkbenchNodeEntry) => {
      return candidateIdNumber > (entry.id as number)
        ? candidateIdNumber
        : (entry.id as number) + 1;
    },
    1
  );

  // same with the handle
  const newHandleIdNumber = oldNodeData.handles.reduce(
    (candidateIdNumber: number, handle: WorkbenchNodeHandle) => {
      return candidateIdNumber > (handle.id as number)
        ? candidateIdNumber
        : (handle.id as number) + 1;
    },
    1
  );

  //create entry from scratch
  const newEntry = createEntryForPath(
    oldNodeData.descriptor,
    newEntryIdNumber,
    path
  );

  // add the entry
  const entriesAfterAdd = existingEntries.concat(newEntry);

  // add new handles for the new entry
  const handleIdCounter: NumberBucket = { value: newHandleIdNumber };
  const newHandles = createHandlesForEntry(handleIdCounter, newEntry);
  const modifiedHandles = oldNodeData.handles.concat(newHandles);

  // find the one handle corresponding to the actual entry
  const newHandle = newHandles.find((handle) => handle.entryId == newEntry.id);

  const newNodeData = {
    ...oldNodeData,
    entries: entriesAfterAdd,
    handles: modifiedHandles,
  };

  return [newNodeData, newEntry.id, newHandle.id];
}

export function removeSpecificEntry(
  oldNodeData: WorkbenchNodeData,
  entryId: WorkbenchNodeEntryId
): [WorkbenchNodeData, WorkbenchNodeHandleId] {
  const entryIdPosition = oldNodeData.entries.findIndex(
    (entry) => entry.id == entryId
  );
  if (entryIdPosition < 0) {
    return [undefined, undefined];
  }
  const entryToRemove = oldNodeData.entries[entryIdPosition];
  const handleToRemove = findNodeHandleForEntry(oldNodeData, entryId);

  // don't remove this entry if it will reduce the entries below the minNumber
  if (!isEntryRemovable(oldNodeData, entryId)) {
    return [undefined, undefined];
  }

  // remove the entry
  const firstHalf = oldNodeData.entries.slice(0, entryIdPosition);
  const secondHalf = oldNodeData.entries.slice(entryIdPosition + 1);
  const entriesAfterRemoval = firstHalf.concat(...secondHalf);

  // shift all the path endpoints for the entries that are similar to the entry we removed
  const shiftedEntries = modifyPathEndpoints(
    entryToRemove,
    entriesAfterRemoval,
    -1
  );

  // remove the handle from the data; we will also need to remove the handle from any edges
  const modifiedHandles = oldNodeData.handles.filter(
    (handle) => handle.id != handleToRemove.id
  );

  const newNodeData = {
    ...oldNodeData,
    entries: shiftedEntries,
    handles: modifiedHandles,
  };

  return [newNodeData, handleToRemove.id];
}

export function isEntryRemovable(
  nodeData: WorkbenchNodeData,
  entryId: WorkbenchNodeEntryId
): boolean {
  const entryToRemove = nodeData.entries.find((entry) => entry.id == entryId);

  const lastElement = lastPathElement(entryToRemove.path);

  const pathType = entryToRemove.path.pathType;
  if (pathType != PathType.NEITHER) {
    const filtered =
      pathType == PathType.BOTH
        ? nodeData.entries.filter(
            (entry) =>
              entry.path.pathType == PathType.BOTH ||
              entry.path.pathType == PathType.INPUT ||
              entry.path.pathType == PathType.OUTPUT
          )
        : nodeData.entries.filter((entry) => entry.path.pathType == pathType);

    if (filtered.length == lastElement.minNumber) {
      return false;
    }
  }

  return true;
}
