/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import { ApolloClient, gql } from "@apollo/client";
import {
  DataFormatDescriptor,
  GeneralResponse,
  ModelFile,
  ModelInfo,
  NodeDescriptor,
  ProtectedUrl,
  ScanResult,
  TransformTypeDescriptor,
} from "./Api/Api";
import { AxiosInstance } from "axios";
import { Dispatch } from "react";
import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Connection,
  EdgeChange,
  EdgeRemoveChange,
  NodeChange,
  NodeRemoveChange,
} from "reactflow";
import "reactflow/dist/style.css";
import { Edge, Mapping, Path } from "./Api/DataTransformation";
import {
  IDLE,
  IfFulfilled,
  PromiseOutcome,
  PromiseState,
  ReducePromise,
} from "./Informedb/PromiseState";
import { notify } from "./Shared/Notify";
import { notifyGeneralError } from "./Shared/Errors";
import { SiteProps } from "./Utils/SiteProps";
import { createUUID, EMPTY_UUID, UUID } from "./Utils/Types";
import {
  addBrandNewEntry,
  addSpecificEntry,
  doChangeTargetLabel,
  doCreateEntriesForChunk,
  edgeMatches,
  findNodeEntry,
  getHandleFromString,
  modifyNodeData,
  ReactFlowEdge,
  ReactFlowNode,
  removeSpecificEntry,
  WorkbenchNodeData,
  WorkbenchNodeEntryId,
} from "./Workbench/WorkbenchData";
import GetAllModelIDs from "./Api/Gql/GetAllModelIDs";
import GetModels from "./Api/Gql/GetModels";
import { CancelToken } from "./Informedb/CancelToken";
import {
  lastPathElement,
  nodeFromDescriptor,
} from "./Workbench/NodeDescriptorUtil";

interface DataSourcePair {
  id: UUID;
  name: string;
}

interface AsyncProjectData {
  formats: DataFormatDescriptor[];
  transforms: TransformTypeDescriptor[];
  sources: DataSourcePair[];
  projects: ProjectStorableState[];
}

export class ProjectStorableState {
  projectID: UUID = createUUID();
  projectName: string = "Untitled mapping";

  // since the descriptor UUIDs don't change, and since the saved nodes are based on these types, it's safe to save them
  bufferedFormats: DataFormatDescriptor[] = [];
  bufferedTransforms: NodeDescriptor[] = [];

  // the mapping objects
  rfNodes: ReactFlowNode[] = [];
  rfEdges: ReactFlowEdge[] = [];

  // preferences
  multipleInputFilesToMultipleOutputFiles: boolean = false;
  multipleCSVsToSingleExcelOutput: boolean = false;
}

// master state used by the project
export class ProjectState extends ProjectStorableState {
  // for calling GraphQL without useQuery
  apolloClient: ApolloClient<any> = undefined;
  // for interacting with the backend
  axiosContext: AxiosInstance = undefined;
  siteProps: SiteProps = undefined;

  // stuff loaded asynchronously
  dataLoadAsync: PromiseState<AsyncProjectData> = IDLE;

  // server operation performed asynchronously
  performMappingAsync: PromiseState<boolean> = IDLE;
  registerMappingAsync: PromiseState<ProjectStorableState[]> = IDLE;

  // the sources might change from run to run, so they shouldn't be persisted
  bufferedSources: DataSourcePair[] = [];

  // saved projects may change from run to run
  bufferedProjects: ProjectStorableState[] = [];

  //Check if edge is clicked
  edgeClicked: boolean = false;

  projectTitleEditInProgress?: boolean = false;
  projectTitleInProgress?: string;

  // There are some components that require a changing
  // piece of state to signal that a rerender needs to occur after
  // non-user-initiated actions, e.g. values being updated from an AJAX response
  rerenderKey: number = 1;
}

// when saving a state, only include the persistent values, not the transient/"working" values
export function stringify(state: ProjectState): string {
  const obj = isolateProject(state);
  return JSON.stringify(obj, null, 2);
}

// ditto for loading a state
export function parse(state: ProjectState, json: string): ProjectState {
  const parsedObject = JSON.parse(json);
  const newProject = new ProjectStorableState();

  // copy storable fields into new project
  Object.keys(newProject).forEach((key) => {
    if (key in parsedObject) {
      newProject[key] = parsedObject[key];
    } else {
      throw Error("Could not find '" + key + "'");
    }
  });

  return mergeProject(state, newProject);
}

export function isolateProject(state: ProjectState): ProjectStorableState {
  const obj = new ProjectStorableState();
  // copy only the fields that exist in the target
  Object.keys(obj).forEach(
    (key) => (obj[key] = (key in state ? state : obj)[key])
  );
  return obj;
}

export function mergeProject(
  state: ProjectState,
  newProject: ProjectStorableState
): ProjectState {
  // build the next state from the current state, the storable fields, and some cleared transient fields
  return {
    ...state,
    ...newProject,
    dataLoadAsync: IDLE,
    performMappingAsync: IDLE,
    registerMappingAsync: IDLE,
    edgeClicked: false,
    rerenderKey: state.rerenderKey + 1,
  };
}

// all possible actions and outcomes for the state machine
type NavigateAction = [
  "navigate",
  {
    apolloClient: ApolloClient<any>;
    axiosContext: AxiosInstance;
    siteProps: SiteProps;
    isActiveRef?: React.MutableRefObject<boolean>;
  },
  Dispatch<ProjectAction>
];
type ReloadDataSourcesAction = [
  "reloadDataSources",
  { isActiveRef?: React.MutableRefObject<boolean> },
  Dispatch<ProjectAction>
];
type StartNodeTitleEditingAction = ["startTitleEditing", { nodeId: string }];
type ConfirmNodeTitleEditAction = ["confirmTitleEdit", { nodeId: string }];
type UpdateNodeTitleEditAction = [
  "updateTitleEdit",
  { nodeId: string; wipTitle: string }
];
type DiscardNodeTitleEditAction = ["discardTitleEdit", { nodeId: string }];
type DiscardProjectTitleEditAction = ["discardProjectTitleEdit"];
type StartProjectTitleEditingAction = ["startProjectTitleEditing"];
type ConfirmProjectTitleEditAction = ["confirmProjectTitleEdit"];
type UpdateProjectTitleAction = ["updateProjectTitle", { wipTitle: string }];
type DataLoadedOutcome = ["dataLoaded", PromiseOutcome<AsyncProjectData>];
type LoadProjectFromJsonAction = [
  "loadProjectFromJson",
  { json: string; source: string }
];
type LoadProjectFromDataAction = [
  "loadProjectFromData",
  { project: ProjectStorableState }
];
type ChangeNodesAction = ["changeNodes", { changes: NodeChange[] }];
type ChangeEdgesAction = ["changeEdges", { changes: EdgeChange[] }];
type ConnectAction = ["connect", { params: Connection | ReactFlowEdge }];
type EdgeClickedAction = ["edgeClicked"];
type CreateNodeFromDescriptorAction = [
  "createNodeFromDescriptor",
  {
    descriptor: NodeDescriptor;
  }
];
type SetSingleOrMultipleOutputFilesAction = [
  "setSingleOrMultipleOutputFiles",
  { multipleInputFilesToMultipleOutputFiles: boolean }
];
type SetMultipleCSVsOrExcelFileAction = [
  "setMultipleCSVsOrExcelFile",
  { multipleCSVsToSingleExcelOutput: boolean }
];
type ModifyNodeDataAction = [
  "modifyNodeData",
  { modifiedNodeData: WorkbenchNodeData }
];
type AddSpecificEntryAction = [
  "addSpecificEntry",
  { nodeId: string; entryId: WorkbenchNodeEntryId; delta: number }
];
type AddBrandNewEntryAction = [
  "addBrandNewEntry",
  { nodeId: string; path: Path }
];
type RemoveSpecificEntryAction = [
  "removeSpecificEntry",
  { nodeId: string; entryId: WorkbenchNodeEntryId }
];
type RegisterMappingAction = [
  "registerMapping",
  { isActiveRef?: React.MutableRefObject<boolean> },
  Dispatch<ProjectAction>
];
type MappingRegisteredOutcome = [
  "mappingRegistered",
  PromiseOutcome<ProjectStorableState[]>
];
type PerformMappingAction = [
  "performMapping",
  { sourceModelID: UUID; isActiveRef?: React.MutableRefObject<boolean> },
  Dispatch<ProjectAction>
];
type MappingPerformedOutcome = ["mappingPerformed", PromiseOutcome<boolean>];

// make a set
export type ProjectAction =
  | NavigateAction
  | ReloadDataSourcesAction
  | DataLoadedOutcome
  | LoadProjectFromJsonAction
  | LoadProjectFromDataAction
  | ChangeNodesAction
  | StartNodeTitleEditingAction
  | ConfirmNodeTitleEditAction
  | UpdateNodeTitleEditAction
  | DiscardNodeTitleEditAction
  | StartProjectTitleEditingAction
  | ConfirmProjectTitleEditAction
  | UpdateProjectTitleAction
  | DiscardProjectTitleEditAction
  | ChangeEdgesAction
  | ConnectAction
  | CreateNodeFromDescriptorAction
  | SetSingleOrMultipleOutputFilesAction
  | SetMultipleCSVsOrExcelFileAction
  | ModifyNodeDataAction
  | AddSpecificEntryAction
  | AddBrandNewEntryAction
  | RemoveSpecificEntryAction
  | RegisterMappingAction
  | MappingRegisteredOutcome
  | PerformMappingAction
  | MappingPerformedOutcome
  | EdgeClickedAction;

export function ProjectReducer(
  state: ProjectState,
  action: ProjectAction
): ProjectState {
  switch (action[0]) {
    case "navigate": {
      const [
        {},
        { apolloClient, axiosContext, siteProps, isActiveRef },
        dispatch,
      ] = action;

      // for the time being, always kick off data loading when we navigate to the page
      const dataLoadAsync = ReducePromise(state.dataLoadAsync, [
        "start",
        (cancel) => loadProjectData(apolloClient, axiosContext, cancel),
        // the following dispatch call is what causes the "Can't perform a React state update on an unmounted component" warning to be shown on the console if the page is refreshed
        (outcome) => {
          // If we don't provide a ref, always fire the new action.  If we do provide a ref, make sure it's current (i.e. the caller hasn't cleaned up)
          if (isActiveRef === undefined || isActiveRef.current) {
            dispatch(["dataLoaded", outcome]);
          }
        },
      ]);

      return {
        ...state,
        apolloClient,
        axiosContext,
        siteProps,
        dataLoadAsync,
        edgeClicked: false,
      };
    }

    case "reloadDataSources": {
      const [{}, { isActiveRef }, dispatch] = action;

      // we want to explicitly reload only the data sources
      const dataLoadAsync = ReducePromise(state.dataLoadAsync, [
        "start",
        (cancel) =>
          loadProjectDataSourcesOnly(
            state.apolloClient,
            state.bufferedFormats,
            state.bufferedTransforms,
            state.bufferedProjects,
            cancel
          ),
        (outcome) => {
          // If we don't provide a ref, always fire the new action.  If we do provide a ref, make sure it's current (i.e. the caller hasn't cleaned up)
          if (isActiveRef === undefined || isActiveRef.current) {
            dispatch(["dataLoaded", outcome]);
          }
        },
      ]);

      return {
        ...state,
        dataLoadAsync,
        edgeClicked: false,
      };
    }

    case "edgeClicked": {
      return {
        ...state,
        edgeClicked: true,
      };
    }

    case "startTitleEditing": {
      const [{}, { nodeId }] = action;
      const existingNodes = state.rfNodes;
      const existingNode = existingNodes.find(
        (node) => node.data.dataID == nodeId
      );
      if (!existingNode) {
        return state;
      }

      const oldNodeData = existingNode.data;

      const newNodeData: WorkbenchNodeData = {
        ...oldNodeData,
        titleEditInProgress: true,
        titleInProgress: oldNodeData.title ?? "",
      };

      return {
        ...state,
        rfNodes: modifyNodeData(existingNodes, newNodeData),
      };
    }

    case "updateTitleEdit": {
      const [{}, { nodeId, wipTitle }] = action;
      const existingNodes = state.rfNodes;
      const existingNode = existingNodes.find(
        (node) => node.data.dataID == nodeId
      );
      if (!existingNode) {
        return state;
      }

      const oldNodeData = existingNode.data;

      const newNodeData: WorkbenchNodeData = {
        ...oldNodeData,
        titleEditInProgress: true,
        titleInProgress: wipTitle,
      };

      return {
        ...state,
        rfNodes: modifyNodeData(existingNodes, newNodeData),
      };
    }

    case "confirmTitleEdit": {
      const [{}, { nodeId }] = action;
      const existingNodes = state.rfNodes;
      const existingNode = existingNodes.find(
        (node) => node.data.dataID == nodeId
      );
      if (!existingNode) {
        return state;
      }

      const oldNodeData = existingNode.data;

      const newNodeData: WorkbenchNodeData = {
        ...oldNodeData,
        titleEditInProgress: false,
        title: oldNodeData.titleInProgress,
      };

      return {
        ...state,
        rfNodes: modifyNodeData(existingNodes, newNodeData),
      };
    }

    case "discardTitleEdit": {
      const [{}, { nodeId }] = action;
      const existingNodes = state.rfNodes;
      const existingNode = existingNodes.find(
        (node) => node.data.dataID == nodeId
      );
      if (!existingNode) {
        return state;
      }

      const oldNodeData = existingNode.data;

      const newNodeData: WorkbenchNodeData = {
        ...oldNodeData,
        titleEditInProgress: false,
        title: oldNodeData.title,
      };

      return {
        ...state,
        rfNodes: modifyNodeData(existingNodes, newNodeData),
      };
    }

    case "startProjectTitleEditing": {
      return {
        ...state,
        projectTitleEditInProgress: true,
        projectTitleInProgress: state.projectName,
      };
    }

    case "updateProjectTitle": {
      const [{}, { wipTitle }] = action;

      return {
        ...state,
        projectTitleEditInProgress: true,
        projectTitleInProgress: wipTitle,
      };
    }

    case "confirmProjectTitleEdit": {
      const newProjectData: ProjectState = {
        ...state,
        projectTitleEditInProgress: false,
        projectName: state.projectTitleInProgress,
      };
      return newProjectData;
    }

    case "discardProjectTitleEdit": {
      return {
        ...state,
        projectTitleEditInProgress: false,
        projectTitleInProgress: state.projectName,
      };
    }

    case "dataLoaded": {
      const [{}, outcome] = action;
      const dataLoadAsync = ReducePromise(state.dataLoadAsync, outcome);
      const data = IfFulfilled(dataLoadAsync);

      return {
        ...state,
        dataLoadAsync,
        bufferedFormats: data?.formats ?? state.bufferedFormats,
        bufferedSources: data?.sources ?? state.bufferedSources,
        bufferedTransforms: data?.transforms ?? state.bufferedTransforms,
        bufferedProjects: data?.projects ?? state.bufferedProjects,
        edgeClicked: false,
      };
    }

    case "loadProjectFromData": {
      const [{}, { project }] = action;

      const { bufferedSources, bufferedProjects } = state;

      return {
        ...mergeProject(state, project),
        bufferedFormats:
          (project.bufferedFormats ?? []).length > 0
            ? project.bufferedFormats
            : state.bufferedFormats,
        bufferedTransforms:
          (project.bufferedTransforms ?? []).length > 0
            ? project.bufferedTransforms
            : state.bufferedTransforms,
        bufferedSources,
        bufferedProjects,
        edgeClicked: false,
      };
    }

    case "loadProjectFromJson": {
      const [{}, { json, source }] = action;

      try {
        return parse(state, json);
      } catch (error) {
        notifyGeneralError(error, "Unable to parse JSON from " + source);
        return state;
      }
    }

    case "changeNodes": {
      const [{}, { changes }] = action;
      const removeChanges = changes.filter(
        (change) => change.type == "remove"
      ) as NodeRemoveChange[];

      const edgeChanges = removeChanges.flatMap(
        (change: NodeRemoveChange): EdgeRemoveChange[] => {
          const edges = state.rfEdges.filter(
            (edge) => edge.source == change.id || edge.target == change.id
          );
          return edges.map((edge) => {
            return { id: edge.id, type: "remove" };
          });
        }
      );

      return {
        ...state,
        rfEdges: applyEdgeChanges(
          edgeChanges,
          state.rfEdges
        ) as ReactFlowEdge[],
        rfNodes: applyNodeChanges(changes, state.rfNodes) as ReactFlowNode[],
        edgeClicked: false,
      };
    }

    case "changeEdges": {
      const [{}, { changes }] = action;

      return {
        ...state,
        rfEdges: applyEdgeChanges(changes, state.rfEdges) as ReactFlowEdge[],
        // do not set edgeClicked to false here
      };
    }

    case "setSingleOrMultipleOutputFiles": {
      const [{}, { multipleInputFilesToMultipleOutputFiles }] = action;

      return {
        ...state,
        multipleInputFilesToMultipleOutputFiles,
        edgeClicked: false,
      };
    }

    case "setMultipleCSVsOrExcelFile": {
      const [{}, { multipleCSVsToSingleExcelOutput }] = action;

      return {
        ...state,
        multipleCSVsToSingleExcelOutput,
        edgeClicked: false,
      };
    }

    // Any component that invokes this action must also invoke updateNodeInternals in case this changes the number of handles; see
    // https://reactflow.dev/docs/api/hooks/use-update-node-internals/
    case "connect": {
      const [{}, { params }] = action;
      const [newUpdatedNodes, newConnections] = doCreateEntriesForChunk(
        state.rfNodes,
        params as ReactFlowEdge
      );

      let updatedNodes = newUpdatedNodes;
      let updatedEdges = state.rfEdges;

      // do the modifications and connections returned by doCreateEntriesForChunk,
      // not necessarily the connection initiated by the user
      newConnections.forEach((connection) => {
        updatedNodes = doChangeTargetLabel(
          updatedNodes,
          connection as ReactFlowEdge
        );
        updatedEdges = doConnection(updatedEdges, connection);
      });

      return {
        ...state,
        rfEdges: updatedEdges,
        rfNodes: updatedNodes,
        edgeClicked: false,
      };
    }

    case "createNodeFromDescriptor": {
      const [{}, { descriptor }] = action;
      return {
        ...state,
        rfNodes: addNodeFromDescriptor(
          descriptor,
          state.rfNodes
        ) as ReactFlowNode[],
        edgeClicked: false,
      };
    }

    case "modifyNodeData": {
      const [{}, { modifiedNodeData }] = action;

      return {
        ...state,
        rfNodes: modifyNodeData(state.rfNodes, modifiedNodeData),
        edgeClicked: false,
      };
    }

    // Any component that invokes this action must also invoke updateNodeInternals since this changes the number of handles; see
    // https://reactflow.dev/docs/api/hooks/use-update-node-internals/
    case "addSpecificEntry": {
      const [{}, { nodeId, entryId, delta }] = action;
      const existingNode = state.rfNodes.find(
        (node) => node.data.dataID == nodeId
      );
      if (!existingNode) {
        return state;
      }

      const [newNodeData, {}] = addSpecificEntry(
        existingNode.data,
        entryId,
        delta
      );

      return {
        ...state,
        rfNodes: modifyNodeData(state.rfNodes, newNodeData),
        edgeClicked: false,
      };
    }

    case "addBrandNewEntry": {
      const [{}, { nodeId, path }] = action;
      const existingNode = state.rfNodes.find(
        (node) => node.data.dataID == nodeId
      );

      if (!existingNode) {
        return state;
      }
      const [newNodeData, {}] = addBrandNewEntry(existingNode.data, path);

      return {
        ...state,
        rfNodes: modifyNodeData(state.rfNodes, newNodeData),
        edgeClicked: false,
      };
    }

    // Any component that invokes this action must also invoke updateNodeInternals since this changes the number of handles; see
    // https://reactflow.dev/docs/api/hooks/use-update-node-internals/
    case "removeSpecificEntry": {
      const [{}, { nodeId, entryId }] = action;
      const existingNode = state.rfNodes.find(
        (node) => node.data.dataID == nodeId
      );
      if (!existingNode) {
        return state;
      }

      const [newNodeData, removedHandleId] = removeSpecificEntry(
        existingNode.data,
        entryId
      );

      // nothing was removed
      if (!newNodeData) {
        return state;
      }

      // remove any edges connected to this handle
      const rfEdges = state.rfEdges.filter(
        (edge) => !edgeMatches(edge, nodeId, removedHandleId)
      );

      return {
        ...state,
        rfEdges,
        rfNodes: modifyNodeData(state.rfNodes, newNodeData),
        edgeClicked: false,
      };
    }

    case "registerMapping": {
      const [{}, { isActiveRef }, dispatch] = action;

      // kick off the processing
      const registerMappingAsync = ReducePromise(state.registerMappingAsync, [
        "start",
        (cancel) => registerMapping(state, cancel),
        (outcome) => {
          // If we don't provide a ref, always fire the new action.  If we do provide a ref, make sure it's current (i.e. the caller hasn't cleaned up)
          if (isActiveRef === undefined || isActiveRef.current) {
            dispatch(["mappingRegistered", outcome]);
          }
        },
      ]);

      return {
        ...state,
        registerMappingAsync,
        edgeClicked: false,
      };
    }

    case "mappingRegistered": {
      const [{}, outcome] = action;
      const registerMappingAsync = ReducePromise(
        state.registerMappingAsync,
        outcome
      );
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const newProjects = IfFulfilled(registerMappingAsync) ?? [];

      return {
        ...state,
        registerMappingAsync,
        bufferedProjects:
          newProjects.length > 0 ? newProjects : state.bufferedProjects,
        edgeClicked: false,
      };
    }

    case "performMapping": {
      const [{}, { sourceModelID, isActiveRef }, dispatch] = action;

      // kick off the processing
      const performMappingAsync = ReducePromise(state.performMappingAsync, [
        "start",
        (cancel) =>
          performMapping(state, state.projectID, sourceModelID, cancel),
        (outcome) => {
          // If we don't provide a ref, always fire the new action.  If we do provide a ref, make sure it's current (i.e. the caller hasn't cleaned up)
          if (isActiveRef === undefined || isActiveRef.current) {
            dispatch(["mappingPerformed", outcome]);
          }
        },
      ]);

      return {
        ...state,
        performMappingAsync,
        edgeClicked: false,
      };
    }

    case "mappingPerformed": {
      const [{}, outcome] = action;
      const performMappingAsync = ReducePromise(
        state.performMappingAsync,
        outcome
      );
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const success = IfFulfilled(performMappingAsync);

      return {
        ...state,
        performMappingAsync,
        edgeClicked: false,
      };
    }
  }
}

function nodePosition(
  existingNodes: ReactFlowNode[]
): ReactFlowNode["position"] {
  const previousNodePosition =
    existingNodes.length > 0
      ? existingNodes[existingNodes.length - 1].position
      : undefined;

  const previousNodeWidth =
    existingNodes.length > 0
      ? existingNodes[existingNodes.length - 1].width
      : undefined;

  const newPosition = {
    x:
      previousNodePosition !== undefined
        ? previousNodePosition.x + previousNodeWidth + 10
        : 50,
    y: previousNodePosition !== undefined ? previousNodePosition.y : 50,
  };

  return newPosition;
}

function addNodeFromDescriptor(
  descriptor: NodeDescriptor,
  existingNodes: ReactFlowNode[]
): ReactFlowNode[] {
  const dataID = descriptor.displayName + existingNodes.length;
  const rfNode = nodeFromDescriptor(
    dataID,
    descriptor,
    nodePosition(existingNodes)
  );

  return existingNodes.concat(rfNode);
}

function doConnection(
  existingEdges: ReactFlowEdge[],
  params: Connection | ReactFlowEdge
): ReactFlowEdge[] {
  if (params.source == params.target) {
    return existingEdges;
  }
  const intermediateEdges = existingEdges.filter(
    (edge) =>
      edge.target != params.target || edge.targetHandle != params.targetHandle
  );
  return addEdge(params, intermediateEdges);
}

async function loadDataFormatDescriptors(
  axiosContext: AxiosInstance,
  _cancel: CancelToken
): Promise<DataFormatDescriptor[]> {
  try {
    const response = await axiosContext.get("/getDataFormatDescriptors");
    if (response.status == 200) {
      return response.data;
    } else {
      notify.negative(`HTTP status ${response.status} - Unable to get formats`);
      return [];
    }
  } catch (error) {
    notifyGeneralError(error, "Unable to get formats");
    return [];
  }
}

async function loadProjects(
  axiosContext: AxiosInstance,
  _cancel: CancelToken
): Promise<ProjectStorableState[]> {
  try {
    const response = await axiosContext.get("/getProjects");
    if (response.status == 200) {
      return response.data;
    } else {
      notify.negative(
        `HTTP status ${response.status} - Unable to get projects`
      );
      return [];
    }
  } catch (error) {
    notifyGeneralError(error, "Unable to get projects");
    return [];
  }
}

async function loadTransformTypeDescriptors(
  axiosContext: AxiosInstance,
  _cancel: CancelToken
): Promise<NodeDescriptor[]> {
  try {
    const response = await axiosContext.get("/getTransformTypeDescriptors");
    if (response.status == 200) {
      return response.data;
    } else {
      notify.negative(
        `HTTP status ${response.status} - Unable to get transforms`
      );
      return [];
    }
  } catch (error) {
    notifyGeneralError(error, "Unable to get transforms");
    return [];
  }
}

async function loadDataSources(
  apolloClient: ApolloClient<any>,
  _cancel: CancelToken
): Promise<DataSourcePair[]> {
  // grab all model IDs
  const modelIDsResult = await apolloClient.query({
    query: gql(GetAllModelIDs),
  });
  const modelIDs: UUID[] = modelIDsResult?.data?.getAllModelIDs ?? [];

  // grab the models associated with each model ID
  const modelResult = await apolloClient.query({
    query: gql(GetModels),
    variables: { ids: modelIDs },
  });
  const models: ModelInfo[] = modelResult?.data?.getModels ?? [];

  // filter just the models that have at least one valid file
  const validModels = models.filter((model: ModelInfo) => {
    return (model.fileFolders ?? []).some((fileFolder) => {
      return (fileFolder.files ?? []).some((file: ModelFile) => {
        const scan = file.metadataScan;
        return scan.scanResult == ScanResult.SUCCESS && scan.metadata;
      });
    });
  });

  // and now list them in the dropdown
  return validModels.map((model: ModelInfo) => {
    return { id: model.id, name: model.metadata.title ?? model.id };
  });
}

async function loadProjectData(
  apolloClient: ApolloClient<any>,
  axiosContext: AxiosInstance,
  cancel: CancelToken
): Promise<AsyncProjectData> {
  const [formats, transforms, sources, projects] = await Promise.all([
    loadDataFormatDescriptors(axiosContext, cancel),
    loadTransformTypeDescriptors(axiosContext, cancel),
    loadDataSources(apolloClient, cancel),
    loadProjects(axiosContext, cancel),
  ]);
  return { formats, transforms, sources, projects };
}

async function loadProjectDataSourcesOnly(
  apolloClient: ApolloClient<any>,
  dataFormatDescriptors: DataFormatDescriptor[],
  transformTypeDescriptors: NodeDescriptor[],
  projects: ProjectStorableState[],
  cancel: CancelToken
): Promise<AsyncProjectData> {
  const sources = await loadDataSources(apolloClient, cancel);
  return {
    formats: dataFormatDescriptors,
    transforms: transformTypeDescriptors,
    sources,
    projects,
  };
}

// Extracts some data from the workbench state to be used in the data transformation mapping.
function toMappingEdge(
  existingNodes: ReactFlowNode[],
  edge: ReactFlowEdge
): Edge {
  const source = existingNodes.find((node) => edge.source == node.id);
  const target = existingNodes.find((node) => edge.target == node.id);

  if (source && target) {
    const sourceHandle = getHandleFromString(source.data, edge.sourceHandle);
    const targetHandle = getHandleFromString(target.data, edge.targetHandle);

    if (sourceHandle && targetHandle) {
      const sourcePathEndpoint = lastPathElement(sourceHandle.path);
      const targetPathEndpoint = lastPathElement(targetHandle.path);

      const sourceEntry = findNodeEntry(source.data, sourceHandle.entryId);
      const targetEntry = findNodeEntry(target.data, targetHandle.entryId);

      // will be undefined if there is no source entry or path element string
      const sourceLabel = sourceEntry?.label ?? sourcePathEndpoint.byString;
      const targetLabel = targetEntry?.label ?? targetPathEndpoint.byString;

      const mappingEdge: Edge = {
        sourceID: source.data.dataID,
        sourcePath: sourceHandle.path,
        sourceLabel,
        targetID: target.data.dataID,
        targetPath: targetHandle.path,
        targetLabel,
      };

      return mappingEdge;
    }
  }

  return undefined;
}

async function registerMapping(
  state: ProjectState,
  cancel: CancelToken
): Promise<ProjectStorableState[]> {
  try {
    const storable = isolateProject(state);

    const postResponse = await state.axiosContext.post("/addProject", storable);
    if (typeof postResponse.data === "string") {
      // all we have is a message, so show it
      notify.positive(postResponse.data);
    } else {
      const data = postResponse.data;
      if (data.projectID == state.projectID) {
        // show positive toast
        notify.positive("Mapping registered successfully");
      } else {
        // show negative toast
        notify.negative(
          "Unable to register mapping" +
            (data.message ? ": " + data.message : "")
        );
      }
    }

    return await loadProjects(state.axiosContext, cancel);
  } catch (error) {
    notifyGeneralError(error, "Unable to register mapping");
    return [];
  }
}

async function performMapping(
  state: ProjectState,
  mappingID: UUID,
  sourceModelID: UUID,
  _cancel: CancelToken
): Promise<boolean> {
  try {
    const mappingEdges = new Array<Edge>();
    state.rfEdges.forEach((edge) => {
      mappingEdges.push(toMappingEdge(state.rfNodes, edge));
    });

    const nodeTitles = new Map<string, string>();
    state.rfNodes.forEach((node) => {
      if ((node.data.title ?? "").length > 0) {
        nodeTitles[node.data.dataID] = node.data.title;
      }
    });

    const supplementalData = new Map<string, string>();
    state.rfNodes.forEach((node) => {
      if ((node.data.supplementalData ?? "").length > 0) {
        supplementalData[node.data.dataID] = node.data.supplementalData;
      }
    });

    const mapping: Mapping = {
      mappingID,
      sourceModelID,
      edges: mappingEdges,
      titles: nodeTitles,
      supplementalData,
      multipleInputFilesToMultipleOutputFiles:
        state.multipleInputFilesToMultipleOutputFiles,
      multipleCSVsToSingleExcelOutput: state.multipleCSVsToSingleExcelOutput,
    };

    const postResponse = await state.axiosContext.post(
      "/performMapping",
      mapping
    );
    if (typeof postResponse.data === "string") {
      // all we have is a message, so show it
      notify.positive(postResponse.data);
    } else {
      const data: GeneralResponse = postResponse.data;

      if (data.status == 0 || data.status == 200) {
        // everything ok, so download the file
        const protectedUrl: ProtectedUrl = data.payload;
        window.location.href = `${state.siteProps.topUrl}${protectedUrl.urlFragment}`;

        // show positive toast
        if (data.message.length > 0) {
          notify.positive(data.message);
        }
      } else {
        // show negative toast
        notify.negative(
          "Unable to perform mapping" +
            (data.message.length > 0 ? ": " + data.message : "")
        );
      }
    }

    return true;
  } catch (error) {
    notifyGeneralError(error, "Unable to perform mapping");
    return false;
  }
}
