/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { useStyletron } from "baseui";
import { Button, SIZE } from "baseui/button";
import { Overflow } from "baseui/icon";
import { StatefulMenu } from "baseui/menu";
import { PLACEMENT, StatefulPopover } from "baseui/popover";
import * as React from "react";
import { TOP_ITEM_ZINDEX } from "../Utils/SiteProps";

export interface OverflowActions {
  label: string;
  onClick?: () => void;
  disabled?: boolean;
}

export const OverflowMenu = ({
  overflowActions,
  disabled,
  float,
}: {
  overflowActions: OverflowActions[];
  disabled?: boolean;
  float?: "left" | "right" | "none" | "inline-start" | "inline-end";
}): JSX.Element => {
  const [css] = useStyletron();
  return (
    <>
      <StatefulPopover
        overrides={{
          Body: {
            style: {
              zIndex: TOP_ITEM_ZINDEX,
            },
          },
        }}
        focusLock
        placement={PLACEMENT.right}
        content={({ close }) => (
          <StatefulMenu
            items={overflowActions}
            onItemSelect={(overflowItem) => {
              close();
              overflowItem.item.onClick();
            }}
          />
        )}
      >
        <div
          className={css({
            marginLeft: "auto",
            float: float ?? "right",
          })}
        >
          <Button
            disabled={disabled}
            size={SIZE.compact}
            overrides={{
              BaseButton: {
                style: {
                  backgroundColor: "transparent",
                  scale: "0.75 1",
                  height: "10px",
                },
              },
            }}
          >
            <Overflow
              color="black"
              size={20}
              overrides={{
                Svg: {
                  style: {
                    transform: "rotate(90deg) scaleY(1.33)",
                  },
                },
              }}
            />
          </Button>
        </div>
      </StatefulPopover>
    </>
  );
};
