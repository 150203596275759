/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { render } from "react-dom";
import "@fontsource/roboto";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { BaseProvider } from "baseui";
import { LightTheme } from "./DesignSystem/LightTheme";

const engine = new Styletron();

import "./simor-site.scss";

import { AuthProvider } from "./Utils/AuthContext";
import { SiteProps, SiteContext } from "./Utils/SiteProps";
import { DexterApp } from "./DexterApp";

const AppRoot = ({ topUrl }: { topUrl: string }): JSX.Element => {
  const setPreviousPage = (previousPage: string): void => {
    setSiteProps({ ...siteProps, previousPage: previousPage });
  };
  const [siteProps, setSiteProps] = React.useState<SiteProps>({
    topUrl: topUrl,
    previousPage: "/",
    setPreviousPage: setPreviousPage,
  });

  return (
    <SiteContext.Provider value={siteProps}>
      <AuthProvider>
        <StyletronProvider value={engine}>
          <BaseProvider theme={LightTheme}>
            <DexterApp />
          </BaseProvider>
        </StyletronProvider>
      </AuthProvider>
    </SiteContext.Provider>
  );
};

const rootUrl = `${window.location.protocol}//${window.location.host}`;
render(<AppRoot topUrl={rootUrl} />, document.getElementById("DexterRoot"));
