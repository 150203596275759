/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */

import {
  WorkbenchNodeData,
  modifyNodeEntry,
  findNodeEntry,
  WorkbenchNodeEntryId,
} from "./WorkbenchData";

export interface CSVNodeEntryStatus {
  editInProgress: boolean;
  labelInProgress: string;
}

export class CSVNodeState {
  nodeData: WorkbenchNodeData = undefined;
  entryStatuses: Map<number, CSVNodeEntryStatus> = new Map<
    number,
    CSVNodeEntryStatus
  >();
}

type NavigateAction = ["navigate", { nodeData: WorkbenchNodeData }];
type StartEditingAction = ["startEditing", { entryId: WorkbenchNodeEntryId }];
type UpdateEditAction = [
  "updateEdit",
  { entryId: WorkbenchNodeEntryId; wipLabel: string }
];
type ConfirmEditAction = ["confirmEdit", { entryId: WorkbenchNodeEntryId }];
type DiscardEditAction = ["discardEdit", { entryId: WorkbenchNodeEntryId }];

export type CSVNodeAction =
  | NavigateAction
  | StartEditingAction
  | UpdateEditAction
  | ConfirmEditAction
  | DiscardEditAction;

export function CSVNodeReducer(
  state: CSVNodeState,
  action: CSVNodeAction
): CSVNodeState {
  switch (action[0]) {
    case "navigate": {
      const [{}, { nodeData }] = action;
      return { ...state, nodeData };
    }

    case "startEditing": {
      const [{}, { entryId }] = action;
      const oldEntry = findNodeEntry(state.nodeData, entryId);

      const newEntryStatus = {
        editInProgress: true,
        labelInProgress: oldEntry.label ?? "",
      };
      // it's probably ok to modify the old map, since we're returning a new state anyway
      const newEntryStatuses = state.entryStatuses;
      newEntryStatuses.set(entryId, newEntryStatus);

      return {
        ...state,
        entryStatuses: newEntryStatuses,
      };
    }

    case "updateEdit": {
      const [{}, { entryId, wipLabel }] = action;

      const newEntryStatus = {
        editInProgress: true,
        labelInProgress: wipLabel,
      };
      // it's probably ok to modify the old map, since we're returning a new state anyway
      const newEntryStatuses = state.entryStatuses;
      newEntryStatuses.set(entryId, newEntryStatus);

      return {
        ...state,
        entryStatuses: newEntryStatuses,
      };
    }

    case "confirmEdit":
      const [{}, { entryId }] = action;
      const oldEntry = findNodeEntry(state.nodeData, entryId);
      const oldEntryStatus = state.entryStatuses.get(entryId as number);

      const newNodeData = modifyNodeEntry(state.nodeData, {
        ...oldEntry,
        label: oldEntryStatus.labelInProgress,
        originalLabel: oldEntryStatus.labelInProgress == oldEntry.label,
      });

      const newEntryStatus = {
        editInProgress: false,
        labelInProgress: oldEntryStatus.labelInProgress,
      };
      // it's probably ok to modify the old map, since we're returning a new state anyway
      const newEntryStatuses = state.entryStatuses;
      newEntryStatuses.set(entryId, newEntryStatus);

      return {
        ...state,
        nodeData: newNodeData,
        entryStatuses: newEntryStatuses,
      };

    case "discardEdit": {
      const [{}, { entryId }] = action;

      const newEntryStatus = {
        editInProgress: false,
        labelInProgress: "",
      };
      // it's probably ok to modify the old map, since we're returning a new state anyway
      const newEntryStatuses = state.entryStatuses;
      newEntryStatuses.set(entryId, newEntryStatus);

      return { ...state, entryStatuses: newEntryStatuses };
    }
  }
}
