/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
// TODO: Move to Comparison.ts

import { DateTime } from "luxon";

const getDateAsMillis = <T>(
  dataObject: T,
  getPropertyValue: (element: T) => DateTime | null
): number => {
  const dateValue = getPropertyValue(dataObject);
  return dateValue ? dateValue.toMillis() : 0;
};

export const sortByDate = <T>(
  list: T[],
  sortAscending: boolean,
  getPropertyValue: (element: T) => DateTime
): T[] => {
  return list.slice().sort((a, b) => {
    const left = sortAscending ? a : b;
    const right = sortAscending ? b : a;
    const leftMillis = getDateAsMillis(left, getPropertyValue);
    const rightMillis = getDateAsMillis(right, getPropertyValue);

    return leftMillis - rightMillis;
  });
};

// TODO: Handle NaN?
export const sortByNumber = <T>(
  list: T[],
  sortAscending: boolean,
  getPropertyValue: (element: T) => number
): T[] => {
  return list.slice().sort((a, b) => {
    const left = sortAscending ? a : b;
    const right = sortAscending ? b : a;
    const leftValue = getPropertyValue(left) ?? 0;
    const rightValue = getPropertyValue(right) ?? 0;
    return leftValue - rightValue;
  });
};

const STRING_COMPARISON_LOCALE = "en";

export const sortByString = <T>(
  files: T[],
  sortAscending: boolean,
  getPropertyValue: (element: T) => string
): T[] => {
  return files.slice().sort((a, b) => {
    const left = sortAscending ? a : b;
    const right = sortAscending ? b : a;
    const leftValue = getPropertyValue(left) ?? "";
    const rightValue = getPropertyValue(right) ?? "";
    return leftValue.localeCompare(rightValue, STRING_COMPARISON_LOCALE, {
      numeric: false,
      sensitivity: "base",
    });
  });
};

export function equalIgnoreCase(left: string, right: string): boolean {
  return compareCaseInsensitive(left, right) === 0;
}

export function compareCaseInsensitive(left: string, right: string): number {
  if (!left) {
    return -1;
  }
  if (!right) {
    return 1;
  }
  const lowerCaseLeft = left.toLowerCase();
  const lowerCaseRight = right.toLowerCase();
  return lowerCaseLeft.localeCompare(lowerCaseRight, STRING_COMPARISON_LOCALE);
}
