/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
export default `query {
  appInfo {
    version {
      descriptor
      abbreviatedCommitHash
      commitHash
      buildDate
    }
    configurations {
      isSensitiveSecurityContext
    }
    schemaStatus {
      expectedMigrationId
      lastExecutedMigration  {
        __typename
        ... on ExecutedMigration {
          changeId
          timestamp
        }
        ... on NoMigrationsExecuted {
          message
        }
        ... on ErrorGettingMigrations {
          errorMessage
        }
      }
    }
  }
}`;
