/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";

export const APP_TITLE_UPPERCASE: string = "DEXTER";
export const APP_TITLE_TITLECASE: string = "Dexter";

export const TOP_ITEM_ZINDEX = 10;

export interface SiteProps {
  topUrl: string;
  previousPage: string;
  setPreviousPage: (previousPage: string) => void;
}

export const COMMON_TEXT_STYLING: React.CSSProperties = {
  textDecoration: "none",
  fontSize: "1rem",
  fontFamily: "Roboto",
  color: "#201f1e",
};

// TODO: Move the reference to the current page state to global state. Possibly
// as a reactive var? It would possibly simplify things.
export const SiteContext = React.createContext<SiteProps>({
  topUrl: "(not set)",
  previousPage: "/",
  setPreviousPage: undefined,
});
