/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { Button, ButtonOverrides } from "baseui/button";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import * as React from "react";
import { Row } from "../DesignSystem/Containers";
import { FieldSpec, simpleTextFieldSpec } from "../Shared/FieldSpec";
import { isFailure, OperationOutcome } from "../Shared/OperationOutcome";
import { isBlank } from "../Utils/Strings";

const addFieldButtonOverrides: ButtonOverrides = {
  BaseButton: {
    style: {
      width: "8rem",
    },
  },
};

const FieldDefinitionEditor = ({
  canAddField,
  onAddField,
  onCancel,
}: {
  canAddField: (newField: FieldSpec) => OperationOutcome;
  onAddField: (newField: FieldSpec, initialValue: any) => OperationOutcome;
  onCancel: () => void;
}): JSX.Element => {
  const [fieldName, setFieldName] = React.useState<string>("");

  const isTextEmpty = React.useMemo(() => {
    return isBlank(fieldName);
  }, [fieldName]);

  const fieldToAdd = React.useMemo(() => {
    const trimmedFieldName = fieldName.trim();
    return simpleTextFieldSpec(trimmedFieldName, trimmedFieldName);
  }, [fieldName]);

  const outcomeIfAdded = React.useMemo(() => {
    return canAddField(fieldToAdd);
  }, [canAddField, fieldToAdd]);

  const shouldShowError = !isTextEmpty && !outcomeIfAdded.successful;

  const errorMessage: string = isFailure(outcomeIfAdded)
    ? outcomeIfAdded.message
    : "";

  const tryAddingField = React.useCallback(() => {
    const addOutcome = onAddField(fieldToAdd, "");
    if (addOutcome.successful) {
      setFieldName("");
    } else {
      // Do nothing if it failed.
    }
  }, [fieldToAdd, onAddField]);

  const onTextChange = React.useCallback(
    (event) => {
      setFieldName(event.currentTarget.value);
    },
    [setFieldName]
  );

  const handleCancel = React.useCallback(() => {
    onCancel();
  }, [onCancel]);

  const handleKeyDown = React.useCallback(
    (keyEvent: React.KeyboardEvent): void => {
      if (keyEvent.key === "Enter" && outcomeIfAdded.successful) {
        tryAddingField();
      } else if (keyEvent.key === "Escape") {
        // TODO: This is currently rendered in a big dialog which
        // also closes when escape is pressed.
        keyEvent.stopPropagation();
        handleCancel();
      }
    },
    [tryAddingField, handleCancel, outcomeIfAdded.successful]
  );

  return (
    <FormControl
      label={() => "Add new field"}
      error={shouldShowError ? errorMessage : null}
    >
      <Row gap={"1rem"}>
        <Input
          autoFocus={true}
          placeholder="New field name..."
          onChange={onTextChange}
          onKeyDown={handleKeyDown}
          error={shouldShowError}
        />
        <Button
          kind="secondary"
          onClick={tryAddingField}
          disabled={!outcomeIfAdded.successful}
          overrides={addFieldButtonOverrides}
        >
          {"Add Field"}
        </Button>
        <Button
          kind="tertiary"
          onClick={handleCancel}
          overrides={addFieldButtonOverrides}
        >
          {"Cancel"}
        </Button>
      </Row>
    </FormControl>
  );
};

export const FieldAdder = ({
  canAddField,
  onAddField,
}: {
  canAddField: (newField: FieldSpec) => OperationOutcome;
  onAddField: (newField: FieldSpec, initialValue: any) => OperationOutcome;
}): JSX.Element => {
  const [isAddingField, setIsAddingField] = React.useState<boolean>(false);

  const handleFieldAdded = React.useCallback(
    (newField: FieldSpec, initialValue: any) => {
      const addOutcome = onAddField(newField, initialValue);
      setIsAddingField(!addOutcome.successful);
      return addOutcome;
    },
    [onAddField]
  );

  const handleCancel = React.useCallback(() => {
    setIsAddingField(false);
  }, [setIsAddingField]);

  if (isAddingField) {
    return (
      <FieldDefinitionEditor
        canAddField={canAddField}
        onAddField={handleFieldAdded}
        onCancel={handleCancel}
      />
    );
  } else {
    return (
      <Button kind="tertiary" onClick={() => setIsAddingField(true)}>
        {"+ Add Field"}
      </Button>
    );
  }
};
