/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { Button } from "baseui/button";
import { gql, useMutation, useQuery, useApolloClient } from "@apollo/client";
import resetData from "../Api/Gql/ResetData";
import appInfo from "../Api/Gql/AppInfo";
import migrateDataToLatestVersion from "../Api/Gql/MigrateDataToLatestVersion";
import { notify } from "../Shared/Notify";
import {
  HeadingXXLarge,
  HeadingLarge,
  LabelLarge,
  LabelSmall,
} from "baseui/typography";
import {
  EndAnchoredRow,
  Row,
  DirectionalStack,
} from "../DesignSystem/Containers";
import { Input } from "baseui/input";
import { FormControl } from "baseui/form-control";
import { AppInfo, MigrationExecutionState } from "Api";
import { formattedDateTime } from "../Api/ApiSerialization";
import { StatefulTooltip } from "baseui/tooltip";
import { SimorAuthContext } from "../Utils/AuthContext";
import { ButtonDestructiveStyle } from "../DesignSystem/SimorButtons";
import { Spinner } from "baseui/spinner";
import { APP_TITLE_TITLECASE } from "../Utils/SiteProps";

export const DatabaseTools = (): JSX.Element => {
  const simorAuth = React.useContext(SimorAuthContext);

  const { loading, error, data, refetch } = useQuery(gql(appInfo), {
    fetchPolicy: "network-only",
  });
  const appInfoValues = data?.appInfo as AppInfo;

  const client = useApolloClient();

  const [resetDataMutation] = useMutation(gql(resetData), {
    onCompleted: (_) => {
      notify.positive("Database cleared.");
      sessionStorage.clear();
      client.cache.reset();
      simorAuth.logoutFromServer();
    },
    onError: (error) =>
      notify.negative(`Couldn't clear database; ${error.message}`),
  });

  const handleResetData = (migrateData: boolean): void => {
    resetDataMutation({
      variables: { migrateData: migrateData },
    });
  };

  const isMigrationUpToDate = React.useMemo(() => {
    const schemaStatus = appInfoValues?.schemaStatus;
    if (schemaStatus?.lastExecutedMigration.__typename == "ExecutedMigration") {
      return (
        schemaStatus?.lastExecutedMigration?.changeId ===
        schemaStatus?.expectedMigrationId
      );
    } else return false;
  }, [appInfoValues?.schemaStatus]);

  const [migrateDataToLatestVersionMutation] = useMutation(
    gql(migrateDataToLatestVersion),
    {
      onCompleted: (data) => {
        notify.positive(data.migrateDataToLatestVersion);
        refetch();
      },
      onError: (error) => notify.negative(error.message),
    }
  );

  const handleUpgrade = React.useCallback(() => {
    migrateDataToLatestVersionMutation();
  }, [migrateDataToLatestVersionMutation]);

  if (loading) {
    return (
      <>
        <LabelLarge>{"Loading migration status..."}</LabelLarge>
        <Spinner />
      </>
    );
  }

  if (error) {
    return (
      <>
        <LabelLarge>{`Couldn't load migration status; ${error.message}`}</LabelLarge>
      </>
    );
  }

  return (
    <DirectionalStack
      gap="1rem"
      style={{ padding: "1rem 1rem 1rem 1rem", width: "50rem" }}
    >
      <HeadingXXLarge $style={{ paddingBottom: "1rem" }}>
        {"Database Tools"}
      </HeadingXXLarge>
      <EndAnchoredRow>
        <HeadingLarge $style={{ paddingBottom: "0.5rem" }}>
          {"Migration Status"}
        </HeadingLarge>
        <Button
          onClick={handleUpgrade}
          kind={isMigrationUpToDate ? "tertiary" : "primary"}
        >
          {"Upgrade"}
        </Button>
      </EndAnchoredRow>

      <LabelSmall>{"Last executed migration"}</LabelSmall>
      <div style={{ paddingLeft: "2rem" }}>
        <MigrationStatus
          migrationState={appInfoValues?.schemaStatus.lastExecutedMigration}
        />
      </div>
      <FormControl
        label={() =>
          `Expected migration for ${APP_TITLE_TITLECASE} ${appInfoValues?.version?.descriptor}`
        }
      >
        <Input
          value={appInfoValues?.schemaStatus?.expectedMigrationId}
          disabled
        />
      </FormControl>

      <HeadingLarge $style={{ paddingBottom: "0.5rem" }}>
        {"Clear Database"}
      </HeadingLarge>
      <LabelSmall $style={{ paddingBottom: "1rem" }}>
        {`These functions will remove all data in ${APP_TITLE_TITLECASE}. Use with care.`}
      </LabelSmall>
      <Row gap="5rem">
        <StatefulTooltip
          content={`Resets ${APP_TITLE_TITLECASE} to a 'fresh install' state.`}
        >
          <Button
            onClick={() => handleResetData(true)}
            overrides={ButtonDestructiveStyle}
          >
            {"Reset Data"}
          </Button>
        </StatefulTooltip>
        <StatefulTooltip
          content={
            <>
              {"Clears all data from the database and runs no migrations."}
              <br />
              {`May leave ${APP_TITLE_TITLECASE} inoperable.`}
            </>
          }
        >
          <Button
            onClick={() => handleResetData(false)}
            overrides={ButtonDestructiveStyle}
          >
            {"Blank Database"}
          </Button>
        </StatefulTooltip>
      </Row>
    </DirectionalStack>
  );
};

const MigrationStatus = ({
  migrationState,
}: {
  migrationState: MigrationExecutionState;
}): JSX.Element => {
  switch (migrationState.__typename) {
    case "ExecutedMigration":
      return (
        <>
          <FormControl label={() => "id"}>
            <Input value={migrationState.changeId} disabled />
          </FormControl>
          <FormControl label={() => "Executed at"}>
            <Input
              value={formattedDateTime(migrationState.timestamp) ?? "N/A"}
              disabled
            />
          </FormControl>
        </>
      );
    case "NoMigrationsExecuted":
      return <LabelSmall>{migrationState.message}</LabelSmall>;
    case "ErrorGettingMigrations":
      return (
        <LabelSmall>{`Couldn't get migrations because ${migrationState.errorMessage}`}</LabelSmall>
      );
    default:
      const _exhaustiveCheck: never = migrationState;
      return _exhaustiveCheck;
  }
};
