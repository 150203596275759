/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { OnChangeParams, Select, Value } from "baseui/select";
import GetKeywords from "../Api/Gql/GetKeywords";
import { gql, useQuery } from "@apollo/client";

export const ModelKeywordTags = ({
  dataTestidBase,
  keywords,
  onChange,
}: {
  dataTestidBase: string;
  keywords?: string[];
  onChange?: (keywords?: string[]) => void;
}): JSX.Element => {
  const { data: keywordsData, refetch: refetchKeywords } = useQuery(
    gql(GetKeywords),
    {
      variables: { keywordSubstring: "" },
      fetchPolicy: "network-only",
    }
  );

  const handleSelectedKeywordUpdate = (changeParams: OnChangeParams): void => {
    if (onChange) {
      const updatedKeywords: string[] = changeParams.value.map(
        (keywordValue) => {
          return keywordValue.id as string;
        }
      );
      onChange(updatedKeywords);
    }
  };

  const handleTextChanged = React.useCallback(
    (event: React.SyntheticEvent) => {
      const target = event.target as HTMLInputElement;
      const keyword = target.value;
      refetchKeywords({ keywordSubstring: keyword });
    },
    [refetchKeywords]
  );

  const selectedKeywords = React.useMemo<Value>(() => {
    return keywords?.map((keyword) => {
      return { id: keyword, label: keyword };
    });
  }, [keywords]);

  const suggestedKeywords = React.useMemo(() => {
    const keywords = keywordsData?.getKeywords as string[];
    return keywords?.slice(0, 5)?.map((keyword) => {
      return { id: keyword, label: keyword };
    });
  }, [keywordsData?.getKeywords]);

  return (
    <>
      <Select
        multi
        creatable
        options={suggestedKeywords}
        value={selectedKeywords}
        onChange={handleSelectedKeywordUpdate}
        disabled={onChange == null}
        onInputChange={handleTextChanged}
        overrides={{
          Input: {
            props: {
              "data-testid": `${dataTestidBase}-input`,
            },
          },
          Tag: {
            props: {
              overrides: {
                ActionIcon: {
                  props: {
                    "data-testid": `remove-${dataTestidBase}-tag`,
                  },
                },
              },
            },
          },
        }}
      />
    </>
  );
};

export default ModelKeywordTags;
