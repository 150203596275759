/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { gql, useQuery } from "@apollo/client";
import { HeadingXXLarge, LabelLarge } from "baseui/typography";
import { Value } from "baseui/select";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import PickListSchemaEditor from "./PickListSchemaEditor";
import getUserDefinedSchema from "../Api/Gql/GetUserDefinedSchema";
import { ModelMetadataSchema, PickListSchema, SchemaFields } from "Api";
import { PickList } from "../Shared/PickList";
import { VerticalSpacer } from "../DesignSystem/Containers";

const ModelFields = (): JSX.Element => {
  const { data, refetch } = useQuery(gql(getUserDefinedSchema));
  const pickListSchema = data?.getUserDefinedSchema as ModelMetadataSchema;
  return (
    <div style={{ margin: "1em 1em 1em 1em" }}>
      <div style={{ marginBottom: "2em" }}>
        <HeadingXXLarge>Transform Fields</HeadingXXLarge>
      </div>
      <FlexGrid flexDirection="row" flexGridColumnCount={2}>
        <FlexGridItem>
          <LabelLarge>
            {`Customize the "Add a Transform" form by creating your own form fields.`}
          </LabelLarge>
          <VerticalSpacer height="2rem" />
          <PickListSchemaEditor
            currentSchema={pickListSchema}
            onSave={refetch}
          />
        </FlexGridItem>
        <FlexGridItem>
          <DemoFieldList schemaFields={pickListSchema?.fields} />
        </FlexGridItem>
      </FlexGrid>
    </div>
  );
};

const DemoFieldList = ({
  schemaFields,
}: {
  schemaFields: SchemaFields | null;
}): JSX.Element => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <LabelLarge>{`How it will look in the "Add a Transform" form`}</LabelLarge>
      <VerticalSpacer height="2rem" />
      <FlexGrid flexDirection="column" width="400px" flexGridRowGap="scale900">
        {schemaFields?.selectMultiple &&
          schemaFields?.selectMultiple?.map((pickListData) => (
            <FlexGridItem key={pickListData.id}>
              <DemoPickList pickListData={pickListData} />
            </FlexGridItem>
          ))}
      </FlexGrid>
    </div>
  );
};

const DemoPickList = ({
  pickListData,
}: {
  pickListData: PickListSchema;
}): JSX.Element => {
  const [value, setValue] = React.useState<Value>([]);

  return (
    <PickList
      value={value}
      onChange={setValue}
      pickListData={pickListData}
      multi
    />
  );
};

export default ModelFields;
