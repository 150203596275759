/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { useStyletron } from "baseui";
import { StatefulTooltip, PLACEMENT } from "baseui/tooltip";
import React from "react";

const placeholderRedactionText = "BEGCPZO2HK0X";

export const RedactedText = (): JSX.Element => {
  const [css] = useStyletron();
  return (
    <StatefulTooltip
      content={() => "This information has been redacted."}
      placement={PLACEMENT.top}
      returnFocus
      autoFocus
    >
      <span
        className={css({
          color: "transparent",
          cursor: "default",
          userSelect: "none",
          textShadow: "0 0 5px rgba(0,0,0,0.999);",
        })}
      >
        {placeholderRedactionText}
      </span>
    </StatefulTooltip>
  );
};
