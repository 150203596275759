/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { UUID } from "../Utils/Types";
import { Path } from "./DataTransformation";

// TODO: ApolloClient is receiving dates as scalars, but it is not
// doing anything to parse them. The apollo-link-scalars library should
// handle this problem so they can be referenced without parsing them.
//
// https://github.com/eturino/apollo-link-scalars
//
// This post had good guidance about how to get that working in an app:
// https://stackoverflow.com/questions/66085887/how-can-you-retrieve-a-date-field-in-apollo-client-from-a-query-as-a-date-and-no

export interface AppInfo {
  version: VersionInfo;
  configurations: AppConfigurations;
  schemaStatus: SchemaStatus;
}

export interface AppConfigurations {
  isSensitiveSecurityContext: boolean;
}

export interface VersionInfo {
  abbreviatedCommitHash: string;
  commitHash: string;
  descriptor: string;
  buildDate: string;
}

export interface ExecutedMigration {
  __typename: "ExecutedMigration";
  changeId: string;
  timestamp: string;
}

export interface NoMigrationsExecuted {
  __typename: "NoMigrationsExecuted";
  message: string;
}

export interface ErrorGettingMigrations {
  __typename: "ErrorGettingMigrations";
  errorMessage: string;
}

export type MigrationExecutionState =
  | ExecutedMigration
  | NoMigrationsExecuted
  | ErrorGettingMigrations;

export interface SchemaStatus {
  /**
   * The state of the data schema currently in the database.
   */
  lastExecutedMigration?: MigrationExecutionState;
  /**
   * The latest migration that should have been run on the data for the application to run with this version.
   */
  expectedMigrationId?: string;
}

export interface FileInfo extends Marked {
  id: UUID;
  attributes?: FileAttributes;
  metadataScans: MetadataScan[];
}

export interface NewFileInfo {
  id: UUID;
  name: string;
}

export interface FileUploadManifest {
  fileInfo: NewFileInfo[];
  uploadDelayMillis?: number;
}

export interface FileAttributes {
  name: string;
  uploadDateUtc: string;
  sizeMbs: number;
}

export interface Marked {
  securityMarkings?: SecurityMarkings;
}

export interface SecurityMarkings {
  classificationLevel: ClassificationLevel;
  compartments: CompartmentMarking[];
}

export interface CompartmentMarking {
  classificationLevel: ClassificationLevel;
  agencyToCompartments: Map<string, string[]>;
}

export enum ClassificationLevel {
  UNCLASSIFIED = "U",
  CONFIDENTIAL = "C",
  SECRET = "S",
  TOP_SECRET = "TS",
}

export interface Redaction {
  __typename: "Redaction";
  message: string;
}

export type ClearedModelFile = ModelFile | Redaction;

export interface ModelFile extends Marked {
  __typename: "ModelFile";
  fileId: UUID;
  fileName: string;
  metadataScan: MetadataScan;
}

export interface ModelFileFolder {
  path: string;
  files?: ClearedModelFile[];
}

export interface ModelInfo extends Marked {
  id: UUID;
  creationDate?: string;
  lastModifiedDate?: string;
  metadata?: ModelMetadata;
  fileFolders?: ModelFileFolder[];
  files?: ClearedModelFile[];
}

export interface ModelQueryResponse {
  pageCount: number;
  results: ModelInfo[];
}

/**
 * The settings that configure a query's response.
 */
export interface QuerySettingsInput {
  /**
   * The settings that configure the pagination of the query response.
   */
  paging?: PageSettingsInput;

  sort?: SortSettingsInput;
}

export interface SortSettingsInput {
  fieldName?: string;

  order?: SortOrder;
}

export type SortOrder = "ASCENDING" | "DESCENDING";

/**
 * The settings that define how to paginate data.
 */
export interface PageSettingsInput {
  /**
   * The size of each page. May be between 1 and 200. Values outside of this range will be clipped to
   * the lower or upper bound.
   */
  size?: number;

  /**
   * The index of the page. This is a 1-based index; the first page is 1.
   */
  index?: number;
}

export interface FileIdFolder {
  path: string;
  fileIds: UUID[];
}

export interface ModelInfoInput extends Marked {
  id: UUID;
  metadata: ModelMetadata;
  fileFolders: FileIdFolder[];
  // the fileIds field is intentionally excluded here; it is included in
  //   the api for backwards compatibility purposes and is not needed to
  //   describe the folder structure.
}

export interface ModelMetadata {
  title?: string;
  version?: string;
  description?: string;
  subject?: string;
  keywords?: string[];
  notes?: string;
  customProps?: Record<string, any>;
  content?: string;
}

export interface ModelChangeSet {
  updatedModel: ModelInfo;
  hasChanged: boolean;
}

export interface MetadataScan {
  id: UUID;
  scanType: SimpleMimeType;
  scanResult?: ScanResult;
  scanMessage?: string;
  metadata?: ModelMetadata;
}

export interface SimpleMimeType {
  mimeType: string;
  extension: string;
}

export const MIME_TYPE_UNKNOWN: SimpleMimeType = {
  mimeType: "",
  extension: "*",
};

export interface NodeDescriptor {
  id: UUID;
  displayName: string;
  allPaths: Path[];
}

export type TransformTypeDescriptor = NodeDescriptor;

export interface DataFormatDescriptor extends NodeDescriptor {
  mimeType: SimpleMimeType;
}

export function isDataFormat(
  descriptor: NodeDescriptor
): descriptor is DataFormatDescriptor {
  return (descriptor as DataFormatDescriptor).mimeType !== undefined;
}

export enum ScanResult {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  FILETYPE_NOT_SUPPORTED = "FILETYPE_NOT_SUPPORTED",
  FILE_DOES_NOT_EXIST = "FILE_DOES_NOT_EXIST",
}

export interface LoginResponse {
  authToken: string;
  user: AuthenticatedUser;
  errors: string[];
}

export interface JwtRefreshResponse {
  authToken: string;
  errors: string[];
}

export interface ProtectedUrl {
  urlFragment: string;
}

export interface Name {
  firstName: string;
  lastName: string;
  displayName: string;
}

export interface ContactInfo {
  email: string;
}

export interface AuthenticatedUser {
  id: UUID;
  username: string;
  person: Person;
  isAdmin: boolean;
  preferences?: UserPreferences;
  securityInfo: SecurityInfo;
}

export interface User {
  id: UUID;
  username: string;
  person: Person;
  preferences?: UserPreferences;
}

export interface UserProfileInput {
  id: UUID;
  person: Person;
  preferences?: UserPreferences;
}

export interface UserPreferences {
  topicsOfInterest?: string[];
}

export interface SecurityInfo {
  clearanceLevel: ClassificationLevel;
  agencyToCompartments: Map<string, string[]>;
}

export interface Person {
  name: Name;
  contactInfo: ContactInfo;
}

export interface AppInitializationStatus {
  initialized: boolean;
}

export interface AppInitializationResponse {
  message: string;
  status: AppInitializationStatus;
}

export interface GeneralResponse {
  status: number;
  message: string;
  payload: any;
}

export interface LoginRequest {
  username: string;
}

export interface FileUploadResponse {
  successfulUploads: string[];
  duplicateUploads: string[];
  failedUploads: FileError[];
}

// TODO: Can this be used instead of ElementUploadFailure or vice versa?
export interface FileError {
  fileName: string;
  uploadError: string;
}

export interface BulkAddModelResponse {
  successfulUploads?: ElementUploadSuccess[];
  failedUploads?: ElementUploadFailure[];
  operationError?: string;
}

export interface ElementUploadSuccess {
  uploadedFileName: string;
  isDuplicate: boolean;
  newModelId: UUID;
}

export interface ElementUploadFailure {
  uploadedFileName: string;
  failureMessage: string;
}

export interface FileInfoInput extends Marked {
  id: UUID;
}

export interface PickListValueOption {
  value: string;
  description?: string | null;
}

// TODO: Create PickListSchemaInput interface
export interface PickListSchema {
  id: UUID;
  attributeName: string;
  valueOptions: PickListValueOption[] | null;
}

export interface SchemaFields {
  selectMultiple: PickListSchema[] | null;
}

export interface ModelMetadataSchema {
  id: UUID;
  fields: SchemaFields;
}

export interface ModeledTopics {
  topics: string[];
}

export type FileSize = "SMALL" | "MEDIUM" | "LARGE" | "EXTRA_LARGE";

export interface TestModelSpecInput {
  modelCount: number;
  fileSize: FileSize;
}

export type ChunkType =
  | "NONE"
  | "MULTIPLICITY"
  | "REPETITION"
  | "MAP_GROUP"
  | "LIST_GROUP"
  | "ITEM";

export interface Chunk<T> {
  type: ChunkType;
  chunkList: Chunk<T>[];
  chunkMap: Map<string, Chunk<T>>;
  data?: T;
  count: number;
}
