/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { HeadingXXLarge, LabelSmall } from "baseui/typography";
import * as React from "react";
import { CommandBar } from "../DesignSystem/CommandBar";
import { EndAnchoredRow, VerticalSpacer } from "../DesignSystem/Containers";

export const DetailsPage = ({
  children,
}: {
  children?: React.ReactNode;
}): JSX.Element => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "1rem 1rem 1rem 1rem",
        overflow: "auto",
      }}
    >
      {React.Children.toArray(children)}
    </div>
  );
};

export const DetailsHeaderBar = ({
  title,
  children,
  creationDate,
  lastSaveDate,
}: {
  title: string;
  children?: React.ReactNode;
  creationDate?: string;
  lastSaveDate?: string;
}): JSX.Element => {
  return (
    <>
      <EndAnchoredRow>
        <HeadingXXLarge>{title}</HeadingXXLarge>
        <CommandBar>{React.Children.toArray(children)}</CommandBar>
      </EndAnchoredRow>
      <VerticalSpacer height={"1rem"}></VerticalSpacer>
      {creationDate && (
        <EndAnchoredRow>
          <LabelSmall>{`Created: ${creationDate}`}</LabelSmall>
          {lastSaveDate && (
            <LabelSmall>{`Last Saved: ${lastSaveDate}`}</LabelSmall>
          )}
        </EndAnchoredRow>
      )}
      <VerticalSpacer height={"2rem"}></VerticalSpacer>
    </>
  );
};
