/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { ClassificationLevel, FileInfo } from "./Api";
import { DateTime } from "luxon";

export function fileUploadDateAsText(fileInfo?: FileInfo): string | null {
  return formattedDateTime(fileInfo?.attributes?.uploadDateUtc);
}

export function formattedDateTime(serializedDateTime: string): string | null {
  const localDate = utcToLocalDate(serializedDateTime);
  return localDate ? localDate.toLocaleString(DateTime.DATETIME_FULL) : null;
}

export function fileUploadDate(fileInfo?: FileInfo): DateTime | null {
  return utcToLocalDate(fileInfo?.attributes?.uploadDateUtc);
}

export function fileSizeAsText(fileInfo?: FileInfo): string | null {
  const size = fileInfo?.attributes?.sizeMbs;
  return mbsSizeAsText(size);
}

export function mbsSizeAsText(sizeMbs: number): string | null {
  if (sizeMbs < 0.001) {
    const sizeBytes = sizeMbs * 1000000;
    return `${Math.round(sizeBytes)} B`;
  }
  if (sizeMbs < 1) {
    const sizeKbs = sizeMbs * 1000;
    return `${Math.round(sizeKbs)} KB`;
  }
  if (sizeMbs < 1000) {
    return `${Math.round(sizeMbs)} MB`;
  }

  const sizeGbs = sizeMbs / 1000;
  return `${Math.round(sizeGbs)} GB`;
}

export function utcToLocalDate(utcDateText?: string): DateTime | null {
  if (!utcDateText) {
    return null;
  }
  const utcDate = DateTime.fromISO(utcDateText);
  return utcDate.toLocal();
}

export function classificationAsText(
  classificationLevel?: ClassificationLevel,
  isDefaultSecurityContext: boolean = true
): string {
  switch (classificationLevel) {
    case ClassificationLevel.UNCLASSIFIED:
      return isDefaultSecurityContext ? "UNCLASSIFIED" : "Cherry - 1";
    case ClassificationLevel.CONFIDENTIAL:
      return isDefaultSecurityContext ? "CONFIDENTIAL" : "Pineapple - 2";
    case ClassificationLevel.SECRET:
      return isDefaultSecurityContext ? "SECRET" : "Peach - 3";
    case ClassificationLevel.TOP_SECRET:
      return isDefaultSecurityContext ? "TOP SECRET" : "Strawberry - 4";
    default:
      return "";
  }
}
