/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { UUID } from "../Utils/Types";

export enum PathAction {
  NONE = "NONE",
  MOVE_TO_NEXT = "MOVE_TO_NEXT",
  MOVE_TO_SIBLING = "MOVE_TO_SIBLING",
  MOVE_TO_CHILD = "MOVE_TO_CHILD",
  TRANSFORM = "TRANSFORM",
  TRANSFORM_RANGE = "TRANSFORM_RANGE",
  RANGE_NEXT = "RANGE_NEXT",
  RANGE_SIBLING = "RANGE_SIBLING",
  RANGE_CHILD = "RANGE_CHILD",
}

export function isRange(action: PathAction): boolean {
  return (
    action == PathAction.TRANSFORM_RANGE ||
    action == PathAction.RANGE_NEXT ||
    action == PathAction.RANGE_SIBLING ||
    action == PathAction.RANGE_CHILD
  );
}

export function rangeToExplicit(action: PathAction): PathAction {
  switch (action) {
    case PathAction.TRANSFORM_RANGE:
      return PathAction.TRANSFORM;
    case PathAction.RANGE_NEXT:
      return PathAction.MOVE_TO_NEXT;
    case PathAction.RANGE_SIBLING:
      return PathAction.MOVE_TO_SIBLING;
    case PathAction.RANGE_CHILD:
      return PathAction.MOVE_TO_CHILD;
    default:
      throw "Not a range action";
  }
}

export function explicitToRange(action: PathAction): PathAction {
  switch (action) {
    case PathAction.TRANSFORM:
      return PathAction.TRANSFORM_RANGE;
    case PathAction.MOVE_TO_NEXT:
      return PathAction.RANGE_NEXT;
    case PathAction.MOVE_TO_SIBLING:
      return PathAction.RANGE_SIBLING;
    case PathAction.MOVE_TO_CHILD:
      return PathAction.RANGE_CHILD;
    default:
      throw "Not convertible to a range action";
  }
}

export enum PathType {
  INPUT = "INPUT",
  OUTPUT = "OUTPUT",
  BOTH = "BOTH",
  NEITHER = "NEITHER",
}

export interface PathElement {
  action: PathAction;
  byNumber?: number;
  byString?: string;
  minNumber?: number;
  maxNumber?: number;
}

export interface Path {
  nodeDescriptorID: UUID;
  pathType: PathType;
  pathElements: PathElement[];
}

export interface Edge {
  sourceID: string;
  sourcePath: Path;
  sourceLabel?: string;
  targetID: string;
  targetPath: Path;
  targetLabel?: string;
}

export interface Mapping {
  mappingID: UUID;
  sourceModelID: UUID;
  edges: Edge[];
  titles: Map<string, string>;
  supplementalData: Map<string, string>;
  multipleInputFilesToMultipleOutputFiles: boolean;
  multipleCSVsToSingleExcelOutput: boolean;
}
