/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import * as React from "react";
import { useLocation } from "react-router-dom";

import getModels from "../Api/Gql/GetModels";
import { ModelChangeSet, ModelInfo } from "Api";
import updateModel from "../Api/Gql/UpdateModel";
import RescanModelFiles from "../Api/Gql/RescanModelFiles";
import { notify } from "../Shared/Notify";
import { handleApolloError } from "../Shared/Errors";
import { Button } from "baseui/button";
import { DetailsHeaderBar } from "../Shared/DetailsPages";
import { ModelDetailsState, ModelDetailsReducer } from "./ModelDetailsState";
import { formattedDateTime } from "../Api/ApiSerialization";
import { ModelDetailsPage } from "./ModelDetailsPage";
import { parseUUID, UUID } from "../Utils/Types";

interface ModelDetailsQueryParams {
  modelId: UUID;
}
function useModelDetailsParams(): ModelDetailsQueryParams {
  const searchParams = new URLSearchParams(useLocation().search);
  // TODO: Handle invalid formatted guids better.
  const fileDetailsParams: ModelDetailsQueryParams = {
    modelId: parseUUID(searchParams.get("modelId")),
  };
  return fileDetailsParams;
}

export const ModelDetailsEditPage = (): JSX.Element => {
  const modelDetailsParams = useModelDetailsParams();

  const [state, dispatch] = React.useReducer(
    ModelDetailsReducer,
    new ModelDetailsState(modelDetailsParams.modelId)
  );

  const [rescanModelFilesMutation] = useMutation(gql(RescanModelFiles), {
    onCompleted: (data) => {
      const changeSet: ModelChangeSet = data.rescanModelFiles;
      if (changeSet.hasChanged) {
        dispatch(["resetOriginalAndEdited", changeSet.updatedModel]);
        notify.positive("Model metadata updated");
      } else {
        notify.positive("No changes to model metadata");
      }
    },
    onError: (error) => {
      handleApolloError(error, "Error re-scanning model!");
    },
  });

  const handleRescan = React.useCallback(() => {
    rescanModelFilesMutation({
      variables: { modelId: state.edited.model.id },
    });
  }, [rescanModelFilesMutation, state.edited.model.id]);

  const [updateModelMutation] = useMutation(gql(updateModel), {
    variables: { model: state.edited.model },
    onCompleted: (data) => {
      dispatch(["resetOriginalAndEdited", data.updateModel as ModelInfo]);
      notify.positive("Model saved");
    },
    onError: (error: ApolloError) => {
      notify.negative(`Error uploading model; ${error.message}`);
    },
  });

  const { loading: modelLoading } = useQuery(gql(getModels), {
    variables: { ids: [state.edited.model.id] },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const models = data.getModels as ModelInfo[];
      dispatch(["resetOriginalAndEdited", models[0]]);
    },
    onError: (error) => {
      handleApolloError(error, "Error getting model data!");
    },
  });

  const ModelDetailsEditButtons = React.useMemo((): JSX.Element => {
    return (
      <DetailsHeaderBar
        title={state.metadataValues.title}
        creationDate={formattedDateTime(state.creationDate)}
        lastSaveDate={formattedDateTime(state.lastSaveDate)}
      >
        <Button kind={"tertiary"} onClick={handleRescan}>
          {"Rescan"}
        </Button>
        <Button
          kind={"tertiary"}
          disabled={!state.modelChanged}
          onClick={() => dispatch(["discardChanges"])}
        >
          {"Discard"}
        </Button>
        <Button
          kind={"primary"}
          disabled={!state.modelChanged}
          onClick={() => updateModelMutation()}
        >
          {"Save"}
        </Button>
      </DetailsHeaderBar>
    );
  }, [
    handleRescan,
    state.creationDate,
    state.lastSaveDate,
    state.metadataValues.title,
    state.modelChanged,
    updateModelMutation,
  ]);

  return (
    <ModelDetailsPage
      state={state}
      dispatch={dispatch}
      headerActionButtons={ModelDetailsEditButtons}
      modelLoading={modelLoading}
    />
  );
};
