/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { PickListSchema } from "Api";
import { camelCase } from "lodash";

export interface BasicFieldSpec {
  title: string;
  propertyName: string;
}

function validPropertyName(title: string, propertyName?: string): string {
  return propertyName ? propertyName : camelCase(title);
}

export interface SimpleTextFieldSpec extends BasicFieldSpec {
  type: "SimpleTextField";
}

export function simpleTextFieldSpec(
  title: string,
  valueName?: string
): SimpleTextFieldSpec {
  return {
    type: "SimpleTextField",
    title: title,
    propertyName: validPropertyName(title, valueName),
  };
}

export interface MultiLineTextFieldSpec extends BasicFieldSpec {
  type: "MultiLineTextField";
}

export function multiLineTextFieldSpec(
  title: string,
  valueName?: string
): MultiLineTextFieldSpec {
  return {
    type: "MultiLineTextField",
    title: title,
    propertyName: validPropertyName(title, valueName),
  };
}

export interface TagListFieldSpec extends BasicFieldSpec {
  type: "TagListField";
}

export function tagListFieldSpec(
  title: string,
  valueName?: string
): TagListFieldSpec {
  return {
    type: "TagListField",
    title: title,
    propertyName: validPropertyName(title, valueName),
  };
}

export interface PickListFieldSpec extends BasicFieldSpec {
  type: "PickListField";
  schema: PickListSchema;
}

export function pickListFieldSpec(schema: PickListSchema): PickListFieldSpec {
  return {
    type: "PickListField",
    title: schema.attributeName,
    schema: schema,
    propertyName: schema.attributeName,
  };
}

export type FieldSpec =
  | SimpleTextFieldSpec
  | MultiLineTextFieldSpec
  | TagListFieldSpec
  | PickListFieldSpec;
