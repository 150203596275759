/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import { ButtonOverrides } from "baseui/button";

// Very subtle option that does not need to stand out.
const KmSubtleStyle: ButtonOverrides = {
  BaseButton: {
    style: () => ({
      boxShadow: `none`,
    }),
  },
};

// Given more weight so it feels less subordinate to the primary option.
const KmSiblingStyle: ButtonOverrides = {
  BaseButton: {
    style: ({ $theme }) => ({
      boxShadow: `inset 0 0 0 3px ${$theme.colors.primary}`,
    }),
  },
};

export const KmButtons = {
  style: {
    subtle: KmSubtleStyle,
    sibling: KmSiblingStyle,
  },
};
